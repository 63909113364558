import React from "react";
import {
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useTableStyles } from "../../styles/table.style";
import { Empty } from "../empty";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetErrorMessage } from "../../store/rollback/rollback.action";
import { ignorePricingRules } from "../../store/checkout/checkout.action";

const PricingRules = (props) => {
  const { openPricingRules, setOpenPricingRules } = props;
  const tableClasses = useTableStyles();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "checkout", "payement"]);

  const [showDetails, setShowDetails] = React.useState(false);
  const itemDetails = useSelector((state) => state.Checkout.itemDetails);
  const errorMessage = useSelector((state) => state.Rollback.errorMessage);

  const ignorePricingRule = useSelector(
    (state) => state.Checkout.ignorePricingRules
  );

  const globalDefaults = useSelector((state) => state.Login.globalDefaults);

  const itemsWithPricingRules = itemDetails?.filter((item) =>
    Object.keys(item).includes("pricing_rules")
  );

  const showPricingRules = () => {
    dispatch(ignorePricingRules(!ignorePricingRule));
  };
  // SUBTOTALWITHDISCOUNT >>>
  const subTotalPerRow = (item, discount) => {
    const subtotal = parseFloat(
      item?.price_list_rate ? item?.price_list_rate : item?.standard_rate
    );

    const discountType =
      item?.discount_percentage !== 0 ? "percentage" : "amount";

    const discountedAmount =
      discountType === "percentage" ? subtotal * (discount / 100) : discount;

    const total = ((subtotal - discountedAmount) * parseInt(item?.qty)).toFixed(
      3
    );

    return `${total} ${globalDefaults?.default_currency}`;
  };

  const handleResetError = () => {
    dispatch(resetErrorMessage());
  };

  const handleReturn = () => {
    handleResetError();
    setOpenPricingRules(false);
  };

  return (
    <>
      <Dialog
        open={openPricingRules}
        onClose={() => setOpenPricingRules(false)}
        maxWidth="md"
        fullWidth
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle align={"center"}>
          <Typography
            className={classes.title}
            align={"center"}
            color={"primary"}
            fontWeight={"bold"}
          >
            {t("checkout:choiceCondition")}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Typography align="left" variant="caption" color="primary">
              {itemsWithPricingRules?.length} {t("checkout:salesConditionApp")}
            </Typography>
          </DialogContentText>
          {errorMessage && (
            <DialogContentText>
              <Alert severity="error">{errorMessage}</Alert>
            </DialogContentText>
          )}
          {itemsWithPricingRules && itemsWithPricingRules?.length === 0 && (
            <Empty text={t("checkout:noCondition")} />
          )}
          {itemsWithPricingRules?.length != 0 && (
            <DialogContentText id="scroll-dialog-description">
              <Box className={tableClasses.paper} mt={2}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow className={classes.tableRow}>
                      <TableCell className={tableClasses.tablecellHeader}>
                        {t("checkout:appliedCondition")}
                      </TableCell>
                      <TableCell className={tableClasses.tablecellHeader}>
                        {t("checkout:PriceOrProductDiscount")}
                      </TableCell>
                      <TableCell className={tableClasses.tablecellHeader}>
                        {t("checkout:ApplConditionFor")}
                      </TableCell>
                      <TableCell className={tableClasses.tablecellHeader}>
                        {t("checkout:benefits")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {Array.isArray(itemDetails) &&
                      itemDetails &&
                      itemDetails
                        ?.filter((item) =>
                          Object.keys(item).includes("pricing_rules")
                        )
                        .map((row) => (
                          <TableRow className={classes.tableRow}>
                            <TableCell className={tableClasses.tableCell}>
                              {JSON.parse(row.pricing_rules)}
                            </TableCell>
                            <TableCell className={tableClasses.tableCell}>
                              {row.price_or_product_discount}
                            </TableCell>
                            <TableCell className={tableClasses.tableCell}>
                              {row.pricing_rule_for
                                ? row.pricing_rule_for
                                : "-"}
                            </TableCell>
                            <TableCell className={tableClasses.tableCell}>
                              {row.discount_percentage != 0
                                ? `${row.discount_percentage}% `
                                : row.discount_amount != 0
                                ? row.discount_amount.toFixed(3) +
                                  " " +
                                  globalDefaults?.default_currency
                                : "Free Item"}
                            </TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </Box>
            </DialogContentText>
          )}

          {itemsWithPricingRules?.length != 0 && (
            <>
              <Box
                mt={3}
                justifyContent="center"
                display="flex"
                flexDirection="column"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={ignorePricingRule}
                        onChange={() => showPricingRules()}
                        color="primary"
                      />
                    }
                    label={t("checkout:ignorePricingRule")}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showDetails}
                        onChange={() => setShowDetails(!showDetails)}
                        color="primary"
                      />
                    }
                    label={t("checkout:detailCondition")}
                  />
                </FormGroup>
              </Box>
              {showDetails && (
                <Box className={tableClasses.paper}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow className={classes.tableRow}>
                        <TableCell className={tableClasses.tablecellHeader}>
                          {t("common:ref")}
                        </TableCell>
                        <TableCell className={tableClasses.tablecellHeader}>
                          {t("produit")}
                        </TableCell>
                        <TableCell className={tableClasses.tablecellHeader}>
                          {t("qty")}
                        </TableCell>
                        <TableCell className={tableClasses.tablecellHeader}>
                          {t("prixHT")}
                        </TableCell>
                        <TableCell className={tableClasses.tablecellHeader}>
                          {t("checkout:discountValue")}
                        </TableCell>
                        <TableCell className={tableClasses.tablecellHeader}>
                          {t("checkout:PricingRule")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                      {Array.isArray(itemDetails) &&
                        itemDetails
                          ?.filter((item) =>
                            Object.keys(item).includes("pricing_rules")
                          )
                          ?.flatMap((row) => [
                            <TableRow
                              key={row.item_code}
                              className={classes.tableRow}
                            >
                              <TableCell className={tableClasses.tableCell}>
                                {row.item_code}
                              </TableCell>
                              <TableCell className={tableClasses.tableCell}>
                                {row.item_name}
                              </TableCell>
                              <TableCell className={tableClasses.tableCell}>
                                {row.qty}
                              </TableCell>
                              <TableCell className={tableClasses.tableCell}>
                                {(
                                  parseInt(row?.qty) *
                                  parseFloat(row?.price_list_rate)
                                ).toFixed(3)}{" "}
                                {globalDefaults?.default_currency}
                              </TableCell>
                              <TableCell className={tableClasses.tableCell}>
                                {subTotalPerRow(
                                  row,
                                  row?.discount_amount != 0
                                    ? row?.discount_amount
                                    : row?.discount_percentage
                                )}
                              </TableCell>
                              <TableCell className={tableClasses.tableCell}>
                                {JSON.parse(row.pricing_rules)}
                              </TableCell>
                            </TableRow>,
                            // Additional rows for each free_item_data
                            ...(row.free_item_data &&
                            row.free_item_data.length > 0
                              ? row.free_item_data.map((freeItem) => (
                                  <TableRow
                                    className={classes.highlightedRow}
                                    key={`${row.item_code}-${freeItem.name}`}
                                  >
                                    <TableCell className={classes.tableCell}>
                                      {freeItem.item_code}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {freeItem.item_name}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {freeItem.qty}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      {freeItem.price_list_rate.toFixed(3)}
                                      {globalDefaults.default_currency}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                      -
                                    </TableCell>

                                    <TableCell className={classes.tableCell}>
                                      {JSON.parse(row.pricing_rules)}
                                    </TableCell>
                                  </TableRow>
                                ))
                              : []),
                          ])}
                    </TableBody>
                  </Table>
                </Box>
              )}
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={handleReturn}>
            {!props.isPurchaseOrder ? t("common:annuler") : t("common:fermer")}
          </Button>
          {!props.isPurchaseOrder && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push("/checkout/payement/1")}
            >
              {t("common:confirmer")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PricingRules;
