import React from "react";
import Box from "@material-ui/core/Box";
import { useStyles } from "../../style";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../helpers/utils";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { symbol } from "../../../../helpers/utils";
import { FreeItemTableBody } from "./components";
import { useState } from "react";
import { useEffect } from "react";
import QRCode from "react-qr-code";

export const Ticket = React.forwardRef(({ isDuplicata, row }, ref) => {
  
  const classes = useStyles();
  const { t } = useTranslation(["payement", "common", "checkout","clients"]);
  const coupon = useSelector((state) => state.Checkout.selectedCoupon);
  const products = useSelector((state) => state.Checkout.products);
  const amount = useSelector((state) => state.Checkout.amount);
  const discount = useSelector((state) => state.Checkout.discount);
  const posOpenedDate = useSelector((state) => state.Login.posOpeningDate);
  const order = useSelector((state) => state.Checkout.order);
  const TPEticketnumber = useSelector(
    (state) => state.Checkout.TPEticketnumber
  );
  const selectedCustomer = useSelector((state) => state.Checkout.client);
  const customRef = useSelector((state) => state.Checkout.customRef);
  const currencies = useSelector((state) => state.Client.currencies);
  const selectedSalesPerson = useSelector(
    (state) => state.Checkout.selectedSalesPerson
  );
  const cashier = useSelector((state) => state.Login.selectedData);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const currencyExchangeRate = useSelector(
    (state) => state.Checkout.currencyExchangeRate
  );
  const exchangeRate = useSelector((state) => state.Checkout.exchangeRate);
  const selectCurrency = useSelector((state) => state.Checkout.selectCurrency);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const itemTaxList = useSelector((state) => state.Product.itemTax);
  const [paymentSansEspaces , setPaymentSansEspaces] = useState("")
  const ignorePricingRule = useSelector(
    (state) => state.Checkout.ignorePricingRules
  );

  const appliedLoyaltyCard = useSelector(
    (state) => state.Cart.appliedLoyaltyCard
  );



  
  
 



  const detailClient = useSelector((state) => state.Client.client);
  const holdselected = useSelector((state) => state.Checkout.holdselected);

  const currencySymbol = globalDefaults?.default_currency
    ? symbol(globalDefaults?.default_currency, currencies)?.symbol
    : symbol(globalDefaults?.default_currency, currencies) &&
      symbol(globalDefaults?.default_currency, currencies)?.symbol;
  const tvaTaxes = (product) => {
    const data =
      selectCurrency != globalDefaults?.default_currency
        ? product?.item_tax_template &&
          product?.item_tax_template.slice(
            8,
            product?.item_tax_template.indexOf("%") + 1
          ) +
            ": " +
            (
              ((product?.net_amount / Object.values(exchangeRate)) *
                parseFloat(
                  product?.item_tax_template
                    .match(/\d+/g)
                    .toString()
                    .replace(",", ".")
                ).toFixed(3)) /
              100
            ).toFixed(3)
        : product?.item_tax_template &&
          product?.item_tax_template.slice(
            8,
            product?.item_tax_template.indexOf("%") + 1
          ) +
            ": " +
            (
              (product?.net_amount *
                parseFloat(
                  product?.item_tax_template
                    .match(/\d+/g)
                    .toString()
                    .replace(",", ".")
                ).toFixed(3)) /
              100
            ).toFixed(3);
    return data;
  };

  const selectedSalesPersonNames = [];

  selectedSalesPerson.length &&
    selectedSalesPerson.map((s) => {
      s.sales_person_name
        ? selectedSalesPersonNames.push(s.sales_person_name)
        : selectedSalesPersonNames.push(s);
    });
  const allSalesPerson = [...new Set(selectedSalesPersonNames)];

  const prixTTC = (element) => {
    let taxRate = itemTaxList.find(
      (s) => s.name == element.item_tax_template
    )?.tax_rate;
    if (!taxRate) {
      taxRate = 0;
    }
    return element.price_list_rate
      ? parseFloat(element.price_list_rate) +
          (parseFloat(element.price_list_rate) * parseFloat(taxRate)) / 100
      : element.standard_rate
      ? parseFloat(element.standard_rate) +
        (parseFloat(element.standard_rate) * parseFloat(taxRate)) / 100
      : parseFloat(element.base_rate) +
        (parseFloat(element.base_rate) * parseFloat(taxRate)) / 100;
  };

  const itemDetails = useSelector((state) => state.Checkout.itemDetails);

  const hasFreeItemData = () => {
    return itemDetails.some(
      (obj) => obj.free_item_data && obj.free_item_data.length > 0
    );
  };


  return (
    <Box className={classes.ticket} ref={ref}>
      <Box className={classes.ticketBlock}>
        <Box className={classes.ticketHeader}>
          <div className={classes.logo}>
          <img
            src={activeImageTheme}
            style={{
              display: "block",
              marginLeft: "40%",
              marginBottom: "30px",
              width: "20%",
            }}
          />
          </div>
          {selectedData &&
            selectedData.contact &&
            Array.isArray(selectedData.contact) &&
            selectedData.contact.map((row) => (
              <>
                <Box>{globalDefaults?.default_company}</Box>
                <Box fontWeight={"normal"} fontSize={"12px"}>
                  {row.address_line_1 === null ? "-" : row.address_line_1}
                </Box>
                <Box fontWeight={"normal"} fontSize={"12px"}>
                  {t("common:city")}: {row.city === null ? "-" : row.city}
                </Box>
                <Box fontWeight={"normal"} fontSize={"12px"}>
                  {t("common:tels")}:{" "}
                  {row.phone_no === null ? "-" : row.phone_no} /{" "}
                  {row.mobile_no === null ? "-" : row.mobile_no}
                </Box>
              </>
            ))}
          {isDuplicata ? (
            <>
              <Box fontWeight={"normal"} fontSize={"12px"}>
                {formatDate(row.posting_date, "LL")} {row.posting_time}
              </Box>
              <Box fontWeight={"bold"} fontSize={"13px"}>
                {t("common:ref")}:{row.name}
              </Box>
            </>
          ) : (
            <>
              <Box fontWeight={"normal"} fontSize={"12px"}>
                {formatDate(posOpenedDate, "LL")}{" "}
                {formatDate(Date(), "HH:mm:ss")}
              </Box>
              <Box fontWeight={"bold"} fontSize={"13px"}>
                {t("common:ref")} :
                {
                  /* order && Array.isArray(order.docs) && order.docs[0] ? order.docs[0].name : */ customRef
                }
              </Box>
              {TPEticketnumber && (
                <Box
                  fontFamily={"Courier Prime"}
                  fontSize={"10px"}
                  color={"#707070"}
                >
                  {`${t("payement:TPEticketnumber")}: ${TPEticketnumber}`}
                </Box>
              )}
            </>
          )}
        </Box>
        {isDuplicata && (
          <div>
            <img
              className={classes.duplicataLogo}
              src={"/img/duplicata.png"}
              alt="Duplicata"
              style={{
                display: "block",
                marginLeft: "80%",
                width: "30%",
              }}
  
            />
          </div>
        )}
        {isDuplicata ? (
          <>
            <Box className={classes.ticketProducts}>
              <Table className={classes.table}>
                <Box className={classes.ticketHeader}>
                  <Box
                    align={"center"}
                    fontWeight={"bold"}
                    fontSize={"13px"}
                    fontFamily={"Courier Prime"}
                    color={"#707070"}
                  >
                    {t("common:caisse")}: {row.pos_profile}
                  </Box>
                  {row.sales_team && row.sales_team.length != 0 && (
                    <Box
                      align={"center"}
                      fontWeight={"bold"}
                      fontSize={"13px"}
                      fontFamily={"Courier Prime"}
                      color={"#707070"}
                    >
                      {"Vendeur: " + row.sales_team.map((s) => s.sales_person)}
                    </Box>
                  )}
                  <Box
                    align={"center"}
                    fontWeight={"bold"}
                    fontSize={"13px"}
                    fontFamily={"Courier Prime"}
                    color={"#707070"}
                  >
                    {t("common:client")}: {row.customer_name}
                  </Box>
                </Box>
              </Table>
            </Box>
            <Box className={classes.ticketProducts}>
              <Table className={classes.table}>
                <TableHead className={classes.tablecellHeader}>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      <Box fontFamily={"Courier Prime"} color={"#707070"}>
                        {t("common:designation")}
                      </Box>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Box
                        align={"right"}
                        fontFamily={"Courier Prime"}
                        color={"#707070"}
                      >
                        {t("common:amnt")} TTC
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.items.map((product) => (
                    <TableRow
                      key={product?.item_name}
                      className={classes.tableRow}
                    >
                      <TableCell className={classes.tableCell}>
                        <Box
                          fontWeight={"bold"}
                          fontFamily={"Courier Prime"}
                          color={"#707070"}
                        >
                          {product.qty}X {product.item_name} {product.item_code}
                        </Box>
                        <Box fontFamily={"Courier Prime"} color={"#707070"}>
                          P.U: {prixTTC(product)?.toFixed(3)}
                          {""} {currencySymbol}
                        </Box>
                        {product.discount_percentage != 0 ? (
                          <Box
                            fontSize={"12px"}
                            fontFamily={"Courier Prime"}
                            color={"#707070"}
                          >
                            {t("common:remise")}: {product.discount_percentage}%{" "}
                          </Box>
                        ) : null}
                        {product.item_tax_template ? (
                          <Box
                            fontSize={"12px"}
                            fontFamily={"Courier Prime"}
                            color={"#707070"}
                          >
                            {tvaTaxes(product)}
                            {""} {currencySymbol}
                          </Box>
                        ) : null}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Box
                          align={"right"}
                          bottom={"0"}
                          fontFamily={"Courier Prime"}
                          color={"#707070"}
                        >
                          {(
                            (parseFloat(product.qty) * prixTTC(product)) /
                            Object.values(exchangeRate)
                          ).toFixed(3)}{" "}
                          {currencySymbol}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      {row.payments.map((payment) => (
                        <Box
                          key={payment?.type}
                          fontFamily={"Courier Prime"}
                          color={"#707070"}
                        >
                          {payment?.mode_of_payment
                            ? payment?.mode_of_payment
                            : payment?.type}
                        </Box>
                      ))}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.payments.map((payment) => (
                        <Box
                          key={payment?.amount}
                          align={"right"}
                          fontFamily={"Courier Prime"}
                          color={"#707070"}
                        >
                          {(
                            payment.amount / Object.values(exchangeRate)
                          ).toFixed(3)}{" "}
                          {currencySymbol}
                        </Box>
                      ))}
                    </TableCell>
                  </TableRow>
                  {row.discount_amount != 0 && (
                    <TableRow className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>
                        <Box fontFamily={"Courier Prime"} color={"#707070"}>
                          {t("common:remise")}:{" "}
                        </Box>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Box
                          align={"right"}
                          fontFamily={"Courier Prime"}
                          color={"#707070"}
                        >
                          {row.additional_discount_percentage} % (
                          {row.discount_amount / Object.values(exchangeRate)}{" "}
                          {currencySymbol}){" "}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </>
        ) : (
          <>
            <Box className={classes.ticketProducts}>
              <Table className={classes.table}>
                <Box className={classes.ticketHeader}>
                  <Box
                    align={"center"}
                    fontWeight={"bold"}
                    fontSize={"13px"}
                    fontFamily={"Courier Prime"}
                    color={"#707070"}
                  >
                    {t("common:caisse")}: {cashier && cashier.cashier}
                  </Box>
                  {selectedSalesPerson && selectedSalesPerson.length != 0 && (
                    <Box
                      align={"center"}
                      fontWeight={"bold"}
                      fontSize={"13px"}
                      fontFamily={"Courier Prime"}
                      color={"#707070"}
                    >
                      {"Vendeur: " + allSalesPerson.map((s) => s)}
                    </Box>
                  )}
                  <Box
                    align={"center"}
                    fontWeight={"bold"}
                    fontSize={"13px"}
                    fontFamily={"Courier Prime"}
                    color={"#707070"}
                  >
                    {t("common:client")}:{" "}
                    {selectedCustomer && selectedCustomer.customer_name}
                  </Box>
                </Box>
              </Table>
            </Box>
            <Box className={classes.ticketProducts}>
              <Table className={classes.table}>
                <TableHead className={classes.tablecellHeader}>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      <Box fontFamily={"Courier Prime"} color={"#707070"}>
                        {t("common:designation")}
                      </Box>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Box
                        align={"right"}
                        fontFamily={"Courier Prime"}
                        color={"#707070"}
                      >
                        {t("common:amnt")} TTC
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FreeItemTableBody
                    products={
                      hasFreeItemData() && !ignorePricingRule && order
                        ? order?.docs[0]?.items
                        : products
                    }
                    classes={classes}
                    currencySymbol={currencySymbol}
                    ignorePricingRule={ignorePricingRule}
                    isPricingRule={
                      hasFreeItemData() && !ignorePricingRule ? true : false
                    }
                  />
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      {amount.payments.map((payment) => (
                        <>
                          <Box
                            key={payment?.name}
                            fontFamily={"Courier Prime"}
                            color={"#707070"}
                          >
   
                              {t(`common:${payment?.name.replace(/\s/g, '')}`)}
                          

                                      
                          </Box> 
                        </>
                      ))}
                      {appliedLoyaltyCard?.redeem_points_amount ? (
                        <Box
                          key={"loyalty"}
                          fontFamily={"Courier Prime"}
                          color={"#707070"}
                        >
                          {t("clients:loyalty")}
                        </Box>
                      ) : null}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {amount.payments.map((payment) => (
                        <Box
                          key={payment?.amount}
                          align={"right"}
                          fontFamily={"Courier Prime"}
                          color={"#707070"}
                        >
                          {(
                            payment.amount / Object.values(exchangeRate)
                          ).toFixed(3)}{" "}
                          {currencySymbol}
                        </Box>
                      ))}
                      {appliedLoyaltyCard?.redeem_points_amount ? (
                        <Box
                          key={appliedLoyaltyCard?.redeem_points_amount}
                          align={"right"}
                          fontFamily={"Courier Prime"}
                          color={"#707070"}
                        >
                          {appliedLoyaltyCard?.redeem_points_amount?.toFixed(3)}{" "}
                          {currencySymbol}
                        </Box>
                      ) : null}
                    </TableCell>
                  </TableRow>
                  {discount !== 0 && (
                  <TableRow className={classes.tableRow}>
                   <TableCell className={classes.tableCell}>
                      <Box fontFamily={"Courier Prime"} color={"#707070"}>
                       {t("common:remise")}:
                     </Box>
                   </TableCell>
                   <TableCell className={classes.tableCell}>
                       <Box align={"right"} fontFamily={"Courier Prime"} color={"#707070"}>
                        {discount}%
                       </Box>
                      <Box align={"right"} fontFamily={"Courier Prime"} color={"#707070"}>{t("common:amnt")}: 
                       {(
                        (discount / 100) * (amount.totalAmount)
                       ).toFixed(3)}{" "}
                       {currencySymbol}
                     </Box>
                   </TableCell>
                 </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </>
        )}
        {isDuplicata ? (
          <>
            <Box
              align={"center"}
              fontWeight={"bold"}
              fontSize={"15px"}
              fontFamily={"Courier Prime"}
              color={"#707070"}
            >
              {t("common:amnt")} H.T.:{" "}
              {(row.net_total / Object.values(exchangeRate)).toFixed(3)}{" "}
              {currencySymbol}
            </Box>
            <Box
              align={"center"}
              fontWeight={"bold"}
              fontSize={"10px"}
              fontFamily={"Courier Prime"}
              color={"#707070"}
            >
              T.V.A.:{" "}
              {(
                row.total_taxes_and_charges / Object.values(exchangeRate)
              ).toFixed(3)}{" "}
              {currencySymbol}
            </Box>
            <Box
              align={"center"}
              fontWeight={"bold"}
              fontSize={"20px"}
              fontFamily={"Courier Prime"}
              color={"#707070"}
            >
              {t("common:grandTotal")}:{" "}
              {(row.grand_total / Object.values(exchangeRate)).toFixed(3)}{" "}
              {currencySymbol}
            </Box>
            <Box align={"center"}>
              {row.items.length} {t("common:article")}
            </Box>
          {/* <Box className={classes.ticketFooter} textAlign="center">
            <QRCode
                size={256}
                style={{ height: "50%", width: "50%" }}
                value={row.qr_code}
            />
        </Box> */}
          </>
        ) : (
          <>
            {holdselected && holdselected?.amount?.payments?.length > 0 ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#707070",
                  color: "white",
                  margin: 10,
                  padding: 10,
                }}
                align={"center"}
                fontFamily={"Courier Prime"}
              >
                <span style={{ fontWeight: "bold", fontSize: 13 }}>
                  {t("common:advance")} :{" "}
                  {holdselected?.amount?.payments
                    .map((item) => item.amount)
                    .reduce((prev, curr) => prev + curr, 0)
                    .toFixed(3)}{" "}
                  {currencySymbol}
                </span>
              </Box>
            ) : null}
            {order?.docs[0]?.redeem_loyalty_points === 1 ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#707070",
                  color: "white",
                  margin: 10,
                  padding: 10,
                }}
                align={"center"}
                fontFamily={"Courier Prime"}
              >
                <span style={{ fontWeight: "bold", fontSize: 13 }}>
                {t("checkout:lprg")}: {order?.docs[0]?.loyalty_program}
                </span>
                <span style={{ fontWeight: "lighter", fontSize: 11 }}>
                {t("checkout:PointsUsed")} : {order?.docs[0]?.loyalty_points}
                </span>
                <span style={{ fontWeight: "bold", fontSize: 13 }}>
                {t("checkout:PointsNow")} :{" "}
                  {detailClient?.__onload?.dashboard_info[0]?.loyalty_points ??
                    0}
                </span>
              </Box>
            ) : detailClient?.loyalty_program &&
              detailClient?.__onload?.dashboard_info[0]?.loyalty_points ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#707070",
                  color: "white",
                  margin: 10,
                  padding: 10,
                }}
                align={"center"}
                fontFamily={"Courier Prime"}
              >
                <span style={{ fontWeight: "bold", fontSize: 13 }}>
                {t("checkout:lprg")}: {detailClient?.loyalty_program}
                </span>
                <span style={{ fontWeight: "bold", fontSize: 13 }}>
                {t("checkout:ttlpoints")}:{" "}
                  {detailClient?.__onload?.dashboard_info[0]?.loyalty_points ??
                    0}
                </span>
              </Box>
            ) : null}
            {coupon?.name && (
              <Box
                align={"center"}
                fontWeight={"bold"}
                fontSize={"15px"}
                fontFamily={"Courier Prime"}
                color={"#707070"}
              >
                {`${t("checkout:couponCode")}: ${coupon?.name}`}
              </Box>
            )}
            <Box
              align={"center"}
              fontWeight={"bold"}
              fontSize={"15px"}
              fontFamily={"Courier Prime"}
              color={"#707070"}
            >
              {t("common:amnt")} H.T.:{" "}
              {order
                ? (
                    order.docs[0].net_total / Object.values(exchangeRate)
                  ).toFixed(3)
                : discount != 0
                ? (
                    ((1 - discount / 100) *
                      (amount.totalAmount - amount.taxAmount)) /
                    Object.values(exchangeRate)
                  ).toFixed(3)
                : (amount.totalAmount - amount.taxAmount).toFixed(3)}{" "}
              {currencySymbol}
            </Box>
            <Box
              align={"center"}
              fontWeight={"bold"}
              fontSize={"10px"}
              fontFamily={"Courier Prime"}
              color={"#707070"}
            >
              T.V.A.:{" "}
              {order
                ? (
                    order.docs[0].total_taxes_and_charges /
                    Object.values(exchangeRate)
                  ).toFixed(3)
                : discount != 0
                ? (
                    ((1 - discount / 100) * amount.taxAmount) /
                    Object.values(exchangeRate)
                  ).toFixed(3)
                : (amount.taxAmount / Object.values(exchangeRate)).toFixed(
                    3
                  )}{" "}
              {currencySymbol}
            </Box>
            <Box
              align={"center"}
              fontWeight={"bold"}
              fontSize={"20px"}
              fontFamily={"Courier Prime"}
              color={"#707070"}
            >
              {t("common:grandTotal")}:{" "}
              {order
                ? (
                    order.docs[0].grand_total / Object.values(exchangeRate)
                  ).toFixed(3)
                : (
                    amount.payments.reduce(
                      (a, v) => (a = a + parseFloat(v.amount)),
                      0
                    ) / Object.values(exchangeRate)
                  ).toFixed(3)}{" "}
              {currencySymbol}
            </Box>
            <Box align={"center"}>
              {order ? order.docs[0].total_qty : products.length}{" "}
              {t("common:article")}
              </Box>
              


          </>
        )}
        
        <Box className={classes.ticketFooter} textAlign="center">
          {t("payement:msgRetour")}
          <br />
          {t("payement:msgComing")}
        </Box>
      </Box>
    </Box>
  );
});
