import React, { useRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { useTableStyles } from "../../../../styles/table.style";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../../helpers/utils";
import ReactToPrint from "react-to-print";
import {
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import { LoyaltyPointComponentToPrint } from "../../../../components/componentToPrint/loyaltyPoint";
import { useTranslation } from "react-i18next";
import { SetNotification } from "../../../../store/notification/notification.action";
import { SecondSetDetailClient } from "../../../../store/client/client.action";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1.0625rem 1.5rem",
  },
  price: {
    fontWeight: 600,
  },
  filterList: {
    borderTop: "none",
  },
  button: {
    fontSize: 14,
    backgroundColor: theme.palette.productsTable.button,
    color: theme.palette.productsTable.buttonColor,
    "&:hover": {
      backgroundColor: theme.palette.productsTable.button,
    },
  },
  print: {
    display: "none",
  },
  card: {
    background: "white",
    borderRadius: 10,
    border: "none",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  pointsCard: {
    padding: "10px 15px",
    borderRadius: 10,
    backgroundColor: theme.palette.grey[200],
    display: "flex",
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
  },
}));

export const ClientLoyaltyDetails = ({ client }) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const loyaltyPoints = useSelector((state) => state.Client.loyaltyPoints);
  const loyaltyPrograms = useSelector((state) => state.Cart.loyaltyPrograms);
  const { t } = useTranslation(["common", "clients", "order", "checkout"]);

  const [page, setPage] = React.useState(1);

  const [formattedData, setFormattedData] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [state, setState] = useState({
    lp: "",
    tier: "",
  });

  const handleChangeForm = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleRemove = () => {
    setState({
      lp: "",
      tier: "",
    });
  };

  const handleSaveLp = () => {
    setLoadingButton(true);
    const data = {
      doc: {
        name: client?.name,
        owner: client?.owner,
        creation: client?.creation,
        modified: client?.modified,
        modified_by: client?.modified_by,
        naming_series: client?.naming_series,
        docstatus: 0,
        customer_name: client?.customer_name,
        customer_group: client?.customer_group,
        customer_type: client?.customer_type,
        territory: client?.territory,
        doctype: "Customer",
        __onload: client?.__onload,
        loyalty_program: state.lp,
        __unsaved: 1,
        loyalty_program_tier: state.tier,
      },
      action: "Save",
    };
    fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user"))?.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        setLoadingButton(false);
        if (!res.ok) {
          dispatch(
            SetNotification({ code: "error", message: "Something went wrong" })
          );
        }
        return res.json();
      })
      .then((data) => {
        data?.docs?.[0] && dispatch(SecondSetDetailClient(data?.docs?.[0]));
        if (data?.success === false) {
          dispatch(
            SetNotification({ code: "warning", message: data?.message })
          );
        } else {
          dispatch(
            SetNotification({
              code: "success",
              message: `Loyalty information for the customer has been successfully saved.`,
            })
          );
        }
      });
  };

  useEffect(() => {
    if (loyaltyPrograms && loyaltyPrograms?.length !== 0) {
      const result = Object.values(
        loyaltyPrograms.reduce((acc, cur) => {
          if (acc[cur.name]) {
            acc[cur.name].push(cur.tier_name);
          } else {
            acc[cur.name] = [cur.tier_name];
          }
          return acc;
        }, {})
      ).map((tier_names, index) => ({
        name: Object.keys(
          loyaltyPrograms.reduce((acc, cur) => {
            acc[cur.name] = true;
            return acc;
          }, {})
        )[index],
        tier_names,
      }));
      setFormattedData(result);
    }
  }, [loyaltyPrograms]);

  useEffect(() => {
    if (client) {
      setState({
        lp: client?.loyalty_program,
        tier: client?.loyalty_program_tier,
      });
    }
  }, [client]);

  return (
    <Box className={clsx(classes.root, tableClasses.paper)}>
      <Table className={tableClasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:date")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:ref")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("clients:lyltyPoints")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="center">
              {t("clients:expire")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(loyaltyPoints) &&
            loyaltyPoints.slice((page - 1) * 3, page * 3).map((row, index) => (
              <>
                <TableRow className={tableClasses.tableRow} key={index}>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {formatDate(row.creation, "YYYY-MM-DD")}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {row.name}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {row.loyalty_points}
                  </TableCell>
                  <TableCell
                    className={clsx(tableClasses.tableCell, classes.price)}
                    align="center"
                  >
                    {row.expiry_date}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="left">
                    <ReactToPrint
                      trigger={() => (
                        <Button className={classes.button}>Imprimer</Button>
                      )}
                      content={() => componentRef.current}
                    />
                  </TableCell>
                </TableRow>
                <div className={classes.print}>
                  <LoyaltyPointComponentToPrint
                    document={row}
                    order={row}
                    title={t("clients:BA")}
                    ref={componentRef}
                  />
                </div>
              </>
            ))}
        </TableBody>
      </Table>
      {Array.isArray(loyaltyPoints) &&
        Math.ceil(loyaltyPoints?.length / 3) > 0 && (
          <Box mt={2} display={"flex"} justifyContent={"right"}>
            <Pagination
              onChange={(event, newPage) => setPage(newPage)}
              defaultPage={page}
              count={Math.ceil(loyaltyPoints?.length / 3)}
              color="primary"
            />
          </Box>
        )}
      <Divider style={{ margin: 20 }} />
      <div className={classes.card}>
        <FormControl style={{ width: "100%" }} variant="outlined">
          <InputLabel id="demo-simple-select-label">
            {t("checkout:lprg")}
          </InputLabel>
          <Select
            name={"lp"}
            value={state.lp}
            onChange={handleChangeForm}
            labelId="demo-simple-select-label"
            id="demo-multiple-name"
            label={t("checkout:lprg")}
            endAdornment={
              state.lp && (
                <IconButton
                  style={{ marginRight: "5%" }}
                  onClick={handleRemove}
                >
                  <CloseIcon color="error" />
                </IconButton>
              )
            }
          >
            {formattedData?.map((row, i) => (
              <MenuItem key={`${row?.name}-${i}`} value={row?.name}>
                {row?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: "100%" }} variant="outlined">
          <InputLabel id="demo-simple-select-label">Tier</InputLabel>
          <Select
            name={"tier"}
            value={state.tier}
            onChange={handleChangeForm}
            labelId="demo-simple-select-label"
            id="demo-multiple-name"
            label="Tier"
          >
            {state.lp &&
              formattedData
                ?.filter((el) => el?.name === state.lp)?.[0]
                ?.tier_names?.map((row, i) => (
                  <MenuItem key={`${"tier"}-${i}`} value={row}>
                    {row}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        <Box className={classes.pointsCard}>
          <div
            style={{
              position: "relative",
              backgroundColor: "Highlight",
              width: 35,
              height: 35,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 5,
            }}
          >
            <CardMembershipIcon
              style={{ color: "white", width: 25, height: 25 }}
            />
          </div>
          <Typography
            variant="body1"
            style={{ fontWeight: "bold", color: "Highlight" }}
          >
            {t("checkout:lp")}:{" "}
            {client?.__onload?.dashboard_info[0]?.loyalty_points ?? 0}
          </Typography>
        </Box>
        <Box style={{ display: "flex", justifyContent: "right" }}>
          <Button
            size="large"
            loading={loadingButton}
            color="primary"
            variant="contained"
            onClick={handleSaveLp}
            disabled={
              state.lp === client?.loyalty_program &&
              state.tier === client?.loyalty_program_tier
            }
          >
            {t("checkout:save")}
          </Button>
        </Box>
      </div>
    </Box>
  );
};
