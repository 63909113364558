import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../pages/payement/style";
import { formatDate } from "../../../helpers/utils";

export const Voucher = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { t } = useTranslation(["payement", "common", "checkout"]);

  const posOpenedDate = useSelector((state) => state.Login.posOpeningDate);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const clients = useSelector((state) => state.Client.clients);
  const [client, setClient] = useState(
    clients.find((cl) => cl?.name == props?.document?.customer)
  );

  return (
    <Box className={classes.ticket} ref={ref}>
      <Box className={classes.ticketBlock}>
        <Box className={classes.ticketHeader}>
          <div className={classes.logo}>
            <img alt="" src={activeImageTheme} />
          </div>
          {selectedData ? (
            <>
              <Box>{globalDefaults?.default_company}</Box>
              <Box fontWeight={"normal"} fontSize={"12px"}>
                {selectedData?.store ?? selectedData?.store}
              </Box>
              <Box fontWeight={"normal"} fontSize={"12px"}>
                {selectedData?.cashier ?? selectedData?.cashier}
              </Box>

              {selectedData?.contact &&
                Array.isArray(selectedData?.contact) &&
                selectedData?.contact?.map((row) => (
                  <>
                    <Box fontWeight={"normal"} fontSize={"12px"}>
                      {t("common:addr")}:{" "}
                      {row?.address_line_1 === null ? "-" : row?.address_line_1}
                    </Box>
                    <Box fontWeight={"normal"} fontSize={"12px"}>
                      {t("common:city")}: {row?.city === null ? "-" : row?.city}
                    </Box>
                    <Box fontWeight={"normal"} fontSize={"12px"}>
                      {t("common:tels")}:{" "}
                      {row?.phone_no === null ? "-" : row?.phone_no} /{" "}
                      {row?.mobile_no === null ? "-" : row?.mobile_no}
                    </Box>
                  </>
                ))}
            </>
          ) : (
            <></>
          )}

          <Box fontWeight={"normal"} fontSize={"12px"}>
            {formatDate(posOpenedDate, "LL")} {formatDate(Date(), "HH:mm:ss")}
          </Box>
          <Box fontWeight={"bold"} fontSize={"15px"}>
            {"Avoir N° : "}
            {props?.document?.name}
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          {props?.document && (
            <Box style={{ width: "70%" }}>
              <div>
                <strong>
                  {t("common:montant")} {": "}
                </strong>
                {props?.document?.credit_amount?.toFixed(3)}{" "}
                {props?.document?.currency}
              </div>
              <div>
                <strong>
                  {t("common:client")} {": "}
                </strong>
                {props?.document?.customer ?? props?.document?.customer}
              </div>
              <div>
                <strong>
                  {t("common:contact")}
                  {": "}
                </strong>
                {client?.mobile_no
                  ? client?.mobile_no
                  : client?.email_id
                  ? client?.email_id
                  : "-"}
              </div>

              <div>
                <strong>
                  {t("checkout:ValidFrom")} {": "}
                </strong>
                {props?.document?.creation_date}
              </div>
              <div>
                <strong>
                  {t("checkout:ValidUntil")} {": "}
                </strong>
                {`${formatDate(
                  new Date(new Date().getFullYear(), 11, 31),
                  "YYYY-MM-DD"
                )}`}
              </div>
            </Box>
          )}
        </Box>
        <Box className={classes.ticketFooter} textAlign="center">
          <br />
          {t("payement:msgComing")}
        </Box>
      </Box>
    </Box>
  );
});
