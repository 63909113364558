import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { LoadFullfilments } from "../../store/fullfilment/fullfilment.action";
import { HoldsPage } from "./components/holdPage";
import { ReservationPage } from "./components/reservation";

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      borderBottom: "2px solid #ECF2F5",
    },
  },
  tab: {
    borderBottom: "none",
    fontSize: "0.8rem",
  },
}));

export const PosOrderTabs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const { t } = useTranslation(["clients", "common", "fulfillment"]);
  let query = useQuery();

  const [value, setValue] = React.useState(
    query.get("tab") ? parseInt(query.get("tab")) : 0
  );
  const selectedData = useSelector((state) => state.Login.selectedData);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (globalDefaults?.default_company)
      selectedData?.store &&
        dispatch(
          LoadFullfilments(selectedData.store, globalDefaults?.default_company)
        );
  }, [globalDefaults?.default_company]);

  return (
    <div className={classes.root}>
      <Box position="static" color="default">
        <Tabs
          value={value}
          className={classes.tabs}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            className={classes.tab}
            label={t("common:awaitingOrders")}
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tab}
            label={t("common:orderReserved")}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <HoldsPage />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ReservationPage />
      </TabPanel>
    </div>
  );
};
