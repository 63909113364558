import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Button,
  Modal,
  MenuItem,
  Menu,
  Typography,
  Paper,
  ClickAwayListener,
  List,
  ListItem,
  IconButton,
  ListItemText,
} from "@material-ui/core";
import { useStyles } from "../../style";
import { useTableStyles } from "../../../../styles/table.style";
import { useDispatch, useSelector } from "react-redux";
import {
  CreatePurchaseInvoiceRequest,
  createPurchaseInvoiceDoc,
  SetPurchaseInvoiceMappedDoc,
} from "../../../../store/buying/buying.action";
import { Loader } from "../../../../components/loader";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import { statusFormat } from "../../../../helpers/utils";
import { PurchaseRecDetails } from "./purchaseRecDetails";
import SubmitPurchaseReceipt from "../purchaseInvoiceReq/submitPurchaseReceipt";
import PrintPurchaseReceipt from "../purchaseInvoiceReq/printPurchaseReceipt";
import CreatePurchaseReturn from "../purchaseInvoiceReq/create/createPurchaseReturn";
import CreateMakeStockEntry from "../purchaseInvoiceReq/create/createMakeStockEntry";
import CreateSubscribtion from "../purchaseInvoiceReq/create/createSubscribtion";
import CreateRetentionStockEntry from "../purchaseInvoiceReq/create/createRetentionStockEntry";
import { SetNotification } from "../../../../store/notification/notification.action";
import { Comment } from "@material-ui/icons";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import RecapPurchaseReceipt from "../purchaseInvoiceReq/recapPurchaseReceipt";
import DuplicatePurchaseInvoice from "./DuplicatePurchaseInvoice";
import DuplicatePurchaseReceipt from "./DuplicatePurchaseReceipt";

export const PurhaseInvoiceTable = (props) => {
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "buying"]);
  const [open, setOpen] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openDuplicatePurchaseReceipt, setOpenDuplicatePurchaseReceipt] = useState(false);
  const [openPrintPurchaseReceipt, setOpenPrintPurchaseReceipt] =
    useState(false);
    const [ openDuplicateModal,setOpenDuplicateModal] = useState(false);
  const [openCreatePurchaseReturnModal, setOpenCreatePurchaseReturnModal] =
    useState(false);
  const [openCreatePurchaseMakeStock, setOpenCreateMakeStockEntryModal] =
    useState(false);
  const [
    openCreateRetentionStockEntryModal,
    setOpenCreateRetentionStockEntryModal,
  ] = useState(false);
  const [openCreateSubscriptionModal, setOpenCreateSubscriptionModal] =
    useState(false);
  const [showIcons, setShowIcons] = useState(true); // State variable to track visibility of icons
const [isAmend, setIsAmend] = useState(false);
const[openRecapPurchaseReceipt, setOpenRecapPurchaseReceipt] = useState(false);
const [openDuplicatePurchaseReceiptModal,setOpenDuplicatePurchaseReceiptModal] = useState(false)




  const puchaseInvoiceDoc = useSelector(
    (state) => state.Buying.puchaseInvoiceDoc
  );

  const handleCloseModal = () => {
    setOpenDetailsModal(false);
    dispatch(SetPurchaseInvoiceMappedDoc(null));
  };

  const handleCreatePurInvoice = () => {
    let puchaseInvoiceDocMapped = {
      ...puchaseInvoiceDoc,
      is_subcontracted: 0,
    };
    dispatch(CreatePurchaseInvoiceRequest(puchaseInvoiceDocMapped));
    handleCloseModal();
  };

  const handleAmendSupplierQuotation = (row) => {
    const data = {
      doctype: "Purchase Receipt",
      docname: row?.name,
    };

    fetch(`${process.env.REACT_APP_API_URI}/api/amend`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user"))?.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        setLoadingButton(false);

        if (!res.ok) {
          // dispatch(
          //     SetNotification({ code: "error", message: "Something went wrong" })
          // );
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === false) {
          setLoadingButton(false);
          dispatch(
            SetNotification({ code: "warning", message: data?.message })
          );
        } else {
          setLoadingButton(false);
          setIsAmend(true);
          // dispatch(
          //     SetNotification({
          //       code: "success",
          //       message: `${t("common:DeleteQuotationSuccess")}`,
          //     })
          // );
          handleOpenModal(row);
          //  handleReload();
        }
      });
  };

  const handleOpenModal = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };
  const handleOpenPrintModal = (row) => {
    setSelectedRow(row);
    setOpenPrintPurchaseReceipt(true);
  };
  const handleOpenModalPurchaseReceipt = (row) => {
    setSelectedRow(row);
    setOpenDuplicatePurchaseReceipt(row);

  }
  const handleDuplcatepurchaseOrder = (row) => {
     setSelectedRow(row);
     setOpenDuplicateModal(true);
  }
  const handleCancelpurchaseOrder = (row) => {
    const data = {
      doctype: "Purchase Receipt",
      name: row?.name,
    };

    fetch(`${process.env.REACT_APP_API_URI}/api/cancel-doc`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user"))?.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        setLoadingButton(false);

        if (!res.ok) {
          dispatch(
            SetNotification({ code: "error", message: "Something went wrong" })
          );
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === false) {
          setLoadingButton(false);
          dispatch(
            SetNotification({ code: "warning", message: data?.message })
          );
        } else {
          setLoadingButton(false);
          dispatch(
            SetNotification({
              code: "success",
              message: `${t("common:CancelFactureSuccess")}`,
            })
          );

          handleReload();
        }
      });
  };
  const handleDeletepurchaseOrder = (row) => {
    const data = {
      doctype: "Purchase Receipt",
      name: row?.name,
    };

    fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user"))?.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        setLoadingButton(false);

        if (!res.ok) {
          dispatch(
            SetNotification({ code: "error", message: "Something went wrong" })
          );
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === false) {
          setLoadingButton(false);
          dispatch(
            SetNotification({ code: "warning", message: data?.message })
          );
        } else {
          setLoadingButton(false);
          dispatch(
            SetNotification({
              code: "success",
              message: `${t("common:DeleteFactureSuccess")}`,
            })
          );

          handleReload();
        }
      });
  };
  const handleReload = () => {
    window.location.reload();
  };

  const handleOpenRecapPurchaseReceiptModal = (row) => {
    setSelectedRow(row);
    setOpenRecapPurchaseReceipt(true);
  };
  const handleCloseRecapPurchaseReceiptModal = () => {
    setOpen(false);
  };









  const handleCloseModalPurchaseReceipt = () => {
    setOpen(false);
  };
  const handleCloseModalDuplicate = () => {
    setOpenDuplicateModal(false);
  }
  const handleCloseModalDuplicatePurchaseReceipt = () => {
    setOpenDuplicatePurchaseReceiptModal(false);
  }
  const handlePurInvoiceDetails = (id) => {
    setOpenDetailsModal(true);
    dispatch(createPurchaseInvoiceDoc(id));
  };
  const handleOpenModalCreatePurchaseReturn = (row) => {
    setSelectedRow(row);
    setOpenCreatePurchaseReturnModal(true);
  };
  const handleOpenModalCreatePurchaseMakeStock = (row) => {
    setSelectedRow(row);
    setOpenCreateMakeStockEntryModal(true);
  };
  const handleOpenModalCreateRetentionStockEntry = (row) => {
    setSelectedRow(row);
    setOpenCreateRetentionStockEntryModal(true);
  };
  const handleOpenModalSubscription = (row) => {
    setSelectedRow(row);
    setOpenCreateSubscriptionModal(true);
  };
  const handleCloseModalPurchaseReturn = () => {
    setOpenCreatePurchaseReturnModal(false);
  };
  const handleCloseModalPurchaseMakeStock = () => {
    setOpenCreateMakeStockEntryModal(false);
  };
  const handleCloseModalSubscription = () => {
    setOpenCreateSubscriptionModal(false);
  };
  const handleCloseModalRetentionStockEntry = () => {
    setOpenCreateRetentionStockEntryModal(false);
  };
  const [anchorEl, setAnchorEl] = useState(
    Array(props.purchaseReceiptList.length).fill(null)
  );
  const [anchorEl2, setAnchorEl2] = useState(
    Array(props.purchaseReceiptList.length).fill(null)
  );
  const handleClick = (event, index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
  };
  const handleClick2 = (event, index) => {
    const newAnchorEl2 = [...anchorEl2];
    newAnchorEl2[index] = event.currentTarget;
    setAnchorEl2(newAnchorEl2);
  };
  const handleClose = (index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };
  const handleClose2 = (index) => {
    const newAnchorEl2 = [...anchorEl2];
    newAnchorEl2[index] = null;
    setAnchorEl2(newAnchorEl2);
  };
  return (
    <Box className={tableclasses.paper}>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/*   <ClickAwayListener
        onClickAway={() => (setOpenModal ? setOpenModal(false) : null)}
      > */}
        <div
          className={classes.paper}
          style={{ overflowY: "auto", maxHeight: "90vh", width: "70%" }}
        >
          <SubmitPurchaseReceipt
            setCloseModal={handleCloseModalPurchaseReceipt}
            id={selectedRow}
            isAmend={isAmend}
          />
        </div>
        {/*   </ClickAwayListener> */}
      </Modal>
      <Modal
        open={openPrintPurchaseReceipt}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ClickAwayListener
          onClickAway={() =>
            setOpenPrintPurchaseReceipt
              ? setOpenPrintPurchaseReceipt(false)
              : null
          }
        >
          <div
            className={classes.paper}
            style={{
              overflowY: "auto",
              maxHeight: "90vh",
              width: "auto",
              height: "100%",
              aspectRatio: "1.414",
            }}
          >
            <PrintPurchaseReceipt
              setCloseModal={handleCloseModalPurchaseReceipt}
              id={selectedRow}
            />
          </div>
        </ClickAwayListener>
      </Modal>
    
      <Modal
        open={openCreatePurchaseReturnModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/*   <ClickAwayListener
        onClickAway={() => (setOpenModal ? setOpenModal(false) : null)}
      > */}
        <div
          className={classes.paper}
          style={{ overflowY: "auto", maxHeight: "90vh", width: "70%" }}
        >
          {/* <CreateSupplierQuotation setCloseModal={handleCloseModalSupplierQuotation} id={selectedRow}/> */}
          <CreatePurchaseReturn
            setCloseModal={handleCloseModalPurchaseReturn}
            id={selectedRow}
          />
        </div>
        {/*   </ClickAwayListener> */}
      </Modal>
      <Modal
        open={openCreatePurchaseMakeStock}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/*   <ClickAwayListener
        onClickAway={() => (setOpenModal ? setOpenModal(false) : null)}
      > */}
        <div
          className={classes.paper}
          style={{ overflowY: "auto", maxHeight: "90vh", width: "70%" }}
        >
          {/* <CreateSupplierQuotation setCloseModal={handleCloseModalSupplierQuotation} id={selectedRow}/> */}
          <CreateMakeStockEntry
            setCloseModal={handleCloseModalPurchaseMakeStock}
            id={selectedRow}
          />
        </div>
        {/*   </ClickAwayListener> */}
      </Modal>
      <Modal
        open={openCreateSubscriptionModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/*   <ClickAwayListener
        onClickAway={() => (setOpenModal ? setOpenModal(false) : null)}
      > */}
        <div
          className={classes.paper}
          style={{ overflowY: "auto", maxHeight: "90vh", width: "40%" }}
        >
          {/* <CreateSupplierQuotation setCloseModal={handleCloseModalSupplierQuotation} id={selectedRow}/> */}
          <CreateSubscribtion
            setCloseModal={handleCloseModalSubscription}
            id={selectedRow}
          />
        </div>
        {/*   </ClickAwayListener> */}
      </Modal>

      <Modal
        open={openCreateRetentionStockEntryModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/*   <ClickAwayListener
        onClickAway={() => (setOpenModal ? setOpenModal(false) : null)}
      > */}
        <div
          className={classes.paper}
          style={{ overflowY: "auto", maxHeight: "90vh", width: "70%" }}
        >
          {/* <CreateSupplierQuotation setCloseModal={handleCloseModalSupplierQuotation} id={selectedRow}/> */}
          <CreateRetentionStockEntry
            setCloseModal={handleCloseModalRetentionStockEntry}
            id={selectedRow}
          />
        </div>
        {/*   </ClickAwayListener> */}
      </Modal>

      <Modal
         open={openDuplicatePurchaseReceipt}
       
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/*   <ClickAwayListener
        onClickAway={() => (setOpenModal ? setOpenModal(false) : null)}
      > */}
        <div
          className={classes.paper}
          style={{ overflowY: "auto", maxHeight: "90vh", width: "70%" }}
        >
          {/* <CreateSupplierQuotation setCloseModal={handleCloseModalSupplierQuotation} id={selectedRow}/> */}
          <DuplicatePurchaseReceipt
            setCloseModal={() => setOpenDuplicatePurchaseReceipt(false)}
            id={selectedRow}
          />
        </div>
        {/*   </ClickAwayListener> */}
      </Modal>
      <Table className={tableclasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:ref")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:createDate")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("buying:SupplierName")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:total")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:Taxation")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:grandTotal")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:statut")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.purchaseReceiptList &&
            Array.isArray(props.purchaseReceiptList) &&
            props.purchaseReceiptList.map((row, index) => (
              <TableRow className={tableclasses.tableRow} key={index}>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.name}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.posting_date}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.supplier}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.total.toFixed(3)} {row?.currency}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.total_taxes_and_charges.toFixed(3)} {row?.currency}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.grand_total.toFixed(3)} {row?.currency}
                </TableCell>
                <TableCell
                  className={clsx(
                    tableclasses.tableCell,
                    tableclasses[statusFormat(row?.status).color]
                  )}
                  align="left"
                >
                  {statusFormat(row?.status).value}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="right">
                  <Button
                    className={classes.menuButton}
                    size="small"
                    style={{ marginRight: 5 }}
                    variant="outlined"
                    //disabled={row.status !== "Pending"}
                    color="primary"
                    aria-controls={`basic-menu-${index}`}
                    aria-haspopup="true"
                    onClick={(event) => handleClick(event, index)}
                  >
                    {t("common:create")}
                    {!anchorEl[index] && <ArrowDropDownIcon />}
                  </Button>
                  <Menu
                    id={`basic-menu-${index}`}
                    anchorEl={anchorEl[index]}
                    open={Boolean(anchorEl[index])}
                    onClose={() => handleClose(index)}
                    MenuListProps={{
                      "aria-labelledby": `basic-menu-${index}`,
                    }}
                  >
                    <MenuItem
                      disabled={
                        row?.status == "Cancelled" || row?.status == "Draft"
                      }
                      onClick={() => handleOpenModalCreatePurchaseReturn(row)}
                    >
                      {t("common:purchaseReturn")}{" "}
                    </MenuItem>
                    {/* <MenuItem disabled={row?.status == "Cancelled" || row?.status == "Draft"} onClick={()=> handleOpenModalCreatePurchaseMakeStock(row)}>{t('common:makeStockEntry')}</MenuItem> 
                  <MenuItem disabled={row?.status == "Cancelled" || row?.status == "Draft"} onClick={() => handleOpenModalCreateRetentionStockEntry(row)}>{t("common:RetentionStockEntry")} </MenuItem> 
                  <MenuItem disabled={row?.status == "Cancelled" || row?.status == "Draft"} onClick={() => handleOpenModalSubscription(row)}>{t("common:Subscribtion")} </MenuItem> 
                  */}
                  </Menu>
                  <Button
                    disabled={
                      row?.status == "Completed" || row?.status == "Draft"
                    }
                    size={"small"}
                    variant="outlined"
                    color="primary"
                    onClick={() => handlePurInvoiceDetails(row?.name)}
                  >
                    {t("buying:CreateFactAchat")}
                  </Button>
                  <Button
                    disabled={row?.status !== "Draft"}
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenModal(row)}
                    style={{ marginRight: 5, marginLeft: 5 }}
                  >
                    {/* <VisibilityIcon /> */}
                    {t("common:valider")}
                  </Button>

                  {/* <Button
      // disabled={row?.status != "Draft"}
      size="small"
      variant="outlined"
      color="primary"
       onClick={() => handleOpenPrintModal(row)}
      style={{ marginRight: 5 }}
    >
      <LocalPrintshopIcon />
    </Button>  */}
                  <Button
                    className={classes.menuButton2}
                    size="small"
                    style={{ marginRight: 5 }}
                    variant="outlined"
                    //disabled={row.status !== "Pending"}
                    color="primary"
                    aria-controls={`basic-menu-${index}`}
                    aria-haspopup="true"
                    onClick={(event) => handleClick2(event, index)}
                  >
                    {!anchorEl2[index] && <MoreVertIcon />}
                  </Button>
                  <Menu
                    id={`basic-menu-${index}`}
                    anchorEl={anchorEl2[index]}
                    open={Boolean(anchorEl2[index])}
                    onClose={() => handleClose2(index)}
                    MenuListProps={{
                      "aria-labelledby": `basic-menu-${index}`,
                    }}
                  >
                    <MenuItem
                      disabled={
                        row?.status == "Cancelled" ||
                        row?.status == "Draft" ||
                        row?.status == "Completed"
                      }
                      onClick={() => handleCancelpurchaseOrder(row)}
                    >
                      {" "}
                      <CancelIcon style={{ color: "red" }} />{" "}
                    </MenuItem>
                    <MenuItem
                      disabled={row?.status == "Completed"}
                      onClick={() => handleDeletepurchaseOrder(row)}
                    >
                      {" "}
                      <DeleteIcon style={{ color: "red" }} />{" "}
                    </MenuItem>
                    <MenuItem
                      disabled={
                        row?.status === "Expired" ||
                        row?.status === "Pending" ||
                        row?.status === "Draft" ||
                        row?.status === "Completed" ||
                        row?.status === "To Bill"
                      }
                      onClick={() => handleAmendSupplierQuotation(row)}
                    >
                      {" "}
                      <AssignmentReturnIcon style={{ color: "primary" }} />{" "}
                    </MenuItem>
                    <MenuItem onClick={() => handleOpenModalPurchaseReceipt(row)}>
                      {" "}
                      <ContentCopyIcon />{" "}
                    </MenuItem>
                    <MenuItem onClick={() => handleOpenPrintModal(row)}>
                      {" "}
                      <LocalPrintshopIcon />{" "}
                    </MenuItem>
                  </Menu>

                  <Button
                    // disabled={row?.status !== "Draft"}
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenRecapPurchaseReceiptModal(row)}
                    style={{ marginRight: 5, marginLeft: 5 }}
                  >
                    <ChromeReaderModeIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Modal
        open={openDetailsModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {puchaseInvoiceDoc ? (
          <PurchaseRecDetails
            puchaseInvoiceDoc={puchaseInvoiceDoc}
            handleCreatePurInvoice={handleCreatePurInvoice}
            handleCloseModal={handleCloseModal}
          />
        ) : (
          <div className={classes.paper}>
            <Loader />
          </div>
        )}
      </Modal>


      <Modal
        open={openRecapPurchaseReceipt}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ClickAwayListener
          onClickAway={() =>
            setOpenRecapPurchaseReceipt ? setOpenRecapPurchaseReceipt(false) : null
          }
        >
          <div
            className={classes.paper}
            style={{
              overflowY: "auto",
              maxHeight: "90vh",
              width: "auto",
              height: "100%",
              aspectRatio: "1.414",
              
            }}
          >
            <RecapPurchaseReceipt
              setCloseModalPrint={handleCloseRecapPurchaseReceiptModal}
              id={selectedRow} 
            />
          </div>
        </ClickAwayListener>
      </Modal>
    </Box>
  );
};
