import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { symbol, removeHTML, formatDate } from "../../helpers/utils";
import Barcode from "react-barcode";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "0px 50px",
    fontSize: 12,
  },
  header: {
    padding: "60px 0",
    marginBottom: 60,
  },
  logo: {
    textAlign: "center",
    marginBottom: 10,
  },
  title: {
    borderTop: "1px solid  #5D6975",
    borderBottom: "1px solid  #5D6975",
    color: "#5D6975",
    fontSize: "30px",
    lineHeight: "1.4em",
    fontWeight: "normal",
    textAlign: "center",
    margin: "0 0 20px 0",
  },
  company: {
    float: "right",
    textAlign: "right",
    marginTop: 30,
  },
  project: {
    float: "left",
    marginTop: 30,
    marginBottom: 60,
  },
  projectitem: {
    color: "#5D6975",
    textAlign: "left",
    width: "52px",
    marginRight: 10,
    display: "inline-block",
    fontSize: "0.8em",
  },
  table: {
    width: "100%",
    marginTop: 20,
  },
  tableheader: {
    padding: "5px 5px",
    background: "#E5E5E5",
    border: "1px solid #C1CED9",
    fontWeight: "normal",
  },
  tableColumn: {
    padding: 10,
    textAlign: "right",
    border: "1px solid #C1CED9",
  },
  ref: {
    width: "60%",
    marginTop: 5,
  },
  headtable: {
    border: " 1px solid #C1CED9 ",
    background: "#E5E5E5",
    fontWeight: "normal",
  },
  columntable: {
    padding: 10,
    textAlign: "center",
    border: "1px solid #C1CED9",
  },
  pay: {
    width: "60%",
    display: "inline-table",
    tableLayout: "fixed",
  },
  comp: {
    width: "80%",
  },
  address: {
    paddingTop: "40px",
  },
  item: {
    color: "#5D6975",
    textAlign: "left",
    width: "52px",
    fontSize: "0.8em",
  },
  taxStamp: {
    marginTop: 20,
    textAlign: "left",
    fontSize: "0.8em",
  },
}));

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { t } = useTranslation([
    "common",
    "checkout",
    "payement",
    "hold",
    "clients",
  ]);
  const exchangeRate = useSelector((state) => state.Checkout.exchangeRate);
  const selectCurrency = useSelector((state) => state.Checkout.selectCurrency);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const taxStamp = useSelector((state) => state.Checkout.tax);
  const coupon = useSelector((state) => state.Checkout.selectedCoupon);
  const detailClient = useSelector((state) => state.Client.client);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const currencies = useSelector((state) => state.Client.currencies);
  const customRef = useSelector((state) => state.Checkout.customRef);
  const products = useSelector((state) => state.Checkout.products);
  const amount = useSelector((state) => state.Checkout.amount);
  const discount = useSelector((state) => state.Checkout.discount);
  const TPEticketnumber = useSelector(
    (state) => state.Checkout.TPEticketnumber
  );
  const payments = useSelector((state) => state.Checkout.amount.payments);
  const client = useSelector((state) => state.Checkout.client);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const taxsales = useSelector((state) => state.Checkout.taxsales);
  const companyinformations = useSelector(
    (state) => state.Configuration.companyinformations
  );

  const holdselected = useSelector((state) => state.Checkout.holdselected);
  const taxsalesvalue =
    taxsales && !isNaN(taxsales?.value) ? parseFloat(taxsales?.value) : 0;

  const totalAmount = amount.totalAmount
    ? (
        amount.totalAmount -
        (discount * amount.totalAmount) / 100 +
        taxsalesvalue
      ).toFixed(3)
    : "0.000";
  const totalAmountPayed = payments
    ? payments.reduce((a, v) => (a = a + parseFloat(v.amount)), 0)
    : 0;

  const currencySymbol = props.document
    ? symbol(props.document.currency, currencies)?.symbol
    : globalDefaults?.default_currency;

  const d = Date(customRef?.slice(-13));
  let postingDate = d?.toString();

  const hasPricingRules = () => {
    return props?.order?.items.some((obj) => obj.pricing_rules);
  };

  return (
    <div className={classes.main} ref={ref}>
      <header className={classes.header}>
        <div className={classes.logo}>
          <img src={activeImageTheme} />
        </div>
        <h1 className={classes.title}>
          {props.order ? props.title + " " + props.order.name : props.title}{" "}
        </h1>
        <div className={classes.company}>
          <div>
            {props.document
              ? props.document.company
              : globalDefaults?.default_company}
            : {props.contactCompany && props.contactCompany[0].warehouse_name}{" "}
            <br /> {props.contactCompany && props.contactCompany[0].city}{" "}
          </div>
          <div>
            {props.contactCompany && props.contactCompany[0].address_line_1}
          </div>
          <div>
            {props.contactCompany && props.contactCompany[0].mobile_no} |{" "}
            {props.contactCompany && props.contactCompany[0].phone_no}
          </div>
          <div>
            <a href="mailto:company@example.com">
              {props.contactCompany && props.contactCompany[0].email}{" "}
            </a>
          </div>
        </div>
        <div className={classes.project}>
          <div>
            <span className={classes.projectitem}>{t("checkou:customer")}</span>{" "}
            {props.document
              ? props.document.customer_name
              : client && client.customer_name}
          </div>
          <div>
            <span className={classes.projectitem}>{t("common:email")}</span>{" "}
            <a href="mailto:john@example.com">
              {props.document
                ? props.document.contact_email
                : client && client.email_id}
            </a>
          </div>
          <div>
            <span className={classes.projectitem}>DATE</span>
            {props.document ? props.document.posting_date : postingDate}
          </div>
        </div>
      </header>
      {props.document && props.document.address_display && (
        <div className={classes.address}>
          <span className={classes.item}>{t("common:customerAddress")}: </span>{" "}
          {props.document.address_display.replace(/<br>/g, "\n")}{" "}
        </div>
      )}
      {props.isFactureClient && (
        <table className={classes.ref}>
          <thead>
            <tr>
              {companyinformations?.tax_id && (
                <th className={classes.headtable}>matricule fiscal</th>
              )}
              {props.document && (
                <th className={classes.headtable}>{t("common:refIntn")}</th>
              )}
              <th className={classes.headtable}>{t("common:refextrn")}</th>
              {TPEticketnumber && (
                <th className={classes.headtable}>
                  {t("payement:TPEticketnumber")}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              {companyinformations?.tax_id && (
                <td className={classes.columntable}>
                  {companyinformations?.tax_id}
                </td>
              )}
              {props.document && (
                <td className={classes.columntable}>
                  {props.document.name && props.document.name.substr(15, 5)}
                </td>
              )}
              <td className={classes.columntable}>
                {props.document ? props.document.custom_ref : customRef}
              </td>
              {TPEticketnumber && (
                <td className={classes.columntable}>{TPEticketnumber}</td>
              )}
            </tr>
          </tbody>
        </table>
      )}

      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.tableheader}>{t("common:ref")}</th>
            <th className={classes.tableheader}>{t("common:produit")}</th>
            <th className={classes.tableheader}>{t("common:Description")}</th>
            <th className={classes.tableheader}>P.U</th>
            <th className={classes.tableheader}>{t("common:remise")} (%)</th>
            <th className={classes.tableheader}>{t("common:qty")}</th>
            <th className={classes.tableheader}>{t("common:total")}</th>
          </tr>
        </thead>
        <tbody>
          {props.order
            ? props.order?.items?.map((p, index) => (
                <tr key={index}>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "left" }}
                  >
                    {p.item_code}
                  </td>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "left" }}
                  >
                    {p.item_name}
                  </td>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "left" }}
                  >
                    {removeHTML(p.description)}
                  </td>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "left" }}
                  >
                    {(p.price_list_rate / Object.values(exchangeRate)).toFixed(
                      3
                    )}
                    {currencySymbol}
                  </td>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "left" }}
                  >
                    {p.discount_percentage}% (
                    {(p.discount_amount / Object.values(exchangeRate)).toFixed(
                      3
                    )}
                    ) {currencySymbol}
                  </td>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "left" }}
                  >
                    {p.qty}
                  </td>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "left" }}
                  >
                    {(p.amount / Object.values(exchangeRate)).toFixed(3)}
                    {currencySymbol}
                  </td>
                </tr>
              ))
            : products?.map((p, index) => (
                <tr key={index}>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "left" }}
                  >
                    {p.name}
                  </td>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "left" }}
                  >
                    {p.item_name}
                  </td>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "left" }}
                  >
                    {removeHTML(p.description)}
                  </td>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "left" }}
                  >
                    {(p.price_list_rate / Object.values(exchangeRate)).toFixed(
                      3
                    )}{" "}
                    {currencySymbol}
                  </td>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "left" }}
                  >
                    {+p.discount}% (
                    {(p.discount_amount / Object.values(exchangeRate)).toFixed(
                      3
                    )}
                    ) {currencySymbol}
                  </td>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "left" }}
                  >
                    {p.qty}
                  </td>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "left" }}
                  >
                    {(p.net_amount / Object.values(exchangeRate)).toFixed(3)}{" "}
                    {currencySymbol}
                  </td>
                </tr>
              ))}

          <tr>
            <td className={classes.tableColumn} colSpan="6">
              {t("common:total")} H.T
            </td>
            <td className={classes.tableColumn} style={{ textAlign: "left" }}>
              {props?.document
                ? props?.document?.total &&
                  (
                    props?.document?.total / Object.values(exchangeRate)
                  ).toFixed(3)
                : discount != 0
                ? (
                    ((1 - discount / 100) *
                      (amount?.totalAmount - amount.taxAmount)) /
                    Object.values(exchangeRate)
                  ).toFixed(3)
                : (
                    (amount.totalAmount - amount.taxAmount) /
                    Object.values(exchangeRate)
                  ).toFixed(3)}
              {currencySymbol}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="6">
              {t("common:totalTaxe")}
            </td>
            <td className={classes.tableColumn} style={{ textAlign: "left" }}>
              {props?.document
                ? (
                    props?.document?.total_taxes_and_charges /
                    Object.values(exchangeRate)
                  ).toFixed(3)
                : discount != 0
                ? (
                    (amount.payments.reduce(
                      (a, v) => (a = a + parseFloat(v.amount)),
                      0
                    ) -
                      (1 - discount / 100) *
                        (amount.totalAmount - amount.taxAmount)) /
                    Object.values(exchangeRate)
                  ).toFixed(3)
                : (
                    (amount.payments.reduce(
                      (a, v) => (a = a + parseFloat(v.amount)),
                      0
                    ) -
                      (amount.totalAmount - amount.taxAmount)) /
                    Object.values(exchangeRate)
                  ).toFixed(3)}
              {currencySymbol}
            </td>
          </tr>
          {props?.document?.discount_amount !== 0 && (
            <tr>
              <td className={classes.tableColumn} colSpan="6">
                {t("hold:remiseTtl")}
              </td>
              <td className={classes.tableColumn} style={{ textAlign: "left" }}>
                {props?.document
                  ? props?.document?.additional_discount_percentage
                  : discount}
                % (
                {props?.document &&
                  (
                    props?.document?.discount_amount /
                    Object.values(exchangeRate)
                  ).toFixed(3)}{" "}
                {currencySymbol})
              </td>
            </tr>
          )}
          <tr>
            <td className={classes.tableColumn} colSpan="6">
              {t("common:total")} TTC
            </td>
            <td className={classes.tableColumn} style={{ textAlign: "left" }}>
              {props?.document
                ? (
                    props?.document?.grand_total / Object.values(exchangeRate)
                  ).toFixed(3)
                : (
                    amount?.payments?.reduce(
                      (a, v) => (a = a + parseFloat(v.amount)),
                      0
                    ) / Object.values(exchangeRate)
                  ).toFixed(3)}
              {currencySymbol}
            </td>
          </tr>
          {props?.title == "Facture" && (
            <tr>
              <td className={classes.tableColumn} colSpan="6">
                {t("common:timbreFiscal")}
              </td>
              <td className={classes.tableColumn} style={{ textAlign: "left" }}>
                {taxStamp?.taxes?.[0]?.tax_amount?.toFixed(3) ?? 0}{" "}
                {currencySymbol}
              </td>
            </tr>
          )}
          <tr>
            <td className={classes.tableColumn} colSpan="6">
              {t("payement:totalToPay")}
            </td>
            <td className={classes.tableColumn} style={{ textAlign: "left" }}>
              {props?.document
                ? (
                    props?.document?.grand_total / Object.values(exchangeRate) +
                    (taxStamp?.taxes?.[0]?.tax_amount ?? 0)
                  ).toFixed(3)
                : (
                    (amount?.payments?.reduce(
                      (a, v) => (a = a + parseFloat(v.amount)),
                      0
                    ) +
                      (taxStamp?.taxes?.[0]?.tax_amount ?? 0)) /
                    Object.values(exchangeRate)
                  ).toFixed(3)}
              {currencySymbol}
            </td>
          </tr>
        </tbody>
      </table>

      {hasPricingRules() ? (
        <table className={classes.ref}>
          <thead>
            <tr>
              <th className={classes.headtable}>
                {t("checkout:pricingRule2")}
              </th>
              <th className={classes.headtable}>{t("common:itemCode")}</th>
              <th className={classes.headtable}>{t("checkout:ruleApplied")}</th>
              <th className={classes.headtable}>{t("checkout:couponCode")}</th>
            </tr>
          </thead>
          <tbody>
            {props.order &&
              props.order?.pricing_rules?.map((p, index) => (
                <tr key={index}>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "center" }}
                  >
                    {p.pricing_rule}
                  </td>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "center" }}
                  >
                    {p.item_code}
                  </td>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "center" }}
                  >
                    {p.rule_applied == 1 ? <DoneIcon /> : <ClearIcon />}
                  </td>
                  <td
                    className={classes.tableColumn}
                    style={{ textAlign: "center" }}
                  >
                    {coupon?.pricing_rule === p?.pricing_rule
                      ? coupon?.name
                      : "-"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : null}

      {detailClient?.loyalty_program &&
      detailClient?.__onload?.dashboard_info[0]?.loyalty_points ? (
        <table style={{ paddingTop: 30 }}>
          <thead>
            <tr>
              <th className={classes.tableheader} style={{ textAlign: "left" }}>
                {t("checkout:lprg")}
              </th>
              <th className={classes.tableheader} style={{ textAlign: "left" }}>
                {t("checkout:lp")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr key={"lyty-details"}>
              <td className={classes.tableColumn} style={{ textAlign: "left" }}>
                {detailClient?.loyalty_program}
              </td>
              <td className={classes.tableColumn} style={{ textAlign: "left" }}>
                {detailClient?.__onload?.dashboard_info[0]?.loyalty_points ?? 0}
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}

      {holdselected && holdselected?.amount?.payments?.length > 0 ? (
        <table style={{ paddingTop: 30 }}>
          <thead>
            <tr>
              <th className={classes.tableheader} style={{ textAlign: "left" }}>
                {t("common:createDate")}
              </th>
              <th className={classes.tableheader} style={{ textAlign: "left" }}>
                {t("common:advance")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr key={"advance"}>
              <td className={classes.tableColumn} style={{ textAlign: "left" }}>
                {formatDate(holdselected?.created_at, "YYYY-MM-DD")}
              </td>
              <td className={classes.tableColumn} style={{ textAlign: "left" }}>
                {holdselected?.amount?.payments
                  .map((item) => item.amount)
                  .reduce((prev, curr) => prev + curr, 0)
                  .toFixed(3)}{" "}
                {currencySymbol}
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}

      <div style={{ paddingTop: 30, display: "flex" }}>
        <div className={classes.pay}>
          {props.isFactureClient && (
            <table>
              <thead>
                <tr>
                  <th
                    className={classes.tableheader}
                    style={{ textAlign: "left" }}
                  >
                    {t("checkout:reglement")}
                  </th>
                  <th
                    className={classes.tableheader}
                    style={{ textAlign: "left" }}
                  >
                    {t("clients:paidAmount")}
                  </th>
                  <th
                    className={classes.tableheader}
                    style={{ textAlign: "left" }}
                  >
                    {t("common:montantRendre")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.order
                  ? props.order?.payments?.map((p, index) => (
                      <tr key={index}>
                        <td
                          className={classes.tableColumn}
                          style={{ textAlign: "left" }}
                        >
                          {p.mode_of_payment}
                        </td>
                        <td
                          className={classes.tableColumn}
                          style={{ textAlign: "left" }}
                        >
                          {p.amount
                            ? p.amount.toFixed(3)
                            : props?.title == "Facture"
                            ? totalAmountPayed
                              ? (
                                  totalAmountPayed +
                                  (taxStamp?.taxes?.[0]?.tax_amount ?? 0)
                                )?.toFixed(3)
                              : (
                                  p.amount +
                                  +(taxStamp?.taxes?.[0]?.tax_amount ?? 0)
                                )?.toFixed(3)
                            : totalAmountPayed
                            ? totalAmountPayed.toFixed(3)
                            : p.amount.toFixed(3)}
                          {currencySymbol}
                        </td>
                        <td
                          className={classes.tableColumn}
                          style={{ textAlign: "left" }}
                        >
                          {amount.totalAmount &&
                          parseFloat(totalAmountPayed - totalAmount) <= 0
                            ? 0.0
                            : (
                                parseFloat(totalAmountPayed - totalAmount) /
                                Object.values(exchangeRate)
                              ).toFixed(3)}{" "}
                          {currencySymbol}
                        </td>
                      </tr>
                    ))
                  : amount.payments.map((p, index) => (
                      <tr key={index}>
                        <td
                          className={classes.tableColumn}
                          style={{ textAlign: "left" }}
                        >
                          {p.mode_of_payment}
                        </td>
                        <td
                          className={classes.tableColumn}
                          style={{ textAlign: "left" }}
                        >
                          {(p.amount / Object.values(exchangeRate)).toFixed(3)}{" "}
                          {currencySymbol}
                        </td>
                        <td
                          className={classes.tableColumn}
                          style={{ textAlign: "left" }}
                        >
                          {(
                            (p.amount -
                              amount.payments.reduce(
                                (a, v) => (a = a + parseFloat(v.amount)),
                                0
                              )) /
                            Object.values(exchangeRate)
                          ).toFixed(3)}{" "}
                          {currencySymbol}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Barcode
              value={props.document ? props.document.custom_ref : customRef}
              background="transparent"
              fontSize={13}
              width={1}
            />{" "}
          </div>
        </div>
        <div className={classes.comp}>
          <table>
            {props.order && props.order?.taxes?.length !== 0 && (
              <thead>
                <tr>
                  <th
                    className={classes.tableheader}
                    style={{ textAlign: "left" }}
                  >
                    {t("common:taxe")}
                  </th>
                  <th
                    className={classes.tableheader}
                    style={{ textAlign: "left" }}
                  >
                    {t("common:taxAmount")}
                  </th>
                  <th
                    className={classes.tableheader}
                    style={{ textAlign: "left" }}
                  >
                    {t("common:taxAmountAfterDiscount")}
                  </th>
                </tr>
              </thead>
            )}
            <tbody>
              {props.order &&
                props.order?.taxes?.map((p, index) => (
                  <tr key={index}>
                    <td
                      className={classes.tableColumn}
                      style={{ textAlign: "left" }}
                    >
                      {p.account_head}
                    </td>
                    <td
                      className={classes.tableColumn}
                      style={{ textAlign: "left" }}
                    >
                      {(
                        p.base_tax_amount / Object.values(exchangeRate)
                      ).toFixed(3)}{" "}
                      {currencySymbol}
                    </td>
                    <td
                      className={classes.tableColumn}
                      style={{ textAlign: "left" }}
                    >
                      {(
                        p.tax_amount_after_discount_amount /
                        Object.values(exchangeRate)
                      ).toFixed(3)}{" "}
                      {currencySymbol}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
