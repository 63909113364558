import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import {
  OutlinedInput,
  InputAdornment,
  FormControl,
  Modal,
} from "@material-ui/core";
import { HoldTable } from "../table";
import * as localforage from "localforage";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Pagination } from "@material-ui/lab";
import { useHistory } from "react-router";
import {
  deleteHoldById,
  loadHolds,
  reloadHold,
} from "../../../../store/hold/hold.action";
import { GlobalfilterData } from "../../../../helpers/helper";
import { Empty } from "../../../../components/empty";
import { Loader } from "../../../../components/loader";
import { formatDate } from "../../../../helpers/utils";
import {
  CreateAvoirEntry,
  SetAvoirToPrint,
} from "../../../../store/checkout/checkout.action";
import { PrintAvoirPage } from "./printAvoir";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: "0.75rem 0px 0.8125rem",
    flexWrap: "wrap",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  formControl: {
    maxWidth: 300,
    marginRight: 15,
    "& .MuiOutlinedInput-input": {
      padding: "8px 14px !important",
      fontSize: 12,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    fontSize: "0.875rem",
    borderRadius: 10,
  },
  icon: {
    marginRight: "0.375rem",
  },
  label: {
    fontSize: 12,
    color: theme.palette.gray.main,
    paddingLeft: 9,
  },
  date: {
    marginBottom: 15,
    marginRight: 15,
  },
}));
export const ReservationPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const { t } = useTranslation(["common", "hold"]);
  const [filtredHoldOrders, setFiltredHoldOrders] = useState([]);
  const [searchOrder, setSearchOrder] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [printOpen, setPrintOpen] = useState(false);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const holdOrders = useSelector((state) => state.Checkout.holds);
  const avoir2Print = useSelector((state) => state.Checkout.avoir2Print);

  const temporaryholdOrders = useSelector(
    (state) => state.Checkout.temporaryHolds
  );

  const handleSearchOrder = (event) => {
    setSearchOrder(event.target.value);
  };

  const timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };
  const handleCancelReservation = async (data, depositAmnt) => {
    if (
      (data?.amount?.depositAmount != 0 || data?.amount?.payments.length) &&
      depositAmnt > 0
    ) {
      setPrintOpen(true);
      const doc = {
        doctype: "Avoir Account",
        company: `${globalDefaults?.default_company}`,
        customer: `${data?.client}`,
        credit_amount: depositAmnt,
        naming_series: "CUS-AVR-.YYYY.-",
        creation_date: formatDate(new Date(), "YYYY-MM-DD"),
        //return_against: "",
        return_reason: "Reservation cancellation",
      };
      dispatch(
        CreateAvoirEntry(doc, {
          id: data.id,
          company: globalDefaults?.default_company,
          cashier: selectedData?.cashier,
        })
      );
    }
  };
  const handleDelete = async (data) => {
    if (data?.id) {
      await dispatch(deleteHoldById(data?.id));
      await dispatch(
        loadHolds(globalDefaults?.default_company, selectedData?.store)
      );
      history.go(0);
    } else if (avoir2Print) {
      setPrintOpen(false);
      history.go(0);
    } else {
      await dispatch(
        reloadHold(
          temporaryholdOrders?.filter((el) => el.index !== data?.index)
        )
      );
      await timeout(1500);
      history.go(0);
    }
  };

  const filtredate = async (data0) => {
    try {
      if (
        selectedDate &&
        selectedDate != null &&
        selectedDate !== undefined &&
        selectedDate !== "Invalid Date"
      ) {
        const date = moment(selectedDate).format("YYYY-MM-DD");
        return data0.filter((item) => {
          const HoldDate = moment(item.created_at).format("YYYY-MM-DD");
          return HoldDate === date;
        });
      } else {
        return data0;
      }
    } catch (error) {
      return data0;
    }
  };
  const filtreAlldata = async () => {
    setPage(1);
    const datafiltre1 = await GlobalfilterData(holdOrders, searchOrder, [
      "client",
      "created_at",
      "coupon",
      "amount",
    ]);
    const datafiltre2 = await filtredate(datafiltre1);
    const datatemporaryholdOrdersfiltre1 = await GlobalfilterData(
      temporaryholdOrders,
      searchOrder,
      ["client", "created_at", "coupon", "amount"]
    );
    const datatemporaryholdOrdersfiltre2 = await filtredate(
      datatemporaryholdOrdersfiltre1
    );
    setFiltredHoldOrders([
      ...datatemporaryholdOrdersfiltre2?.slice(0)?.reverse(),
      ...datafiltre2?.slice(0)?.reverse(),
    ]);
  };
  useEffect(() => {
    filtreAlldata();
  }, [searchOrder, selectedDate]);
  useEffect(() => {
    setFiltredHoldOrders([
      ...temporaryholdOrders?.slice(0)?.reverse(),
      ...holdOrders?.slice(0)?.reverse(),
    ]);
  }, [holdOrders, temporaryholdOrders]);
  const initialisation = async () => {
    await dispatch(
      loadHolds(globalDefaults?.default_company, selectedData?.store)
    );
    await localforage.getItem("reduxPersist:Checkout", function (err, value) {
      setFiltredHoldOrders(JSON.parse(value).holds?.slice(0)?.reverse());
    });
    setLoading(false);
  };
  useEffect(() => {
    initialisation();
    return () => {
      dispatch(SetAvoirToPrint(null));
    };
  }, []);

  const filteredData =
    filtredHoldOrders &&
    filtredHoldOrders.filter((item) => {
      return item.amount.payments.length > 0;
    });

  return (
    <Box>
      <Box className={classes.header}>
        <FormControl className={classes.formControl} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            value={searchOrder}
            placeholder={t("hold:clientSearch")}
            onChange={handleSearchOrder}
            endAdornment={
              <InputAdornment position="end">
                <span className="icon-search" />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            labelWidth={0}
          />
        </FormControl>
      </Box>
      <Box mt={2}>
        {loading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <HoldTable
              orders={filteredData.slice((page - 1) * 20, page * 20)}
              handleDelete={handleDelete}
              handleCancelReservation={handleCancelReservation}
              isReservation
            />
            {filteredData.length === 0 && (
              <Empty text={t("common:emptyReserved")} />
            )}
            {Math.ceil(filteredData.length / 20) > 0 && (
              <Box display="flex" justifyContent="center" m={4}>
                <Pagination
                  onChange={(event, newPage) => setPage(newPage)}
                  page={page}
                  count={Math.ceil(filteredData.length / 20)}
                  color="primary"
                />
              </Box>
            )}
          </React.Fragment>
        )}
      </Box>
      <Modal open={printOpen}>
        <PrintAvoirPage closePrint={handleDelete} timeout={timeout} />
      </Modal>
    </Box>
  );
};
