import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Modal,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Button,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Box,
  OutlinedInput,
  InputAdornment,
  FormControl,
  ClickAwayListener,
  Grid,
  Tooltip,
  FormHelperText, FormControlLabel,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import ExpandMore from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import validator from "validator";
import {
  CreateClient,
  loadClientGiftCard,
  loadLoyaltyPointsByClient,
} from "../../../../store/client/client.action";
import { GetFiltredClients } from "../../../../store/client/client.action";
import {
  UpdateClient,
  loadCurrencyRate,
  LoadDetailClient,
} from "../../../../store/checkout/checkout.action";
import {
  loadOutstandingByClient,
  loadReturnseByClient,
  loadPOSInvoicesByClient,
} from "../../../../store/client/client.action";
import { SetCheckedItems } from "../../../../store/inventory/inventory.action";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";
import { isUnique } from "../../../../helpers/utils";
import axiosInstance from "../../../../history/axiosInstance";
import { UpdateAppliedLoyCard } from "../../../../store/cart/cart.action";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  modalButton: {
    marginLeft: 5,
    marginRight: 5,
  },
  customerNameInput: {
    width: "100%",
    marginBottom: 15,
    height: 35,
    fontSize: 12,
  },
  formControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  paper: {
    width: "500px",
    textAlign: "center",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  label: {
    fontSize: 12,
    marginBottom: 6,
    color: theme.palette.gray.main,
    textAlign: "left",
    paddingLeft: 5,
  },
  formControll: {
    width: 240,
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  nested: {
    height: 40,
    "& span": {
      fontSize: 14,
      color: theme.palette.primary.main,
    },
  },
  button: {
    width: "100%",
    backgroundColor: "transparent",
    color: "#657288",
    border: "1px solid #A6B4CB",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  box: {
    position: "relative",
  },
  active: {
    borderBottom: "none",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  scanCodeIcon: {
    color: theme.palette.primary.main,
  },
  collapse: {
    position: "absolute",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    border: "1px solid #A6B4CB",
    borderTop: "none",
    backgroundColor: theme.palette.white.main,
    width: "calc(100% - 2px)",
    zIndex: 2,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
      marginRight: 13,
    },
    "& .MuiOutlinedInput-input": {
      padding: "7.5px 14px",
      fontSize: 12,
    },
  },
  collapse4Inv: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    border: "1px solid #A6B4CB",
    borderTop: "none",
    backgroundColor: theme.palette.white.main,
    width: "calc(100% - 2px)",
    zIndex: 2,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
      marginRight: 13,
    },
    "& .MuiOutlinedInput-input": {
      padding: "7.5px 14px",
      fontSize: 12,
    },
  },
  avatar: {
    height: 37,
    marginRight: 11,
    color: theme.palette.blue.main,
    fontSize: 28,
    fontWeight: 600,
    backgroundColor: theme.palette.secondary.main,
  },
  collapseContent: {
    padding: "8px 11px",
    backgroundColor: theme.palette.secondary.main,
  },
  linkSection: {
    borderTop: "1px solid #f2f4f7",
  },
  link: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    justifyContent: "center",
    color: theme.palette.blue.main,
  },
  userIcon: {
    marginLeft: 4,
  },
  clientList: {
    height: 200,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "1.0em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.secondary.light,
      outline: `1px solid ${theme.palette.blue.main}`,
    },
  },
  noWrap: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "&:hover": {
      overflow: "visible",
    },
  },
  disableText: {
    opacity: 0.5,
    height: 40,
    "& span": {
      fontSize: 14,
      color: theme.palette.primary.main,
    },
  },
  addButton: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary.main,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 11,
  },
}));

export const SearchClient = ({
  isClient,
  isProducts,
  isCategory,
  isWarehouse,
  isPos,
  isRec,
  isRecWarehouse,
  isSalesPerson,
  products,
  searchProduct,
  handleSearchProduct,
  filtredProducts,
  handleProductChange,
  selectedFilters,
  categories,
  searchItemGroup,
  handleSearchItemGroup,
  filtredItemGroup,
  handleItemGroupChange,
  warehouses,
  searchWarehouse,
  handleSearchWarehouse,
  filtredWarehouse,
  handleWarehouseChange,
  pos,
  searchPos,
  handleSearchPos,
  filtredPos,
  handlePosChange,
  selectedCashier,
  filtredPosByWarehouse,
  state,
  handleRecProductChange,
  handleRecWarehouseChange,
  salesPersons,
  searchSalesPerson,
  handleSearchSalesPerson,
  filtredSalesPerson,
  handleSalesPersonChange,
  isInventory,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [searchClient, setSearchClient] = React.useState("");
  const [alertMsg, setAlertMsg] = React.useState("");

  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const selectedClient = useSelector((state) => state.Checkout.client);
  const territory = useSelector((state) => state.Setting.territory);
  const customersgroup = useSelector((state) => state.Client.customersgroup);
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "inventory", "clients", "checkout"]);
  const filtredClients = useSelector((state) => state.Client.filtredClients);
  const [openMiniForm, setOpenMiniForm] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const selectedWarehouse = useSelector((state) => state.Login.selectedData);
  const checkedItems = useSelector((state) => state.Inventory.checkedItems);
  const stockQtyReport = useSelector((state) => state.Inventory.stockQtyReport);
  const invLists = useSelector((state) => state.Inventory.invLists);

  const appliedLoyaltyCard = useSelector(
    (state) => state.Cart.appliedLoyaltyCard
  );

  const [contactPhone, setContactPhone] = useState([]);

  const SearchClients = (event) => {
    setSearchClient(event.target.value);
    dispatch(GetFiltredClients(event.target.value));
  };

  const HandleSelectedClient = (client) => {
    dispatch(UpdateClient(client));
    if (client.default_currency) {
      dispatch(loadCurrencyRate(client.default_currency));
    }
    // dispatch(loadReturnseByClient(client,globalDefaults?.default_company)); /****I just added the company setting*********** */
    // dispatch(loadOutstandingByClient(client,globalDefaults?.default_company)); /****I just added the company setting*********** */
    dispatch(loadPOSInvoicesByClient(client, globalDefaults?.default_company));
    // dispatch(loadClientGiftCard(client));
    dispatch(LoadDetailClient(client.customer_name));

    // remove the applied loyalty
    if (appliedLoyaltyCard) {
      dispatch(UpdateAppliedLoyCard(null));
    }

    setOpen(false);
  };
  const handleClick = () => {
    setOpen(!open);
  };

  const handleSelectProduct = (row) => {
    handleProductChange(row);
    dispatch(SetCheckedItems([]));
    setOpen(false);
  };
  const handleSelectItemGroup = (row) => {
    handleItemGroupChange(row);
    setOpen(false);
  };
  const handleSelectWarehouse = (row) => {
    handleWarehouseChange(row);
    setOpen(false);
  };
  const handleSelectPos = (row) => {
    handlePosChange(row);
    setOpen(false);
  };
  const handleSelectRecProduct = (row) => {
    handleRecProductChange(row);
    setOpen(false);
  };
  const handleSelectRecWarehouse = (row) => {
    handleRecWarehouseChange(row);
    setOpen(false);
  };
  const handleSelectSalesPerson = (row) => {
    handleSalesPersonChange(row);
    setOpen(false);
  };

  const [createClientForm, setCreateClientForm] = useState({
    customer_name: "",
    customer_type: "",
    customer_group: "",
    territory: "",
    email_id: "",
    //mobile_no: "",
    non_resident: "",
    type_doc: "",
    expiration_date_doc: "",
    number_doc: "",
    issued_by: "",
    birth_date: "",
    mobile_phone: ""
  });

  const [error, setError] = React.useState({
    customer_name: false,
    customer_type: false,
    customer_group: false,
    territory: false,
    email_id: false,
    type_doc: false,
    expiration_date_doc: false,
    number_doc: false,
    issued_by: false,
    birth_date: false

  });

  const [errorMsg, setErrorMsg] = React.useState({
    email_id: null
    //mobile_no: null,
  });

  const handleChange = (event) => {
    setError({ ...error, [event.target.name]: false });
    //setErrorMsg({ ...errorMsg, [event.target.name]: null });
    setCreateClientForm({
      ...createClientForm,
      [event.target.name]: event.target.value,
    });
  };

  const handleCreateClient = () => {
    setError({
      customer_name: createClientForm.customer_name === "",
      customer_type: createClientForm.customer_type === "",
      customer_group: createClientForm.customer_group === "",
      territory: createClientForm.territory === "",
      email_id:
        createClientForm.email_id === "" ||
        !validator.isEmail(createClientForm.email_id),
     /* mobile_no:
        createClientForm.mobile_no.length < 8 ||
        !validator.isMobilePhone(createClientForm.mobile_no) ||
        !isUnique(createClientForm.mobile_no, contactPhone),*/
      type_doc: createClientForm.type_doc === "",
      expiration_date_doc: createClientForm.expiration_date_doc === "",
      number_doc: createClientForm.number_doc === "",
      non_resident: createClientForm.non_resident === "",
      birth_date: createClientForm.birth_date === "",
      issued_by: createClientForm.issued_by === ""
    });

    setErrorMsg({
      email_id:
        createClientForm.email_id === "" ||
        !validator.isEmail(createClientForm.email_id)
          ? t("clients:emailError")
          : null,
     /* mobile_no:
        createClientForm.mobile_no.length < 8 ||
        !validator.isMobilePhone(createClientForm.mobile_no)
          ? t("clients:numError")
          : !isUnique(createClientForm.mobile_no, contactPhone)
          ? t("clients:uniqueNum")
          : null,*/
    });

    if (
      createClientForm.customer_name !== "" &&
      createClientForm.customer_type !== "" &&
      createClientForm.customer_group !== "" &&
      createClientForm.territory !== "" &&
      createClientForm.email_id !== "" &&
      validator.isEmail(createClientForm.email_id)
     /* createClientForm.mobile_no.length > 7 &&
      validator.isMobilePhone(createClientForm.mobile_no) &&
      isUnique(createClientForm.mobile_no, contactPhone)*/
    ) {
      const data = {
        doctype: "Customer",
        customer_name: createClientForm.customer_name,
        customer_type: createClientForm.customer_type,
        customer_group: createClientForm.customer_group,
        territory: createClientForm.territory,
        email_id: createClientForm.email_id,
        type_doc: createClientForm.type_doc,
        expiration_date_doc: createClientForm.expiration_date_doc,
        number_doc: createClientForm.number_doc,
        non_resident: createClientForm.non_resident,
        birth_date: createClientForm.birth_date,
        issued_by: createClientForm.issued_by,
        mobile_phone: createClientForm.mobile_phone
      };
      dispatch(CreateClient(data));
      closingModal();
    }
  };

  const closingModal = () => {
    setOpenMiniForm(false);
    setCreateClientForm({
      customer_name: "",
      customer_type: "",
      customer_group: "",
      territory: "",
      email_id: "",
      doc_type: "",
      issued_by: "",
      doc_number: "",
      expiration_date_doc: "",
      non_resident: "",
      birth_date: ""
    });
    setErrorMsg({
      email_id: null
      //mobile_no: null,
    });
  };

  const [showDetails, setShowDetails] = React.useState(null);
  const handleCheck = (event) => {
    var updatedList = [...checkedItems];
    if (event.target.checked) {
      updatedList = [...checkedItems, event.target.value];
    } else {
      updatedList.splice(checkedItems.indexOf(event.target.value), 1);
    }
    dispatch(SetCheckedItems(updatedList));
  };
  useEffect(() => {
    const valid = Object.values(createClientForm).every((value) => {
      if (value != "" && value != null) {
        return true;
      }
      return false;
    });
    setIsValid(valid);
  }, [createClientForm]);

  var isChecked = (item) => (checkedItems.includes(item) ? true : false);

  var isInProgress = (item) => {
    if (invLists && Array.isArray(invLists)) {
      let itemIndex = invLists.findIndex(
        (i) => i.status == "In Progress" && i.products.includes(item)
      );
      if (itemIndex != -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  var setNotification = (item) => {
    setAlertMsg("");
    if (isInProgress(item)) {
      setAlertMsg(
        `${t("inventory:refItem")} ${item} ${t("inventory:Inprogress")}`
      );
      return;
    }
    if (!isInStock(item)) {
      setAlertMsg(
        `${t("inventory:refItem")} ${item} ${t("inventory:OutStock")}`
      );
      return;
    }
    return;
  };

  const alertProduct = (
    <Alert icon={false} severity="error">
      {alertMsg}
    </Alert>
  );

  const handleChangeCheckBox = () => {
    setShowDetails(!showDetails);
    //createClientForm.non_resident = showDetails ? '1' : '0';
  };

  async function getPhoneNumbers() {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_URI}/api/get-list`,
      {
        doctype: "Contact",
        fields: ["`tabContact Phone`.`phone`"],
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      }
    );
    if (response.data) {
      setContactPhone(response.data);
    }
  }

  useEffect(() => {
    getPhoneNumbers();
  }, []);

  const miniForm = (
    <Box className={classes.paper}>
      <Typography align={"center"} color={"primary"}>
        {t("common:addClient")}
      </Typography>
      {/*<Box mt={3} justifyContent="left" className={classes.formBlock} >
        <Box display="flex" flexDirection="row" justifyContent="flex-start">
          <FormControlLabel
              className={classes.nested}
              color={"primary"}
              control={
                <Checkbox
                    checked={showDetails}
                    onChange={handleChangeCheckBox}
                    color="primary"
                    value={showDetails ? createClientForm.non_resident = 1 : createClientForm.non_resident = 0}
                    name="non_resident"
                />
              }
              label={t("Client Non Resident")}
          />
        </Box>
        <InputLabel
          className={classes.label}
          error={error.customer_name}
          required
        >
          {t("common:customerName")}
        </InputLabel>
        <OutlinedInput
          autoComplete="off"
          error={error.customer_name}
          className={classes.customerNameInput}
          placeholder={t("common:customerName")}
          value={createClientForm.customer_name}
          onChange={(e) => {
            handleChange(e);
          }}
          name="customer_name"
        />
        <InputLabel
          className={classes.label}
          required
          error={error.customer_type}
        >
          {t("common:customerType")}
        </InputLabel>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            error={error.customer_type}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            className={classes.select}
            value={createClientForm.customer_type}
            name="customer_type"
            IconComponent={ExpandMore}
            onChange={(e) => handleChange(e)}
            style={{ textAlign: "left" }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem style={{ fontSize: 12 }} key={0} value={"Company"}>
              {t("common:company")}
            </MenuItem>
            <MenuItem style={{ fontSize: 12 }} key={1} value={"Individual"}>
              {t("common:Indiv")}
            </MenuItem>
          </Select>
        </FormControl>
        <InputLabel
          className={classes.label}
          required
          error={error.customer_group}
        >
          {t("common:customerGroup")}
        </InputLabel>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            error={error.customer_group}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            className={classes.select}
            value={createClientForm.customer_group}
            name="customer_group"
            style={{ textAlign: "left" }}
            IconComponent={ExpandMore}
            onChange={(e) => handleChange(e)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {Array.isArray(customersgroup) &&
              customersgroup.map((row, index) => (
                <MenuItem
                  style={{ fontSize: 12 }}
                  value={row.value}
                  key={row.value}
                >
                  {row.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <InputLabel className={classes.label} required error={error.territory}>
          {t("clients:territory")}
        </InputLabel>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            error={error.territory}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            className={classes.select}
            value={createClientForm.territory}
            style={{ textAlign: "left" }}
            IconComponent={ExpandMore}
            onChange={(e) => handleChange(e)}
            name="territory"
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {Array.isArray(territory) &&
              territory.map((row, index) => (
                <MenuItem
                  style={{ fontSize: 12 }}
                  value={row.value}
                  key={row.value}
                >
                  {row.title}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputLabel
              className={classes.label}
              required
              error={error.email_id}
            >
              {t("common:email")}
            </InputLabel>
            <OutlinedInput
              error={error.email_id}
              autoComplete="off"
              className={classes.formControll}
              placeholder={t("common:email")}
              value={createClientForm.email_id}
              onChange={(e) => {
                handleChange(e);
              }}
              name="email_id"
            />
            <FormHelperText
              className={classes.error}
              style={{ marginBottom: 0 }}
            >
              {errorMsg?.email_id ?? errorMsg.email_id}
            </FormHelperText>
          </Grid>
          <Grid item xs={6}>
            <InputLabel
              className={classes.label}
              required
              //error={error.mobile_no}
            >
              {t("common:numTel")}
            </InputLabel>
            <OutlinedInput
              //error={error.mobile_no}
              autoComplete="off"
              className={classes.formControll}
              placeholder={t("common:numTel")}
              value={createClientForm.mobile_phone}
              onChange={(e) => {
                handleChange(e);
              }}
              name="mobile_phone"
            />
            <FormHelperText
              className={classes.error}
              style={{ marginBottom: 0 }}
            >
              {errorMsg?.mobile_no ?? errorMsg.mobile_no}
            </FormHelperText>
          </Grid>
        </Grid>
      </Box>*/}
     {/* {showDetails && <Box justifyContent="center" className={classes.formBlock}>
        <InputLabel
            className={classes.label}
            required
            error={error.type_doc}
        >
          {t("common:Doc")}
        </InputLabel>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
              error={error.type_doc}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              className={classes.select}
              value={createClientForm.type_doc}
              name="type_doc"
              IconComponent={ExpandMore}
              onChange={(e) => handleChange(e)}
              style={{textAlign: "left"}}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
          >
            <MenuItem style={{fontSize: 12}} key={0} value={"passport"}>
              {t("common:passport")}
            </MenuItem>
            <MenuItem style={{fontSize: 12}} key={1} value={"cin"}>
              {t("common:CIN")}
            </MenuItem>
          </Select>
        </FormControl>
        <InputLabel
            className={classes.label}
            error={error.number_doc}
            required
        >
          {t("common:Num_Doc")}
        </InputLabel>
        <OutlinedInput
            autoComplete="off"
            error={error.number_doc}
            className={classes.customerNameInput}
            placeholder={t("common:Num_Doc")}
            value={createClientForm.number_doc}
            onChange={(e) => {
              handleChange(e);
            }}
            name="number_doc"
        />
        <InputLabel
            className={classes.label}
            error={error.expiration_date_doc}
            required
        >
          {t("common:expiration")}
        </InputLabel>
        <OutlinedInput
            autoComplete="off"
            error={error.expiration_date_doc}
            className={classes.customerNameInput}
            placeholder={t("YYYY-MM-DD")}
            value={createClientForm.expiration_date_doc}
            onChange={(e) => {
              handleChange(e);
            }}
            name="expiration_date_doc"
        />
        <InputLabel
            className={classes.label}
            error={error.issued_by}
            required
        >
          {t("common:issued_by")}
        </InputLabel>
        <OutlinedInput
            autoComplete="off"
            error={error.issued_by}
            className={classes.customerNameInput}
            placeholder={t("Délivré")}
            value={createClientForm.issued_by}
            onChange={(e) => {
              handleChange(e);
            }}
            name="issued_by"
        />
        <InputLabel
            className={classes.label}
            error={error.birth_day}
            required
        >
          {t("common:birth_day")}
        </InputLabel>
        <OutlinedInput
            autoComplete="off"
            error={error.birth_date}
            className={classes.customerNameInput}
            placeholder={t("YYYY-MM-DD")}
            value={createClientForm.birth_date}
            onChange={(e) => {
              handleChange(e);
            }}
            name="birth_date"
        />
      </Box>}*/}
      <Box mt={3} justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          className={classes.modalButton}
          component={Link}
          to={"/client/create"}
        >
          {t("Client Resident")}
        </Button>
        <Button
            variant="contained"
            color="primary"
            className={classes.modalButton}
            component={Link}
            to={"/client/create_client_non_res"}
        >
          {t("Client Non Resident")}
        </Button>
        {/*<Button
          variant="contained"
          color="primary"
          className={classes.modalButton}
          onClick={() => handleCreateClient()}
        >
          {t("common:ajouter")}
        </Button>*/}
        <Button
          className={classes.modalButton}
          variant="contained"
          color="secondary"
          onClick={() => closingModal()}
        >
          {t("common:annuler")}
        </Button>
      </Box>
    </Box>
  );

  var isInStock = (item) =>
    stockQtyReport.findIndex((p) => p.item_code == item) == -1 ? false : true;

  useEffect(() => {
    isClient &&
      selectedClient &&
      dispatch(LoadDetailClient(selectedClient?.customer_name));
  }, [selectedClient]);

  return (
    <>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Box className={classes.box}>
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            className={clsx({
              [classes.button]: true, //always applies
              [classes.active]: open, //only when open === true
            })}
            onClick={handleClick}
          >
            {isClient && (
              <Box
                className={classes.noWrap}
                display={"flex"}
                alignItems={"center"}
              >
                <Avatar className={classes.avatar}>
                  {selectedClient
                    ? selectedClient.customer_name &&
                      selectedClient.customer_name[0]
                    : "C"}
                </Avatar>
                {selectedClient
                  ? selectedClient.customer_name
                  : t("common:client")}
              </Box>
            )}
            {isProducts && !isRec && (
              <Box
                className={classes.noWrap}
                fontSize={12}
                display={"flex"}
                alignItems={"center"}
              >
                {t("common:produits")}
              </Box>
            )}
            {isRec && (
              <Box
                className={classes.noWrap}
                fontSize={12}
                dissplay={"flex"}
                alignItems={"center"}
              >
                {state.item_code ? state.item_code : t("common:selectproduct")}
              </Box>
            )}
            {isWarehouse && !isRecWarehouse && (
              <Box
                className={classes.noWrap}
                fontSize={12}
                display={"flex"}
                alignItems={"center"}
              >
                {selectedFilters.selectedFWarehouse
                  ? selectedFilters.selectedFWarehouse
                  : selectedWarehouse.store}
              </Box>
            )}
            {isRecWarehouse && (
              <Box
                className={classes.noWrap}
                fontSize={12}
                display={"flex"}
                alignItems={"center"}
              >
                {state.warehouse ? state.warehouse : t("common:select")}
              </Box>
            )}
            {isCategory && (
              <Box
                className={classes.noWrap}
                fontSize={12}
                display={"flex"}
                alignItems={"center"}
              >
                {selectedFilters.selectedItemGroup
                  ? selectedFilters.selectedItemGroup
                  : t("common:allCategories")}
              </Box>
            )}
            {isPos && (
              <Box
                className={classes.noWrap}
                fontSize={12}
                display={"flex"}
                alignItems={"center"}
              >
                {selectedCashier ? selectedCashier : t("common:none")}
              </Box>
            )}
            {isSalesPerson && (
              <Box
                className={classes.noWrap}
                fontSize={12}
                display={"flex"}
                alignItems={"center"}
              >
                {selectedFilters.selectedSalesPerson
                  ? selectedFilters.selectedSalesPerson
                  : t("common:none")}
              </Box>
            )}
            <ExpandMore />
          </Button>
          <Collapse
            className={isInventory ? classes.collapse4Inv : classes.collapse}
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box
              className={classes.collapseContent}
              display={"flex"}
              alignItems={"center"}
            >
              <FormControl variant="outlined">
                {isClient && (
                  <OutlinedInput
                    autoComplete="off"
                    id="outlined-adornment-weight"
                    value={searchClient}
                    placeholder={t("common:RClient")}
                    onChange={SearchClients}
                    endAdornment={
                      <InputAdornment position="end">
                        <span className="icon-search" />
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    labelWidth={0}
                  />
                )}
                {isProducts && !isRec && (
                  <OutlinedInput
                    autoComplete="off"
                    id="outlined-adornment-weight"
                    value={searchProduct}
                    placeholder={t("common:rechercher")}
                    onChange={handleSearchProduct}
                    endAdornment={
                      <InputAdornment position="end">
                        <span className="icon-search" />
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    labelWidth={0}
                  />
                )}
                {isRec && (
                  <OutlinedInput
                    autoComplete="off"
                    id="outlined-adornment-weight"
                    value={searchProduct}
                    placeholder={t("common:rechercher")}
                    onChange={handleSearchProduct}
                    endAdornment={
                      <InputAdornment position="end">
                        <span className="icon-search" />
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    labelWidth={0}
                  />
                )}
                {isCategory && (
                  <OutlinedInput
                    autoComplete="off"
                    id="outlined-adornment-weight"
                    value={searchItemGroup}
                    placeholder={t("common:rechercher")}
                    onChange={handleSearchItemGroup}
                    endAdornment={
                      <InputAdornment position="end">
                        <span className="icon-search" />
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    labelWidth={0}
                  />
                )}
                {isWarehouse && !isRecWarehouse && (
                  <OutlinedInput
                    autoComplete="off"
                    id="outlined-adornment-weight"
                    value={searchWarehouse}
                    placeholder={t("common:rechercher")}
                    onChange={handleSearchWarehouse}
                    endAdornment={
                      <InputAdornment position="end">
                        <span className="icon-search" />
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    labelWidth={0}
                  />
                )}
                {isRecWarehouse && (
                  <OutlinedInput
                    autoComplete="off"
                    id="outlined-adornment-weight"
                    value={searchWarehouse}
                    placeholder={t("common:rechercher")}
                    onChange={handleSearchWarehouse}
                    endAdornment={
                      <InputAdornment position="end">
                        <span className="icon-search" />
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    labelWidth={0}
                  />
                )}
                {isPos && (
                  <OutlinedInput
                    autoComplete="off"
                    id="outlined-adornment-weight"
                    value={searchPos}
                    placeholder={t("common:rechercher")}
                    onChange={handleSearchPos}
                    endAdornment={
                      <InputAdornment position="end">
                        <span className="icon-search" />
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    labelWidth={0}
                  />
                )}
                {isSalesPerson && (
                  <OutlinedInput
                    autoComplete="off"
                    id="outlined-adornment-weight"
                    value={searchSalesPerson}
                    placeholder={t("common:rechercher")}
                    onChange={handleSearchSalesPerson}
                    endAdornment={
                      <InputAdornment position="end">
                        <span className="icon-search" />
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    labelWidth={0}
                  />
                )}
              </FormControl>
              <span className={classes.scanCodeIcon} />
            </Box>
            <List
              id="foo\bar"
              className={classes.clientList}
              component="div"
              disablePadding
            >
              {isClient &&
                Array.isArray(filtredClients) &&
                filtredClients?.map((client, index) => (
                  <ListItem
                    onClick={() => HandleSelectedClient(client)}
                    key={index}
                    button
                    className={classes.nested}
                  >
                    <ListItemText
                      color={"primary"}
                      primary={client.customer_name}
                    />
                  </ListItem>
                ))}
              {isProducts && !isRec && (
                <ListItem
                  onClick={() => handleSelectProduct("Tous les Produits")}
                  button
                  className={classes.nested}
                >
                  <ListItemText
                    color={"primary"}
                    primary={"Tous les Produits"}
                  />
                </ListItem>
              )}
              {isProducts &&
                !isRec &&
                (products &&
                searchProduct == "" &&
                selectedFilters.selectedItemGroup == ""
                  ? products.map((row, index) => (
                      <div onMouseEnter={() => setNotification(row.item_code)}>
                        <Tooltip title={alertMsg ? alertProduct : ""}>
                          <ListItem
                            key={index}
                            button
                            className={
                              !isInStock(row.item_code) ||
                              isInProgress(row.item_code)
                                ? classes.disableText
                                : classes.nested
                            }
                          >
                            <Checkbox
                              disabled={
                                !isInStock(row.item_code) ||
                                isInProgress(row.item_code)
                              }
                              value={row.item_code}
                              onChange={handleCheck}
                              checked={isChecked(row.item_code)}
                            />
                            <ListItemText
                              className={classes.noWrap}
                              color={"primary"}
                              primary={
                                row.item_name + " (" + row.item_code + ")"
                              }
                            />
                          </ListItem>
                        </Tooltip>
                      </div>
                    ))
                  : filtredProducts &&
                    filtredProducts.length != 0 &&
                    filtredProducts.map((row, index) => (
                      <div onMouseEnter={() => setNotification(row.item_code)}>
                        <Tooltip title={alertMsg ? alertProduct : ""}>
                          <ListItem
                            key={index}
                            button
                            className={
                              !isInStock(row.item_code) ||
                              isInProgress(row.item_code)
                                ? classes.disableText
                                : classes.nested
                            }
                          >
                            <Checkbox
                              disabled={
                                !isInStock(row.item_code) ||
                                isInProgress(row.item_code)
                              }
                              value={row.item_code}
                              onChange={handleCheck}
                              checked={isChecked(row.item_code)}
                            />
                            <ListItemText
                              className={classes.noWrap}
                              color={"primary"}
                              primary={
                                row.item_name + " (" + row.item_code + ")"
                              }
                            />
                          </ListItem>
                        </Tooltip>
                      </div>
                    )))}
              {isRec &&
                (products && searchProduct == ""
                  ? products.map((row, index) => (
                      <ListItem
                        onClick={() => handleSelectRecProduct(row)}
                        key={index}
                        button
                        className={classes.nested}
                      >
                        <ListItemText
                          className={classes.noWrap}
                          color={"primary"}
                          primary={row.item_name + " (" + row.item_code + ")"}
                        />
                      </ListItem>
                    ))
                  : filtredProducts &&
                    filtredProducts.length != 0 &&
                    filtredProducts.map((row, index) => (
                      <ListItem
                        onClick={() => handleSelectRecProduct(row)}
                        key={index}
                        button
                        className={classes.nested}
                      >
                        <ListItemText
                          className={classes.noWrap}
                          color={"primary"}
                          primary={row.item_name + " (" + row.item_code + ")"}
                        />
                      </ListItem>
                    )))}
              {isCategory && (
                <ListItem
                  onClick={() => handleSelectItemGroup("Toutes les Catégories")}
                  button
                  className={classes.nested}
                >
                  <ListItemText
                    className={classes.noWrap}
                    color={"primary"}
                    primary={"Toutes les Catégories"}
                  />
                </ListItem>
              )}
              {isCategory &&
                (categories && searchItemGroup == ""
                  ? categories.map((row, index) => (
                      <ListItem
                        onClick={() => handleSelectItemGroup(row)}
                        key={index}
                        button
                        className={classes.nested}
                      >
                        <ListItemText
                          className={classes.noWrap}
                          color={"primary"}
                          primary={row.title}
                        />
                      </ListItem>
                    ))
                  : filtredItemGroup &&
                    filtredItemGroup.length != 0 &&
                    filtredItemGroup.map((row, index) => (
                      <ListItem
                        onClick={() => handleSelectItemGroup(row)}
                        key={index}
                        button
                        className={classes.nested}
                      >
                        <ListItemText
                          className={classes.noWrap}
                          color={"primary"}
                          primary={row.title}
                        />
                      </ListItem>
                    )))}
              {isWarehouse &&
                !isRecWarehouse &&
                (warehouses && searchWarehouse == ""
                  ? warehouses.map((row, index) => (
                      <ListItem
                        onClick={() => handleSelectWarehouse(row)}
                        key={index}
                        button
                        className={classes.nested}
                      >
                        <ListItemText
                          className={classes.noWrap}
                          color={"primary"}
                          primary={row.name}
                        />
                      </ListItem>
                    ))
                  : filtredWarehouse &&
                    filtredWarehouse.length != 0 &&
                    filtredWarehouse.map((row, index) => (
                      <ListItem
                        onClick={() => handleSelectWarehouse(row)}
                        key={index}
                        button
                        className={classes.nested}
                      >
                        <ListItemText
                          className={classes.noWrap}
                          color={"primary"}
                          primary={row.name}
                        />
                      </ListItem>
                    )))}
              {isRecWarehouse &&
                (warehouses && searchWarehouse == ""
                  ? warehouses.map((row, index) => (
                      <ListItem
                        onClick={() => handleSelectRecWarehouse(row)}
                        key={index}
                        button
                        className={classes.nested}
                      >
                        <ListItemText
                          className={classes.noWrap}
                          color={"primary"}
                          primary={row.name}
                        />
                      </ListItem>
                    ))
                  : filtredWarehouse &&
                    filtredWarehouse.length != 0 &&
                    filtredWarehouse.map((row, index) => (
                      <ListItem
                        onClick={() => handleSelectRecWarehouse(row)}
                        key={index}
                        button
                        className={classes.nested}
                      >
                        <ListItemText
                          className={classes.noWrap}
                          color={"primary"}
                          primary={row.name}
                        />
                      </ListItem>
                    )))}
              {isPos && (
                <ListItem
                  onClick={() => handleSelectPos("Aucun")}
                  button
                  className={classes.nested}
                >
                  <ListItemText
                    className={classes.noWrap}
                    color={"primary"}
                    primary={"Aucun"}
                  />
                </ListItem>
              )}
              {isPos &&
                (pos && searchPos == ""
                  ? filtredPosByWarehouse.map((row, index) => (
                      <ListItem
                        onClick={() => handleSelectPos(row)}
                        key={index}
                        button
                        className={classes.nested}
                      >
                        <ListItemText
                          className={classes.noWrap}
                          color={"primary"}
                          primary={row.name}
                        />
                      </ListItem>
                    ))
                  : filtredPos &&
                    filtredPos.length != 0 &&
                    filtredPos.map((row, index) => (
                      <ListItem
                        onClick={() => handleSelectPos(row)}
                        key={index}
                        button
                        className={classes.nested}
                      >
                        <ListItemText
                          className={classes.noWrap}
                          color={"primary"}
                          primary={row.name}
                        />
                      </ListItem>
                    )))}
              {isSalesPerson && (
                <ListItem
                  onClick={() => handleSelectSalesPerson("Aucun")}
                  button
                  className={classes.nested}
                >
                  <ListItemText
                    className={classes.noWrap}
                    color={"primary"}
                    primary={"Aucun"}
                  />
                </ListItem>
              )}
              {isSalesPerson &&
                (Array.isArray(salesPersons) && searchSalesPerson == ""
                  ? salesPersons.map((row, index) => (
                      <ListItem
                        onClick={() => handleSelectSalesPerson(row)}
                        key={index}
                        button
                        className={classes.nested}
                      >
                        <ListItemText
                          className={classes.noWrap}
                          color={"primary"}
                          primary={row.sales_person_name}
                        />
                      </ListItem>
                    ))
                  : filtredSalesPerson &&
                    filtredSalesPerson.length != 0 &&
                    filtredSalesPerson.map((row, index) => (
                      <ListItem
                        onClick={() => handleSelectSalesPerson(row)}
                        key={index}
                        button
                        className={classes.nested}
                      >
                        <ListItemText
                          className={classes.noWrap}
                          color={"primary"}
                          primary={row.sales_person_name}
                        />
                      </ListItem>
                    )))}
            </List>
            {isClient && (
              <Box className={classes.linkSection}>
                <Box className={classes.addButton}>
                  <Button
                    className={classes.link}
                    onClick={() => setOpenMiniForm(true)}
                  >
                    <span className="icon-add-user" />{" "}
                    <span className={classes.userIcon}>
                      {t("clients:newClient")}
                    </span>
                  </Button>
                </Box>
              </Box>
            )}
          </Collapse>
        </Box>
      </ClickAwayListener>
      <Modal
        open={openMiniForm}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {miniForm}
      </Modal>
    </>
  );
};
