import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {useEffect, useState} from "react";
import {FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {fr} from "date-fns/esm/locale";
import DateFnsUtils from "@date-io/date-fns";
import {formatDate} from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import {format, startOfYear } from 'date-fns'; 
import { Loader } from "../../components/loader";
// import { LineChart } from '@mui/x-charts/LineChart';
import { CChart } from '@coreui/react-chartjs'
 
const PurchaseReport = () => {
    const currentDate = new Date();
    const formattedDate = format(startOfYear(currentDate), 'yyyy-MM-dd');

    const { t } = useTranslation(["buying", "common"]);
    const [loading, setLoading] = useState(false);
const [columns, setColumns] = useState([]);
const [rows, setRows] = useState([]);
const [scheduleDateDebut, setScheduleDateDebut] = useState(formattedDate);

const [scheduleDateFin,  setScheduleDateFin] = useState(formatDate(new Date(), "YYYY-MM-DD"))
    const [companyList, setCompanyList] = useState([]);
    const [companySelected, setCompanySelected] = useState('Vndit Company Test');


const [item , setItem] = useState(["Supplier","Supplier Group","Item Group", "Item"]);
const [selectItem, setSelectItem] = useState("Supplier Group");
const [purchase, setPurchase] = useState(["Purchase Order", "Purchase Invoice", "Purchase Receipt"]);
const [selectPurchase, setSelectPurchase] = useState("Purchase Order");
const [value, setValue] = useState(["Value", "Quantity"]);
const [selectValue, setSelectValue] = useState("Value");

const [weekly, setWeekly] = useState(["Weekly","Monthly","Quarterly","Yearly" ]);
const [selectWeekly, setSelectWeekly] = useState("Weekly");


const [xAxisData ,setxAxisData] = useState([])
const [seriesData,setseriesData] = useState([])

 const [ typeSelected,setSelectType] = useState("line");
 const [type, setType] = useState(['line', 'bar', 'doughnut','polarArea', 'pie', 'radar','bubble']);


const [error, setError] = useState({scheduleDateDebut: false,scheduleDateFin: false });
const handleDateDebut = (date, event) => {
        isNaN(Date.parse(date))
            ? setError({ ...error, scheduleDateOrder: false })
            : setScheduleDateDebut(date.toISOString().slice(0, 10));
            getPurchaseReport()
    };
    const handleDateFin = (date, event) => {
        isNaN(Date.parse(date))
            ? setError({ ...error, scheduleDateOrder: false })
            : setScheduleDateFin(date.toISOString().slice(0, 10));
            getPurchaseReport()
    };
const getCompanyList = () => {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                Authorization: JSON.parse(localStorage.getItem("user")).token,
            },
            body: JSON.stringify({
                doctype: "Company",
                fields: [ "`tabCompany`.`name`"]

            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    setCompanyList(data);
                }
            });
    };
   
    
    
const getPurchaseReport = () => {
    const data = {
        "report_name": "Purchase Analytics",
        "filters": {
            tree_type:selectItem,
            doc_type:selectPurchase,
            value_quantity:selectValue,
            from_date:scheduleDateDebut,
            to_date:scheduleDateFin,
            company:companySelected,
            range:selectWeekly}
    
}  

 
   setLoading(true);
        fetch(`${process.env.REACT_APP_API_URI}/api/stock/analytics-report`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                Authorization: JSON.parse(localStorage.getItem("user")).token,
            },
            body: JSON.stringify(data),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => { 
                const rowsWithIds = data.message.result.map((row, index) => ({
                    id: index,
                    ...row,
                }));
           const xdata = data.message.chart;     
           const xAxisData =xdata.data.labels.map((item) => (item));

            setxAxisData(xAxisData)
   
            const datasets = data.message.result.map((item, index) => ({
                label: item.entity,
                borderCapStyle: 'butt',
                borderJoinStyle: 'miter',
                data: Object.values(item)
                    .filter((value, index) => index > 1 && index < 19), // Exclude the entity name and total
            }));
            setseriesData(datasets)
               setColumns(data.message.columns);
            setRows(rowsWithIds);
           setLoading(false);
            });
    };
    useEffect(
        () => {
        
            getPurchaseReport();
            getCompanyList();
        },  []
    )
    const transformedColumns = columns.map((column) => (
            {
                field: column.fieldname,
                headerName: column.label, 
                width:  95,
           
                 
            }
      ));
const handleSelectPurchase = (event) => {
    setSelectPurchase(event.target.value);
    getPurchaseReport()
};
const handleSelectItem = (event) => {
    setSelectItem(event.target.value);
    getPurchaseReport()
};
const handleSelectValue = (event) => {
    setSelectValue(event.target.value);
    getPurchaseReport()
}
const handleSelectWeekly = (event) => {
    setSelectWeekly(event.target.value);
    getPurchaseReport()
}
const handleSelectCompany = (event) => {
    setCompanySelected(event.target.value);
    getPurchaseReport()
}
const handleSelectType = (event) => {
    setSelectType(event.target.value);
    getPurchaseReport()
}
 
if(loading){
   
    return <Loader/>
}
 
     return (
        <div style={{ height: '100%',   marginTop: '100px' }}>
        <Grid container spacing={2} alignItems="center" style={{ width: '100%' ,marginRight: '100px' }}> 
    <Grid item>
        <Select
            labelId="select-label-item"
            id="simple-select-item"
            value={selectItem}
            label="Item"
            onChange={(event) => handleSelectItem(event)}
        >
            {item?.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
            ))}
        </Select>
    </Grid>
    <Grid item>
        <Select
            labelId="select-label-item"
            id="simple-select-item"
            value={selectPurchase}
            onChange={(event) => handleSelectPurchase(event)}
        >
            {purchase?.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>  
            ))}
        </Select>
    </Grid>
    <Grid item>
        <Select
            labelId="select-label-item"
            id="simple-select-item"
            value={selectValue}
            onChange={(event) => handleSelectValue(event)}
            style={{ width: '100%' }}
        >
            {value?.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
            ))}
        </Select>
    </Grid>
    <Grid item>
        <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}  >
            <KeyboardDatePicker
                className={'date'}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                name="scheduleDateOrderConfirmation"
                inputVariant="outlined"
                id="date-till-picker-inline"
                value={scheduleDateDebut}
                onChange={handleDateDebut}
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
                minDate={startOfYear(new Date(),"YYYY-MM-DD")}
                style={{ width: '100%' }}
            />
        </MuiPickersUtilsProvider>
    </Grid>
    <Grid item>
        <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}  >
            <KeyboardDatePicker
                className={'date'}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                name="scheduleDateOrder"
                inputVariant="outlined"
                id="date-till-picker-inline"
                value={scheduleDateFin}
                onChange={handleDateFin}
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
                minDate={scheduleDateDebut}
                minDateMessage={t("common:minDateMessage")}
                style={{ width: '100%' }}
            />
        </MuiPickersUtilsProvider>
    </Grid>
    <Grid item>
        <Select
            labelId="select-label-item"
            id="simple-select-item"
            value={selectWeekly} 
            label="Item"
            onChange={(event) => handleSelectWeekly(event)}
            style={{ width: '100%' }}
        >
            {weekly?.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
            ))}
        </Select>
    </Grid>
    <Grid item>
        <FormControl fullWidth>
            <InputLabel id="select-label">Company</InputLabel>
            <Select 
                labelId="select-label"
                id="simple-select"
                value={companySelected}
                onChange={(e) => handleSelectCompany(e)}
                label="Company"
                style={{ width: '100%' }}
            >
                {companyList?.map((item) => (
                    <MenuItem key={item?.id} value={item?.name}>
                        {item?.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    </Grid>  
    <Grid item>
    <FormControl fullWidth>
    <InputLabel id="select-label">Type</InputLabel>
        <Select
            labelId="select-label-item"
            id="simple-select-item"
            value={typeSelected}
            label="Type"
            onChange={(event) => handleSelectType(event)}
        >
            {type?.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
            ))}
        </Select>
        </FormControl>
    </Grid>

</Grid>
    
    <Grid container alignItems="center" justifyContent="center" style={{ height: '700px', width:"100%" }}>
        
      
           <CChart 
              type={typeSelected}
              data={{
                labels: xAxisData,
                datasets: seriesData,
              }}  
              options={{
                aspectRatio: 1.5,
                tooltips: {
                  enabled: true,
                  intersect: false,
                }
              }}
              style={{ height: '500px', width: "100%", marginTop: 50, marginLeft: 200 }}
            />
          
        
</Grid>


      
          
            <DataGrid
              components={{ Toolbar: GridToolbar  }} 
                rows={rows}
               style={{fontSize: 15, fontWeight: 600, marginTop: 100, width: '100%', height: '700px' }}
                columns={transformedColumns}
                pageSize={10} 
                  pageSizeOptions={[10]}
                 
                  disableSelectionOnClick
                  disableRowSelectionOnClick
                  loading={loading}  
                   
            
            />
         
    </div>
    
    


    )

}

export default PurchaseReport