import React, { useEffect, useState } from "react";
import { Filter } from "../../components/filter";
import ClearIcon from "@material-ui/icons/Clear";
import { TextField, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useTableStyles } from "../../styles/table.style";
import * as localforage from "localforage";
import {
  FilterBy,
  GetFilterItems,
  GlobalfilterData,
} from "../../helpers/helper";
import { useSelector } from "react-redux";
import { SimpleTable } from "../../components/simpleTable";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Button, Chip, makeStyles, Box } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
    whiteSpace: "nowrap",
  },
  formControl: {
    maxWidth: 300,
    "& .MuiOutlinedInput-input": {
      padding: "8px 14px !important",
      fontSize: 12,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    fontSize: "0.875rem",
    borderRadius: 10,
  },
  icon: {
    marginRight: "0.375rem",
  },
  label: {
    fontSize: 12,
    color: theme.palette.gray.main,
    paddingLeft: 9,
  },
  date: {
    marginBottom: 15,
    marginRight: 15,
  },
  filterBlock: {
    "& .MuiOutlinedInput-input": {
      padding: "5.9px 14px !important",
      fontSize: 12,
    },
  },
}));

function removeDuplicates(originalArray, prop) {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}

export const Stock = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(["common", "product"]);
  const tableclasses = useTableStyles();

  const stockQtyReport = useSelector((state) => state.Inventory.stockQtyReport);

  const [filtredStock, setFiltredStock] = useState([]);
  const [categories, setCategories] = useState([]);
  const [warehouses, setWarehouses] = useState([]);

  const [page, setPage] = useState(1);
  const [searchProduct, setSearchProduct] = useState("");

  const [filters, setFilters] = useState({
    warehouse: [],
    item_group: [],
  });

  const columns = [
    { key: "item_code", title: "common:ref" },
    { key: "item_name", title: "Article" },
    { key: "item_group", title: "common:categorie" },
    { key: "brand", title: "common:brand" },
    { key: "actual_qty", title: "common:actualqty" },
    { key: "indented_qty", title: "common:indentedqty" },
    { key: "ordered_qty", title: "common:orderedqty" },
    { key: "planned_qty", title: "common:plannedqty" },
    { key: "projected_qty", title: "common:projectedqty" },
    { key: "re_order_level", title: "common:reorderlvl" },
    { key: "re_order_qty", title: "common:reorderqty" },
    { key: "reserved_qty", title: "common:reservedqty" },
    { key: "stock_uom", title: "common:stockuom" },
    { key: "warehouse", title: "common:warehouse" },
  ];

  const handleRedirection = (item_code) => {
    return history.push(`/stock/${encodeURIComponent(item_code)}`);
  };

  const handleSearchProduct = (event) => {
    setPage(1);
    setSearchProduct(event.target.value);
    setFiltredStock(
      GlobalfilterData(stockQtyReport, event.target.value, [
        "item_name",
        "item_code",
        "item_group",
      ])
    );
  };

  const HandleFilter = (key, item) => {
    setPage(1);
    const FilterDataBy = { ...filters, [key]: item };
    setFilters(FilterDataBy);
    setFiltredStock(stockQtyReport.filter(FilterBy(FilterDataBy)));
  };

  const HandleDeleteAll = () => {
    setPage(1);
    const data = {
      item_group: [],
      warehouse: [],
    };
    setFilters(data);
    setFiltredStock(stockQtyReport.filter(FilterBy(data)));
  };

  const HandleCloseChip = (item, key) => {
    setPage(1);
    const FilterDataBy = {
      ...filters,
      [key]: filters[key].filter((f) => f !== item),
    };
    setFilters(FilterDataBy);
    setFiltredStock(stockQtyReport.filter(FilterBy(FilterDataBy)));
  };

  useEffect(() => {
    localforage.getItem("reduxPersist:Inventory", function (err, value) {
      setFiltredStock(JSON.parse(value)?.stockQtyReport);
      const data = [];
      const warehouses = [];
      JSON.parse(value)?.stockQtyReport?.forEach((el) => {
        data.push({ label: el.item_group, value: el.item_group });
        warehouses.push({ label: el.warehouse, value: el.warehouse });
      });
      setWarehouses(removeDuplicates(warehouses, "value"));
      setCategories(removeDuplicates(data, "value"));
    });
  }, []);

  return (
    <div className={classes.root}>
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <Box className={classes.header}>
          <TextField
            id="outlined-adornment-weight"
            value={searchProduct}
            placeholder={t("common:rechercher")}
            onChange={handleSearchProduct}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/products")}
            startIcon={<CachedIcon />}
          >
            {t("common:produits")}
          </Button>
        </Box>
      </Box>
      <Box className={tableclasses.filterBlock}>
        <Box className={tableclasses.filter}>
          <Filter
            data={warehouses}
            title={t("common:magasin")}
            id={"warehouse"}
            HandleFilter={HandleFilter}
            value={filters.warehouse}
            number={filters.warehouse.length}
          />
          <Filter
            data={categories}
            title={t("common:categorie")}
            id={"item_group"}
            HandleFilter={HandleFilter}
            value={filters.item_group}
            number={filters.item_group.length}
          />
        </Box>
      </Box>

      <Box display="flex" flexWrap="wrap" alignItems="center">
        {filters.item_group.map((filter, i) => (
          <Chip
            key={i}
            className={tableclasses.chip}
            label={filter}
            onDelete={() => HandleCloseChip(filter, "item_group")}
            color="secondary"
            size={"small"}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {filters?.warehouse?.map((filter, i) => (
          <Chip
            key={i}
            className={tableclasses.chip}
            label={filter}
            onDelete={() => HandleCloseChip(filter, "warehouse")}
            color="secondary"
            size={"small"}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {(filters.warehouse.length > 0 || filters.item_group.length > 0) && (
          <Typography onClick={HandleDeleteAll} className={tableclasses.remove}>
            {t("common:delete")}
          </Typography>
        )}
      </Box>
      <Box>
        <SimpleTable
          title={"stock"}
          data={filtredStock.slice((page - 1) * 20, page * 20)}
          columns={columns}
          hasPaper={true}
          isRowClickable={true}
          handleRowClick={handleRedirection}
          rowPrimaryKey={"item_code"}
        />
        {Math.ceil(filtredStock.length / 20) > 0 && (
          <Box display="flex" justifyContent="center" m={4}>
            <Pagination
              onChange={(event, newPage) => setPage(newPage)}
              page={page}
              count={Math.ceil(filtredStock.length / 20)}
              color="primary"
            />
          </Box>
        )}
      </Box>
    </div>
  );
};
