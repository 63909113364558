import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ButtongoBack } from "../../../../components/buttongoback";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { fr } from "date-fns/esm/locale";
import DateFnsUtils from "@date-io/date-fns";
import { TransitionAlerts } from "../../../../components/TransitionAlerts";
import { BasicTable } from "../../../checkout/components/basictable";
import { formatDate, symbol } from "../../../../helpers/utils";
import { GlobalfilterData } from "../../../../helpers/helper";
import { ExpandMore } from "@material-ui/icons";
import {
  LoadSupplierDetails,
  createPurchaseInvoiceRequests,
  createPurchaseOrderRequests,
} from "../../../../store/buying/buying.action";
import { PurchaseForm } from "../purchaseForm";
import { Loader } from "../../../../components/loader";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 8,
    height: "22%",
  },
  virtualKeyboard: {
    position: "fixed",
    width: "80%",
    boxShadow: theme.shadows[5],
    borderRadius: 10,
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -105%)",
  },
  checkoutResume: {
    fontSize: 18,
    fontWeight: "bolder",
    align: "left",
    display: "block",
    textTransform: "uppercase",
  },
  button: {
    fontSize: 32,
    fontWeight: "bold",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.productsTable.button,
    color: theme.palette.productsTable.buttonColor,
    "&:hover": {
      backgroundColor: theme.palette.productsTable.button,
    },
  },
  modalPaper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));
export const AddPurchaseInvoice = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["common"]);

  const companyinformations = useSelector(
    (state) => state.Configuration.companyinformations
  );

  const buyingpricelist = useSelector(
    (state) => state.Configuration.buyingpricelist
  );
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [targetWarehouse, setTargetWarehouse] = useState(null);
  const [scheduleDate, setScheduleDate] = useState(
    formatDate(new Date(), "YYYY-MM-DD")
  );
  const itemTaxList = useSelector((state) => state.Product.itemTax);
  const [pricing, setPricing] = useState({
    totalAmountHT: 0,
    taxe: 0,
  });
  const [error, setError] = useState({
    invaliditems: [],
    items: false,
    targetWarehouse: false,
    selectedSupplier: false,
    scheduleDate: false,
  });
  const [items, setItems] = useState([]);

  const [isSubmit, setIsSubmit] = useState(false);
  /*    const [isPaid, setIsPaid] = useState(false); */

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  useEffect(() => {
    try {
      let totalAmount = 0;
      let taxAmount = 0;
      items.forEach((element) => {
        totalAmount += element?.qty * element?.price_list_rate;
        itemTaxList.forEach((elementtax) => {
          if (elementtax.name == element?.item_tax_template) {
            taxAmount +=
              ((element?.price_list_rate * elementtax.tax_rate) / 100) *
              element?.qty;
          }
        });
      });
      setPricing({
        totalAmountHT: totalAmount,
        taxe: taxAmount,
      });
    } catch (error) {
      console.log("**********************", error);
    }
  }, [items]);
  const onClickgoBack = () => {
    history.goBack();
  };
  const handleSubmit = async (event) => {
    let testitem = [];
    items.forEach((element) => {
      if (element?.qty < 1 || element?.price_list_rate <= 0) {
        testitem.push(element?.item_code);
      }
    });
    const var_error = {
      invaliditems: testitem,
      items: items?.length <= 0 || testitem.length > 0,
      selectedSupplier: !selectedSupplier || selectedSupplier === "",
    };
    setError(var_error);
    if (testitem?.length > 0) {
      setAlert({
        open: true,
        message: t("buying:pleaseEnterThePurchasePrice"),
        severity: "error",
      });
    }
    const taxes = [];
    if (JSON.stringify(var_error).indexOf("true") < 0) {
      setIsSubmit(true);
      items.forEach((element) => {
        if (element?.item_tax_template) {
          const tax = {
            account_head: element?.item_tax_template?.substring(
              element?.item_tax_template.indexOf(" ") + 1
            ),
            parentfield: "taxes",
            docstatus: 0,
            parenttype: "Purchase Invoice",
            doctype: "Sales Taxes and Charges",
            charge_type: "On Net Total",
            cost_center: `${companyacounts?.cost_center}`,
            description:
              element?.item_tax_template.slice(
                8,
                element?.item_tax_template.indexOf("%") + 1
              ) +
              " @ " +
              parseFloat(
                element?.item_tax_template
                  .match(/\d+/g)
                  .toString()
                  .replace(",", ".")
              ).toFixed(1),
          };
          const indextax = taxes?.findIndex(
            (t) => t?.account_head === tax?.account_head
          );
          if (indextax < 0) taxes.push(tax);
        }
      });
      const req = {
        naming_series: "ACC-PINV-.YYYY.-",
        supplier: selectedSupplier,
        supplier_name: selectedSupplier,
        apply_tds: 0,
        docstatus: 0,
        company: companyinformations?.company_name,
        // posting_date: formatDate(new Date(), "YYYY-MM-DD"),
        // posting_time:formatDate(new Date(), "hh:mm:ss"),
        set_posting_time: 0,
        currency: companyinformations?.default_currency,
        conversion_rate: 1,
        buying_price_list: buyingpricelist,
        price_list_currency: companyinformations?.default_currency,
        plc_conversion_rate: 1,
        ignore_pricing_rule: 0,
        is_subcontracted: "No",
        total_net_weight: 0,
        tax_category: "",
        base_taxes_and_charges_deducted: 0,
        taxes_and_charges_deducted: 0,
        apply_discount_on: "Grand Total",
        base_discount_amount: 0,
        // additional_discount_percentage: 0,
        discount_amount: 0,
        base_rounding_adjustment: 0,
        base_rounded_total: 0,
        rounding_adjustment: 0,
        rounded_total: 0,
        disable_rounded_total: 1,
        //advance_paid: 0,
        //per_billed: 0,
        //per_received: 0,
        language: "en",
        group_same_items: 0,
        party_account_currency: companyinformations?.default_currency,
        is_internal_supplier: 0,
        represents_company: "",
        doctype: "Purchase Invoice",
        items: items,
        taxes: taxes,
      };
      dispatch(createPurchaseInvoiceRequests(req));
    }
  };
  const notification = useSelector((state) => state.Notification.notification);
  useEffect(() => {
    if (notification) {
      if (
        notification?.message === "notification:successCreatedPurchaseInvoice"
      ) {
        window.location.href = "/buying?tab=5";
      }
      if (notification?.code !== "success") {
        setIsSubmit(false);
      }
    }
  }, [notification]);
  if (isSubmit) return <Loader />;
  return (
    <Box>
      <ButtongoBack label={t("common:back")} onClick={onClickgoBack} />
      <PurchaseForm
        handleSubmit={handleSubmit}
        selectedSupplier={selectedSupplier}
        setSelectedSupplier={setSelectedSupplier}
        targetWarehouse={targetWarehouse}
        setTargetWarehouse={setTargetWarehouse}
        scheduleDate={scheduleDate}
        setScheduleDate={setScheduleDate}
        items={items}
        setItems={setItems}
        error={error}
        setError={setError}
        ispurchaseInvoice
        pricing={pricing}
        alert={alert}
        setAlert={setAlert}
      />
    </Box>
  );
};
