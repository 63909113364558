import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ButtongoBack } from "../../../../components/buttongoback";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { fr } from "date-fns/esm/locale";
import DateFnsUtils from "@date-io/date-fns";
import { TransitionAlerts } from "../../../../components/TransitionAlerts";
import { BasicTable } from "../../../checkout/components/basictable";
import { formatDate } from "../../../../helpers/utils";
import { GlobalfilterData } from "../../../../helpers/helper";
import { ExpandMore } from "@material-ui/icons";

import {
  LoadSupplierDetails,
  createPurchaseOrderRequests,
} from "../../../../store/buying/buying.action";
import { PurchaseForm } from "../purchaseForm";
import {
  ignorePricingRules,
  updateItemDetails,
} from "../../../../store/checkout/checkout.action";

const useStyles = makeStyles((theme) => ({
  blockTitle: {
    fontSize: 14,
    marginBottom: 8,
    paddingLeft: 10,
  },
  formControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  paper: {
    paddingLeft: 10,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
    "& .MuiSelect-root": {
      padding: "0.4938rem 0.875rem",
    },
  },
  label: {
    fontSize: 12,
    color: theme.palette.gray.main,
    paddingLeft: 9,
    marginBottom: 6,
  },
  date: {
    marginBottom: 15,
  },
  formBlock: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
    paddingTop: "20px",
  },
  customButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
    margin: "0rem 1.375rem",
    padding: "0.1875rem 1.4375rem",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },
  cancelButton: {
    color: "#909BAB",
  },
  basictable: {
    height: "255px",
  },
  save: {
    marginTop: "10px",
    marginLeft: 0,
    marginRight: -20,
  },
}));

export const AddPurchaseOrder = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["common"]);

  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [targetWarehouse, setTargetWarehouse] = useState(null);
  const [scheduleDate, setScheduleDate] = useState(
    formatDate(new Date(), "YYYY-MM-DD")
  );

  const [error, setError] = useState({
    invaliditems: [],
    items: false,
    targetWarehouse: false,
    selectedSupplier: false,
    scheduleDate: false,
  });
  const [items, setItems] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const onClickgoBack = () => {
    history.goBack();
    dispatch(updateItemDetails([]));
    dispatch(ignorePricingRules(true));
  };

  return (
    <Box>
      <ButtongoBack label={t("common:back")} onClick={onClickgoBack} />
      <PurchaseForm
        selectedSupplier={selectedSupplier}
        setSelectedSupplier={setSelectedSupplier}
        targetWarehouse={targetWarehouse}
        setTargetWarehouse={setTargetWarehouse}
        scheduleDate={scheduleDate}
        setScheduleDate={setScheduleDate}
        items={items}
        setItems={setItems}
        error={error}
        setError={setError}
        alert={alert}
        setAlert={setAlert}
        isPricingRule
      />
    </Box>
  );
};
