import React, { useState } from "react";
import { useStyles } from "./style";
import { useSelector } from "react-redux";
import { useTableStyles } from "../../../styles/table.style";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const AvoirComponentToPrint = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const exchangeRate = useSelector((state) => state.Checkout.exchangeRate);
  const selectCurrency = useSelector((state) => state.Checkout.selectCurrency);
  const clients = useSelector((state) => state.Client.clients);
  const [client, setClient] = useState(
    clients.find((cl) => cl?.name == props?.document?.customer)
  );
  const { t } = useTranslation(["common", "buying", "checkout"]);

  return (
    <div className={classes.main} ref={ref}>
      <header className={classes.header}>
        <div className={classes.logo}>
          <img src={activeImageTheme} />
        </div>
        <h1 className={classes.title}>
          {"Avoir N° : "}
          {props?.document?.name}
        </h1>
        <Grid container spacing={2}>
          {props?.document && (
            <Grid item style={{ width: "70%" }}>
              <div>
                <strong>
                  {t("common:client")} {": "}
                </strong>
                {props?.document?.customer ?? props?.document?.customer}
              </div>
              <div>
                <strong>
                  {t("common:contact")}
                  {": "}
                </strong>
                {client?.mobile_no
                  ? client?.mobile_no
                  : client?.email_id
                  ? client?.email_id
                  : "-"}
              </div>
              <div>
                <strong>
                  {t("common:commande")}
                  {": "}
                </strong>{" "}
                {props?.returnedInvoice?.return_against ??
                  props?.returnedInvoice?.return_against}
              </div>
              <div>
                <strong>
                  {t("common:total")} {": "}
                </strong>
                {props?.document?.credit_amount?.toFixed(3)}{" "}
                {props?.document?.currency}
              </div>
              <div>
                <strong>
                  {t("checkout:ValidFrom")} {": "}
                </strong>
                {props?.document?.creation_date}
              </div>
              <div>
                <strong>
                  {t("checkout:RRetour")} {": "}
                </strong>
                {props?.document?.return_reason}
              </div>
            </Grid>
          )}
          <Grid item style={{ width: "30%" }}>
            <div>{globalDefaults?.default_company}</div>
            {selectedData &&
              selectedData.contact &&
              Array.isArray(selectedData.contact) &&
              selectedData.contact.map((row, i) => (
                <>
                  <div>{row?.phone_no ?? row?.phone_no}</div>
                  <div>{row?.mobile_no ?? row?.mobile_no}</div>
                  <div>
                    {row.address_line_1 === null ? "-" : row.address_line_1}
                  </div>
                  <div>{row.city === null ? "-" : row.city}</div>
                </>
              ))}
          </Grid>
        </Grid>
      </header>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.tableheader}>{t("common:ref")}</th>
            <th className={classes.tableheader}>{t("common:name")}</th>
            <th className={classes.tableheader}>P.U</th>
            <th className={classes.tableheader}>T.V.A.</th>
            <th className={classes.tableheader}>{t("common:remise")}</th>
            <th className={classes.tableheader}>{t("common:qty")}</th>
            <th className={classes.tableheader}>{t("common:total")}</th>
          </tr>
        </thead>
        <tbody>
          {props?.returnedInvoice &&
            props?.returnedInvoice?.items?.map((row, index) => (
              <tr key={index}>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {row?.item_code}
                </td>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {row?.item_name}
                </td>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {Math.abs(row?.price_list_rate)?.toFixed(3)}{" "}
                  {props?.details?.currency}
                </td>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {row.item_tax_template ? row?.item_tax_template : "-"}
                </td>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {row?.discount_percentage && row?.discount_percentage != 0
                    ? row?.discount_percentage + " %"
                    : row?.discount_amoun && row?.discount_amoun != 0
                    ? Math.abs(row?.discount_amount)?.toFixed(3) +
                      " " +
                      props?.details?.currency
                    : "0 %"}
                </td>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {Math.abs(row?.qty)}
                </td>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {Math.abs(row?.amount)?.toFixed(3)}{" "}
                  {props?.detailInvoice?.currency}
                </td>
              </tr>
            ))}
          <tr>
            <td className={classes.tableColumn} colSpan="5">
              {t("common:totalHT")}
            </td>
            <td
              className={classes.tableColumn}
              style={{ textAlign: "left" }}
              colSpan="2"
            >
              {Math.abs(props?.returnedInvoice?.net_total)?.toFixed(3)}{" "}
              {props?.detailInvoice?.currency}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="5">
              {t("common:totalTaxe")}
            </td>
            <td
              className={classes.tableColumn}
              style={{ textAlign: "left" }}
              colSpan="2"
            >
              {Math.abs(
                props?.returnedInvoice?.total_taxes_and_charges
              )?.toFixed(3)}{" "}
              {props?.returnedInvoice?.currency}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="5">
              {t("common:remise")} {t("common:total")}
            </td>
            <td
              className={classes.tableColumn}
              style={{ textAlign: "left" }}
              colSpan="2"
            >
              {props?.returnedInvoice?.discount
                ? props?.returnedInvoice?.discount
                : props?.returnedInvoice?.additional_discount_percentage
                ? Math.abs(
                    props?.returnedInvoice?.additional_discount_percentage
                  )
                : 0}
              %
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="5">
              {t("common:qtyTotal")}
            </td>
            <td
              className={classes.tableColumn}
              style={{ textAlign: "left" }}
              colSpan="2"
            >
              {Math.abs(props?.returnedInvoice?.total_qty)}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="5">
              {t("common:grandTotal")}
            </td>
            <td
              className={classes.tableColumn}
              style={{ textAlign: "left" }}
              colSpan="2"
            >
              {Math.abs(props?.returnedInvoice?.grand_total)?.toFixed(3)}{" "}
              {props?.returnedInvoice?.currency}
            </td>
          </tr>
        </tbody>
      </table>

      <div className={classes.company}></div>
    </div>
  );
});
