
import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root : {
    padding :"1.0625rem 1.5rem",
  },
  price: {
    fontWeight: 600,
  },
  filterList : {
      borderTop : 'none'
  },
  button: {
    fontSize: 14,
    backgroundColor: theme.palette.productsTable.button,
    color: theme.palette.productsTable.buttonColor,
    "&:hover": {
      backgroundColor: theme.palette.productsTable.button,
    },
  },
  print: {
    display: "none",
  },
  backLink : {
    fontSize : 12,
    color : '#2F334D',
    display :"flex",
    alignItems : "center",
    cursor :"pointer"
  },
  backBox : {
    marginTop : "0.5rem",
    width : "fit-content",   
  },
  addButton: {
    marginTop: 20,
  },
  paper: {
    position: "absolute",
    width: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    border: `${theme.palette.blue.main} 2px solid`,
  },
  ticketBlock : {
    textAlign : "center",
    boxShadow: "0px 5px 15px 4px #0000000F",
    [theme.breakpoints.up('md')]: {
      minWidth : 420
    },
    fontFamily: 'Courier Prime',
    color : "#707070",
    fontSize : 14,
    padding : "2rem 0.5rem",
    lineHeight: 2
  },
  ticketHeader : {
    marginBottom: "7px",
    textAlign : "center",
    fontWeight: "bold",
    fontSize:"30px",
    color: theme.palette.primary.main,
  '& > *': {
    margin: theme.spacing(-2),
    },
  },
  title: {
    //backgroundImage: `url(${Image})`
    color :theme.palette.blue.main,
    fontSize:30
  },
  print: {
    display: "none",
  },
  positive:{
    color: "green"
  },
  negative:{
    color: "red"
  },
}));

