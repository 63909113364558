  /* eslint-disable */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { TotalPrice } from "../totalPrice";
import useScanDetection from "use-scan-detection";
 import {
  Button,
  TextField,
  Typography,
  Modal,
  ClickAwayListener,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Tooltip,
  OutlinedInput,
  Chip,
  InputAdornment,
  FormControl,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";
import { useTableStyles } from "../../../../styles/table.style";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateProducts,
  UpdatePayment,
} from "../../../../store/checkout/checkout.action";
import { useTranslation } from "react-i18next";
import { GlobalfilterData } from "../../../../helpers/helper";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { UpdateSalesPerson } from "../../../../store/checkout/checkout.action";
import Keyboard from "react-simple-keyboard";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import "react-simple-keyboard/build/css/index.css";
import { symbol } from "../../../../helpers/utils";
import AlertModal from "../../../../components/common/alertModal";
import { LoadProductStockById } from "./../../../../store/product/product.action";
import MessageStock from "../../../../components/common/messageStock";
import french from "simple-keyboard-layouts/build/layouts/french";
import { displayProductsStock } from "../../../../store/product/product.action";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PricingRules from "../../../../components/pricingRules";
import { formatDate } from "../../../../helpers/utils";
import {loadItemDetail} from "./../../../../store/checkout/checkout.action";

const useStyles = makeStyles((theme) => ({
  virtualKeyboard: {
    position: "fixed",
    width: "80%",
    boxShadow: theme.shadows[5],
    borderRadius: 10,
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -105%)",
  },
  dialogTitle: {
    color: theme.palette.primary.main,
  },
  table: {
    borderSpacing: "0 5px",
    borderCollapse: "separate",
    "& .MuiTableRow-root": {
      display: "table",
      width: "100%",
      tableLayout: "fixed",
    },
    marginBottom: 0,
  },
  tableRowWithPR: {
    color: theme.palette.blue.main,
    marginRight: "10px",
    fontSize: "16px",
  },
  tableRow: {
    height: 56,
    "& .MuiTableCell-root:first-child": {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
    },
    "& .MuiTableCell-root:last-child": {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
    },
  },
  tableCell: {
    background: "#fff",
    fontSize: "0.8rem",
    padding: "8px 12px",
    color: theme.palette.primary.main,
  },
  paper: {
    padding: "0px 20px",
    background: theme.palette.productsTable.background,
    overflow: "auto",
    borderRadius: 19,
    height: "100%",
    "& .MuiInputBase-root": {
      background: theme.palette.productsTable.tableCell,
      marginTop: 20,
    },
    "& .MuiOutlinedInput-input": {
      padding: "7.5px 14px",
      fontSize: 12,
    },
  },
  price: {
    fontWeight: 700,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "&:hover": {
      overflow: "visible",
    },
  },
  tablecellHeader: {
    paddingBottom: 0,
    color: "#657288",
    fontSize: 12,
    borderBottom: "none",
  },
  formControl: {
    minWidth: 340,
  },
  chip: {
    borderRadius: 6,
    color: theme.palette.primary.main,
    backgroundColor: "white",
    marginTop: 10,
    marginRight: 12,
    border: "1px solid",
    borderColor: theme.palette.primary.main,
  },
  tableBody: {
    display: "block",
    height: 225,
    overflow: "auto",
  },
  delete: {
    marginTop: 0,
    cursor: "pointer",
    position: "relative",
  },
  quantityButton: {
    width: 28,
    height: 25,
    background: theme.palette.secondary.main,
    borderRadius: 5,
    fontSize: 21,
    paddingTop: 2,
  },
  quantityButtonS: {},
  quantityText: {},
  discInput: {
    width: 50,
    "& .MuiInputBase-formControl": {
      marginTop: "-4px",
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  },
  prixInput: {
    width: 100,
    marginRight: 5,
    "& .MuiInputBase-formControl": {
      marginTop: "-4px",
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  },
  qtyControl: {
    position: "relative",
    width: "100px",
  },
  prixControl: {
    position: "relative",
    width: "200px",
  },
  noWrap: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "&:hover": {
      overflow: "visible",
    },
  },
  simpleKeyboard: {
    maxWidth: "850px",
    display: "none",
  },
  showKeyboard: {
    display: "block",
  },
  itemInput: {
    height: 45,
  },
  avatar: {
    color: theme.palette.blue.main,
    backgroundColor: theme.palette.secondary.main,
  },
  alert: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  listItemtext: {
    backgroundColor: theme.palette.blue.main,
    padding: 10,
    borderRadius: 10,
  },
}));

function SimpleDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { onClose, open, item } = props;
  const salesPersonList = useSelector(
    (state) => state.Checkout.salesPersonList
  );
  const checkedItems = useSelector(
    (state) => state.Checkout.selectedSalesPerson
  );
  const { t } = useTranslation(["common", "checkout"]);

  var isChecked = (salesPerson, item_code) =>
    checkedItems.findIndex((s) => s.item == item_code) != -1
      ? checkedItems[checkedItems.findIndex((s) => s.item == item_code)]
          .sales_person_name == salesPerson
        ? true
        : false
      : false;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    let updatedList = [...checkedItems];
    const itemIndex = updatedList.findIndex((s) => s.item === item);

    if (itemIndex !== -1) {
      // Clicked on the currently selected choice, so deselect it
      updatedList.splice(itemIndex, 1);
    } else {
      // Clicked on a different choice, so select the new choice
      updatedList.push({ item: item, sales_person_name: value });
      onClose();
    }

    dispatch(UpdateSalesPerson(updatedList));
  };

  const isStringsArray = (arr) => {
    if (arr.length == 0) return false;
    return arr.every((i) => typeof i === "string");
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle
        id="simple-dialog-title"
        align="center"
        className={classes.dialogTitle}
      >
        {t("checkout:SVendor")}
      </DialogTitle>
      {isStringsArray(checkedItems) && (
        <Typography style={{ fontSize: 12, textAlign: "center", color: "red" }}>
          {t("checkout:selectVendorMsg")}
        </Typography>
      )}
      <List>
        {Array.isArray(salesPersonList) &&
          salesPersonList.length != 0 &&
          salesPersonList.map((sp, index) => (
            <ListItem
              button
              onClick={() => handleListItemClick(sp.sales_person_name)}
              key={index}
              disabled={isStringsArray(checkedItems)}
            >
              <ListItemAvatar>
                <Avatar
                  className={
                    isChecked(sp.sales_person_name, item)
                      ? classes.avatar
                      : null
                  }
                />
              </ListItemAvatar>
              <ListItemText
                primary={sp.sales_person_name}
                className={classes.listItemtext}
              />
            </ListItem>
          ))}
        {salesPersonList.length == 0 ||
          (!Array.isArray(salesPersonList) && (
            <ListItem>
              <ListItemAvatar>
                <Avatar />
              </ListItemAvatar>
              <ListItemText
                primary={t("checkout:selectVendor")}
                className={classes.listItemtext}
              />
            </ListItem>
          ))}
      </List>
    </Dialog>
  );
}

export const BasicTable = (props) => {
 
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableclasses = useTableStyles();
  const [state, setState] = React.useState({
    open: false,
    row: null,
  });
  const [message, setMessage] = React.useState({
    row: null,
  });
  const products = useSelector((state) => state.Product.products);
  const amount = useSelector((state) => state.Checkout.amount);
  const currencyExchangeRate = useSelector(
    (state) => state.Checkout.currencyExchangeRate
  );
  const productsStock = useSelector((state) => state.Transfert.ProductsStock);
  const client = useSelector((state) => state.Checkout.client);
  const currencies = useSelector((state) => state.Client.currencies);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const [open, setOpen] = React.useState(false);
  const [orderLine, setOrderLine] = React.useState("");
  const [layout, setLayout] = useState("default");
  const [keyboardVisibility, setKeyboardVisibility] = useState(false);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const pricingRules = useSelector((state) => state.Checkout.pricingRules);
  const rows = useSelector((state) => state.Checkout.products);
  const productStock = useSelector((state) => state.Product.productStock);
  const itemTaxList = useSelector((state) => state.Product.itemTax);
  const imageList = useSelector((state) => state.Product.imagesData);
  const productsPos = useSelector((state) => state.Product.productsPos);
  const suggestion = useSelector((state) => state.Product.products);
  const cashier = useSelector((state) => state.Login.selectedData?.cashier);
  const store = useSelector((state) => state.Login.selectedData?.store);
  const selectedClient = useSelector((state) => state.Checkout.client);

  const checkedItems = useSelector(
    (state) => state.Checkout.selectedSalesPerson
  );
  const itemDetails = useSelector((state) => state.Checkout.itemDetails);
  const itemsWithPricingRules = itemDetails?.filter((item) =>
    Object.keys(item).includes("pricing_rules")
  );

  const ignorePricingRule = useSelector(
    (state) => state.Checkout.ignorePricingRules
  );

  const isExistInPOS = (item_code) => {
    if (
      itemsWithPricingRules &&
      itemsWithPricingRules.find((p) => p.item_code == item_code)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isExistInPOS1 = (item_code) => {
    if (
      productsPos 
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isFreeItemExistInPOS = (item_code) => {
    if (
      itemsWithPricingRules &&
      itemsWithPricingRules?.free_item_data &&
      itemsWithPricingRules?.free_item_data?.find(
        (p) => p.item_code == item_code
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isChecked = (itemCode) => {
    return checkedItems.findIndex((s) => s.item === itemCode) !== -1;
  };

  const currencySymbol =
    client && client.default_currency
      ? symbol(client.default_currency, currencies)?.symbol
      : symbol(globalDefaults?.default_currency, currencies) &&
        symbol(globalDefaults?.default_currency, currencies)?.symbol;
  const { t } = useTranslation(["common", "checkout"]);

  var indents = [];
  for (var i = 0; i < props.initialLines; i++) {
    indents.push(
      <TableRow className={classes.tableRow} key={i}>
        <TableCell className={classes.tableCell} scope="row"></TableCell>
        <TableCell className={classes.tableCell} align="center"></TableCell>
        <TableCell className={classes.tableCell} align="center"></TableCell>
        <TableCell className={classes.tableCell} align="center"></TableCell>
        {!props.transfer && (
          <TableCell
            align="center"
            className={clsx(classes.tableCell, classes.price)}
          ></TableCell>
        )}
      </TableRow>
    );
  }


  const loadItemDetails = (items) => {
    items?.forEach((item) => {
      let doc = {
        doctype: "POS Invoice",
        company: globalDefaults.default_company,
        posting_date: formatDate(new Date(), "YYYY-MM-DD"),
        currency: globalDefaults.default_currency,
        price_list_currency: globalDefaults.default_currency,
        ignore_pricing_rule: 0,
        party_account_currency: globalDefaults.default_currency,
        items: [
          {
            doctype: "POS Invoice Item",
            item_group: item.item_group,
            parentfield: "items",
            parenttype: "POS Invoice",
            qty: item.qty,
            item_code: item.item_code,
          },
        ],
        pos_profile: cashier,
        set_warehouse: store,
        customer_name: selectedClient?.customer_name
          ? selectedClient?.customer_name
          : defaultClient?.customer_name,
        customer: selectedClient?.customer_name
          ? selectedClient?.customer_name
          : defaultClient?.customer_name,
      };
      let args = {
        item_code: item.item_code,
        set_warehouse: store,
        customer: selectedClient?.customer_name
          ? selectedClient?.customer_name
          : defaultClient?.customer_name,
        currency: globalDefaults.default_currency,
        price_list_currency: globalDefaults.default_currency,
        company: globalDefaults.default_company,
        ignore_pricing_rule: 0,
        doctype: "POS Invoice",
        pos_profile: cashier,
      };
      dispatch(loadItemDetail(args, doc));
    });
  };

  const handleAddService = async(item) => {
   
    if (item?.item_code != null && isExistInPOS1(item.item_code)) {
      var discountedProducts =
        pricingRules && pricingRules.items
          ? pricingRules.items.map((i) => i.item_code)
          : null;
      var data = suggestion.find((s) => s.item_code == item.item_code);

      if (data) {
        const match = productsPos.find(
          (pos) => pos.item_code === data.item_code
        );

        if (match) {
          data = {
            ...data,
            ...match,
          };
        }
      }

      const ItemIndex = rows.findIndex((s) => s.item_code == item.item_code);
      if (ItemIndex != -1) {
        
          rows[ItemIndex].quantity = parseInt(rows[ItemIndex].quantity) + 1;
          rows[ItemIndex].qty = parseInt(rows[ItemIndex].qty) + 1;
          rows[ItemIndex].price_list_rate = parseFloat(
            rows[ItemIndex].price_list_rate
              ? rows[ItemIndex].price_list_rate
              : rows[ItemIndex].standard_rate
          );
          rows[ItemIndex].net_rate =
            (rows[ItemIndex].price_list_rate -
              (rows[ItemIndex].price_list_rate *
                parseFloat(rows[ItemIndex].discount)) /
                100) /
            currencyExchangeRate;
          rows[ItemIndex].net_amount =
            rows[ItemIndex].net_rate * rows[ItemIndex].quantity;
          rows[ItemIndex].discount_amount =
            (rows[ItemIndex].price_list_rate - rows[ItemIndex].net_rate) *
            rows[ItemIndex].quantity;
        
      } else {
        data.discount = 0;
        data.quantity = 1;
        data.qty = 1;
        data.price_list_rate = data.price_list_rate
          ? parseFloat(data.price_list_rate)
          : parseFloat(data.standard_rate);
        data.net_rate =
          (data.price_list_rate -
            (data.price_list_rate * parseFloat(data.discount)) / 100) /
          currencyExchangeRate;
        data.net_amount = data.net_rate * data.quantity;
        data.discount_amount =
          (data.price_list_rate - data.net_rate) * data.quantity;
        if (
          discountedProducts &&
          discountedProducts.includes(data.item_code) &&
          pricingRules?.discount_percentage != 0
        ) {
          data.discount = pricingRules?.discount_percentage;
          data.net_rate =
            (data.price_list_rate -
              (data.price_list_rate *
                parseFloat(pricingRules?.discount_percentage)) /
                100) /
            currencyExchangeRate;
          data.net_amount = data.net_rate * data.quantity;
        } else if (
          discountedProducts &&
          discountedProducts.includes(data.item_code) &&
          pricingRules?.discount_amount != 0
        ) {
          data.discount_amount = pricingRules?.discount_amount;
          data.net_rate = data.price_list_rate - pricingRules?.discount_amount;
          data.net_amount = data.net_rate * data.quantity;
        }
        rows.push(data);
      
        props.initialLines.splice(-1);
      }
      dispatch(UpdateProducts(rows));
      loadItemDetails(rows);
      const totalAmount = props.GetTotalPrice(rows);
      const taxAmount =
        totalAmount -
        rows.reduce((a, v) => (a = a + parseFloat(v.net_amount)), 0);
      dispatch(UpdatePayment({ ...amount, totalAmount, taxAmount }));
      
    }
   
  };




  const HandleUpdateDisc = (e, id) => {
    const ItemIndex = rows.findIndex((s) => s.item_code === id);
    const data = [...rows];
    if (ItemIndex !== -1) {
      if (!pricingRules) {
        data[ItemIndex].discount =
          e.target && e.target.value && e.target.value >= 0
            ? e.target.value
            : 0;
      } else {
        data[ItemIndex].discount = e ? e : 0;
      }
      data[ItemIndex].net_rate =
        (data[ItemIndex].price_list_rate -
          (data[ItemIndex].price_list_rate * data[ItemIndex].discount) / 100) /
        currencyExchangeRate;
      data[ItemIndex].net_amount =
        data[ItemIndex].net_rate * data[ItemIndex].quantity;
      data[ItemIndex].discount_amount =
        data[ItemIndex].discount_amount * data[ItemIndex].quantity;
      dispatch(UpdateProducts(rows));
      const totalAmount = props.GetTotalPrice(rows);
      const taxAmount =
        totalAmount -
        rows.reduce((a, v) => (a = a + parseFloat(v.net_amount)), 0);
      dispatch(UpdatePayment({ ...amount, totalAmount, taxAmount }));
    }
  };

  const HandleUpdateDiscAmount = (e, id) => {
    const ItemIndex = rows.findIndex((s) => s.item_code === id);
    const data = [...rows];
    if (ItemIndex !== -1) {
      data[ItemIndex].net_rate =
        (data[ItemIndex].net_rate - e) / currencyExchangeRate;
      data[ItemIndex].net_amount =
        data[ItemIndex].net_rate * data[ItemIndex].quantity;
      data[ItemIndex].discount_amount = e * data[ItemIndex].quantity;
      dispatch(UpdateProducts(rows));
      const totalAmount = props.GetTotalPrice(rows);
      const taxAmount =
        totalAmount -
        rows.reduce((a, v) => (a = a + parseFloat(v.net_amount)), 0);
      dispatch(UpdatePayment({ ...amount, totalAmount, taxAmount }));
    }
  };

  const keyboard = React.useRef();
 const [barcode, setBarcode] = useState("");
 useScanDetection({
    onComplete: setBarcode,
    minLength: 0,
    maxLength: 50,
    endChar: "Enter",
  });
  const handleSearchProduct = (e) => {
    const input = e.target.value;
    props.setSearchProduct(input) ;
    keyboard.current && keyboard.current.setInput(input);
    if (input == "") {
      props.setsuggestion([]);
      return;
    }
    const data = GlobalfilterData(
        products?.filter((p) => p?.is_stock_item == 1 || p?.is_stock_item == 0),
      input,
      [
        "barcode",
        "item_code",
        "item_name",
        "description",
        "price_list_rate",
        "standard_rate",
        "item_group",
      ]
    );

    props.setsuggestion(data);
  };
 

 
  useEffect(() => {
    if (barcode) {
      // Setting the search input to the scanned barcode
     // props.setSearchProduct(barcode);
      
      // Optionally clear the previous suggestions if any
      // props.setsuggestion([]);
  
      // Trigger the search directly with the scanned barcode
      const data = GlobalfilterData(
        products?.filter((p) => p?.is_stock_item === 1),
        barcode,
        [
          "barcode", // Make sure barcode is part of the fields being searched
          "item_code",
          "item_name",
          "description",
          "price_list_rate",
          "standard_rate",
          "item_group",
        ]
      );
      props.setsuggestion(data);
      props.suggestion.map((row, index) => (
          props.handleAddproduct(row.item_name),
          props.setsuggestion([])
      ))
      
  console.log("data",data)
      // Set the suggestions based on the filtered data
     
      
      // Clear the barcode after searching to prevent re-triggering
      setBarcode("");
    }
  }, [barcode, products, props]);
  
  const onChange = (input) => {
    props.setSearchProduct(input);
    if (input == "") {
      props.setsuggestion([]);
      return;
    }
    const data = GlobalfilterData(
        products?.filter((p) => p?.is_stock_item == 1),
      input,
      [
        "item_code",
        "item_name",
        "description",
        "price_list_rate",
        "standard_rate",
        "item_group",
      ]
    );
 
    props.setsuggestion(data);
  };

  const [capsLockEnabled, setCapsLockEnabled] = useState(false);

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
    setCapsLockEnabled(false);
  };

  useEffect(() => {
    keyboard.current && keyboard.current.setInput(props.searchProduct);
  }, [props.searchProduct]);

  const handleClickOpenDialog = React.useCallback(
    (item_code) => {
      setOpen(true);
      setOrderLine(item_code);
    },
    [open, orderLine]
  );

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenAlert = (row) => () => {
    setState({ open: true, row: row });
  };
  const handleCloseAlert = () => {
    setState({ open: false });
  };
  const handleOpenTooltip = (row) => async () => {
    await setMessage({ row: row });
    var tabProdectStock = [];
    productsStock?.forEach((element) => {
      const elementProdectStock = element.filter(
        (s) => s.item_code == row?.item_code
      );
      if (elementProdectStock?.length > 0)
        tabProdectStock.push(elementProdectStock);
    });
    if (props.transfer) {
      dispatch(displayProductsStock(tabProdectStock[0]));
    } else {
      dispatch(LoadProductStockById(row.item_code));
    }
  };

  const handleCloseTooltip = (row) => () => {
    setMessage({ row: null });
  };

  function getImgUrl(itemCode) {
    if (imageList && imageList?.itemImages) {
      const IIndex = imageList?.itemImages?.findIndex(
        (e) => e.itemCode === itemCode
      );
      if (IIndex !== -1) {
        return (
          `${process.env.REACT_APP_API_URI}` +
          imageList?.itemImages?.[IIndex]?.imgUrl
        );
      } else {
        return activeImageTheme;
      }
    } else {
      return activeImageTheme;
    }
  }

  useEffect(() => {
    function clickHanlder(e) {
      if (
        e.target.className &&
        typeof e.target.className.includes !== "undefined"
      ) {
        if (
          !(e.target.nodeName == "SPAN") &&
          !e.target.className.includes("hg-button") &&
          !e.target.className.includes("hg-row") &&
          !e.target.className.includes("simple-keyboard")
        ) {
          setKeyboardVisibility(false);
        }
      }
    }

    window.addEventListener("click", clickHanlder);
    return window.removeEventListener("click", clickHanlder, true);
  }, []);

  useEffect(() => {
    var discountedProducts =
      pricingRules && pricingRules.items
        ? pricingRules.items.map((i) => i.item_code)
        : null;
    rows.forEach((element) => {
      if (
        discountedProducts &&
        discountedProducts.includes(element.item_code) &&
        pricingRules?.discount_percentage != 0
      ) {
        HandleUpdateDisc(pricingRules?.discount_percentage, element.item_code);
      } else if (
        discountedProducts &&
        discountedProducts.includes(element.item_code) &&
        pricingRules?.discount_amount != 0
      ) {
        HandleUpdateDiscAmount(
          pricingRules?.discount_amount,
          element.item_code
        );
      }
    });
  }, [pricingRules]);

  const prixTTC = (element) => {
    let taxRate = itemTaxList?.find(
      (s) => s.name == element?.item_tax_template
    )?.tax_rate;
    if (!taxRate) {
      taxRate = 0;
    }
    return element?.price_list_rate
      ? parseInt(element?.qty) *
          parseFloat(
            element?.price_list_rate + (element?.net_rate * taxRate) / 100
          )
      : element?.standard_rate
      ? parseInt(element?.qty) *
        parseFloat(element?.standard_rate + (element?.net_rate * taxRate) / 100)
      : element?.basic_rate
      ? parseInt(element?.qty) *
        parseFloat(element?.basic_rate + (element?.net_rate * taxRate) / 100)
      : 0;
  };

  function isPurchaseOrderTag(row) {
    if (props?.isPurchaseOrder && Object.keys(row).includes("pricing_rules")) {
      return true;
    }
    return false;
  }
  return (
    <React.Fragment>
      <Box className={classes.paper}>
 
        <FormControl variant="outlined" className={classes.formControl}>
          {!props.transfer && !props.stockEntry && !props.addPurchaseAction ? (
            <OutlinedInput
              className={classes.itemInput}
              id="outlined-adornment-weight"
              value={props.searchProduct}
              placeholder={t("checkout:searchItem")}
              onChange={handleSearchProduct}
              onKeyDown={(e) => props.handleEnterKey(e, props.searchProduct)} 
              endAdornment={
                <>
                  <KeyboardIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setKeyboardVisibility(true)}
                  />
                  <InputAdornment position="end">
                    <span className={"icon-scan-code"} />
                  </InputAdornment>
                </>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          ) : (
            !props.isPack && (
              <OutlinedInput
                id="outlined-adornment-weight"
                value={props.searchProduct}
                placeholder={t("checkout:searchItem")}
                onChange={props.handleSearchProduct}
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <span className={"icon-scan-code"} />
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                labelWidth={0}
              />
            )
          )}
        </FormControl>
        <Box display="flex" flexWrap="wrap" alignItems="center">
          {props.suggestion.map((row, index) => (
            <Chip
              disabled={props.transfer && props.selectitem}
              key={index}
              onClick={() => row.item_group =='Services' ? handleAddService(row) : props.handleAddproduct(row.item_name)}
              className={classes.chip}
              avatar={<img src={getImgUrl(row.item_code)} alt={"logo"} />}
              label={row.item_name}
            />
          ))}
          {props.transfer && props.selectitem && (
            <Box
              style={{ width: "100%" }}
              justifyContent="center"
              display="flex"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tablecellHeader}>
                {t("common:produit")}
              </TableCell>
              <TableCell className={classes.tablecellHeader} align="center">
                {t("common:ref")}
              </TableCell>
              <TableCell
                className={clsx(classes.tablecellHeader, classes.qtyControl)}
                align="center"
                width={50}
              >
                {t("qty")}
              </TableCell>
              {!props.transfer && !props.isPurchasePackage && (
                <TableCell className={classes.tablecellHeader} align="center">
                  {props.checkout ? t("prixHT") : t("prix")}
                </TableCell>
              )}
              {props.checkout && (
                <TableCell className={classes.tablecellHeader} align="center">
                  {t("prixTTC")}
                </TableCell>
              )}
              {!props.stockEntry && !props.addPurchaseAction && (
                <TableCell className={classes.tablecellHeader} align="center">
                  {t("remise")}(%)
                </TableCell>
              )}
              {!props.stockEntry && (
                <TableCell
                  className={classes.tablecellHeader}
                  align="center"
                  width={15}
                ></TableCell>
              )}
              <TableCell
                className={classes.tablecellHeader}
                align="center"
                width={15}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {props.rows &&
              props.rows.map((row, index) => (
                <Tooltip
                  title={
                    props.isMessageStock ? (
                      <MessageStock productStock={productStock} />
                    ) : (
                      ""
                    )
                  }
                  onMouseEnter={handleOpenTooltip(row)}
                  onMouseLeave={() => handleCloseTooltip}
                  key={index}
                >
                  <TableRow className={classes.tableRow} key={index}>
                    <TableCell
                      className={clsx(classes.tableCell, classes.noWrap)}
                      scope="row"
                      style={{
                        color:
                          props.checkout &&
                          pricingRules &&
                          row?.discount &&
                          row?.discount !== 0 &&
                          "#006633",
                      }}
                    >
                      {(isFreeItemExistInPOS(row?.item_code) ||
                        isExistInPOS(row?.item_code)) && (
                        <span className={classes.tableRowWithPR}>●</span>
                      )}
                      {row?.item_name}
                    </TableCell>
                    {props.stockEntry || props.addPurchaseAction ? (
                      <TableCell
                        className={clsx(classes.tableCell, classes.noWrap)}
                        align="center"
                      >
                        {row.item_code}
                      </TableCell>
                    ) : (
                      <TableCell
                        className={classes.tableCell}
                        align="center"
                        style={{
                          color:
                            props.checkout &&
                            pricingRules &&
                            row?.discount &&
                            row?.discount !== 0 &&
                            "#006633",
                        }}
                      >
                        {row.name}
                      </TableCell>
                    )}
                    <TableCell
                      className={clsx(classes.tableCell, classes.qtyControl)}
                      align="center"
                    >
                      <Box whiteSpace="nowrap">
                        <Button
                          className={classes.quantityButton}
                          onClick={() => {
                            props.HandleQuantityChange(false, row.item_code);
                          }}
                          onMouseLeave={
                            props.isMessageStock && handleCloseTooltip()
                          }
                        >
                          -
                        </Button>
                        {props.HandleUpdateQuantity ? (
                          <TextField
                            type="number"
                            id="qty-input"
                            className={classes.discInput}
                            value={row?.qty}
                            error={
                              props?.invaliditems &&
                              props?.invaliditems?.findIndex(
                                (item) => item == row?.item_code
                              ) > -1 &&
                              row?.qty <= 0
                            }
                            onChange={(e) =>
                              props.HandleUpdateQuantity(e, row.item_code)
                            }
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0 } }}
                          />
                        ) : (
                          <Typography className={classes.quantityText}>
                            {row.quantity}
                          </Typography>
                        )}

                        <Button
                          color={"primary"}
                          className={classes.quantityButton}
                          disabled={
                            props.handleDisableQuantityButton
                              ? props.handleDisableQuantityButton(row.item_code)
                              : null
                          }
                          onClick={() => {
                            props.HandleQuantityChange(true, row.item_code);
                          }}
                          onMouseLeave={
                            props.isMessageStock && handleCloseTooltip()
                          }
                        >
                          +
                        </Button>
                      </Box>
                    </TableCell>
                    {!props?.transfer && !props?.isPurchasePackage && (
                      <TableCell
                        align="center"
                        className={clsx(classes.tableCell, classes.price)}
                        style={{
                          color:
                            props?.checkout &&
                            pricingRules &&
                            row?.discount &&
                            row?.discount !== 0 &&
                            "#006633",
                        }}
                      >
                        {!props?.addPurchaseAction ? (
                          row?.price_list_rate ? (
                            row?.price_list_rate?.toFixed(3)
                          ) : row?.standard_rate ? (
                            row?.standard_rate?.toFixed(3)
                          ) : row?.basic_rate ? (
                            row?.basic_rate?.toFixed(3)
                          ) : (
                            (0).toFixed(3)
                          )
                        ) : (
                          <TextField
                            type="number"
                            className={classes.prixInput}
                            value={row?.price_list_rate}
                            error={
                              props?.invaliditems &&
                              props?.invaliditems?.findIndex(
                                (item) => item == row?.item_code
                              ) > -1 &&
                              row?.price_list_rate <= 0
                            }
                            onChange={(e) =>
                              props?.HandleUpdatePrice(e, row?.item_code)
                            }
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0 } }}
                            disabled={
                              props?.isPurchaseOrder &&
                              Object.keys(row).includes("pricing_rules")
                            }
                          />
                        )}{" "}
                        {currencySymbol}
                      </TableCell>
                    )}
                    {props.checkout && (
                      <TableCell
                        align="center"
                        className={clsx(classes.tableCell, classes.price)}
                        style={{
                          color:
                            props.checkout &&
                            pricingRules &&
                            row?.discount &&
                            row?.discount !== 0 &&
                            "#006633",
                        }}
                      >
                        {prixTTC(row).toFixed(3)} {currencySymbol}
                      </TableCell>
                    )}
                    {!props.stockEntry && !props.addPurchaseAction && (
                      <TableCell className={classes.tableCell} align="center">
                        <TextField
                          disabled={
                            isExistInPOS(row.item_code) || pricingRules
                              ? true
                              : false
                          }
                          InputProps={{ inputProps: { min: 0 } }}
                          type="number"
                          id="discount-input"
                          className={classes.discInput}
                          value={
                            row?.discount
                              ? Number(row?.discount).toString()
                              : Number(0).toString()
                          }
                          onChange={(e) =>
                            e.target.value < 100 &&
                            HandleUpdateDisc(e, row.item_code)
                          }
                          variant="outlined"
                        />
                      </TableCell>
                    )}
                    {!props.stockEntry && !props.addPurchaseAction && (
                      <TableCell
                        className={tableclasses.tableCell}
                        style={{ background: "#fff" }}
                        align="center"
                        width={25}
                      >
                        <IconButton
                          onClick={() => handleClickOpenDialog(row.item_code)}
                          size="small"
                          color="primary"
                        >
                          <GroupAddIcon
                            className={
                              isChecked(row.item_code) ? classes.avatar : null
                            }
                          />
                        </IconButton>
                      </TableCell>
                    )}

                    {props.addPurchaseAction && (
                      <TableCell
                        className={tableclasses.tableCell}
                        style={{ background: "#fff" }}
                        align="center"
                        width={25}
                      >
                        <IconButton
                          disabled={
                            !(
                              isFreeItemExistInPOS(row.item_code) ||
                              isExistInPOS(row.item_code)
                            )
                          }
                          onClick={() => props.setOpenPricingRules(true)}
                          size="small"
                          color="primary"
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    )}
                    <TableCell
                      className={tableclasses.tableCell}
                      align="center"
                      width={25}
                      style={{ background: "#fff" }}
                    >
                      <IconButton
                        onClick={handleOpenAlert(row)}
                        size="small"
                        color="primary"
                        // disabled={
                        //   props?.isPurchaseOrder &&
                        //   Object.keys(row).includes("pricing_rules")
                        // }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </Tooltip>
              ))}
            {indents}
            {!props.stockEntry &&
              props.initialLines &&
              props.initialLines.map((row, index) => (
                <TableRow className={classes.tableRow} key={index}>
                  <TableCell
                    className={classes.tableCell}
                    scope="row"
                  ></TableCell>
                  <TableCell
                    className={classes.tableCell}
                    align="center"
                  ></TableCell>
                  <TableCell
                    className={classes.tableCell}
                    align="center"
                  ></TableCell>
                  <TableCell
                    className={classes.tableCell}
                    align="center"
                  ></TableCell>
                  {!props.transfer && (
                    <TableCell
                      align="center"
                      className={clsx(classes.tableCell, classes.price)}
                    ></TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!props.transfer && !props.stockEntry && !props.addPurchaseAction && (
          <TotalPrice
            coupon={props.coupon}
            amount={props.amount}
            discount={props.discount}
          />
        )}
      </Box>
      <SimpleDialog open={open} item={orderLine} onClose={handleClose} />
      <Modal hideBackdrop={true} open={keyboardVisibility}>
        <ClickAwayListener onClickAway={() => setKeyboardVisibility(false)}>
          <Box className={classes.virtualKeyboard}>
            <Keyboard
              keyboardRef={(r) => (keyboard.current = r)}
              layoutName={layout}
              layout={french.layout}
              display={{
                "{capslock}": capsLockEnabled ? "🔒" : "🔓",
              }}
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
          </Box>
        </ClickAwayListener>
      </Modal>
      <AlertModal
        setOpenModal={handleCloseAlert}
        openModal={state.open}
        data={state.row}
        HandleDelete={props.HandleDelete}
        handleCancel={handleCloseAlert}
        title={t("common:DeleteCommandeLine")}
      />
      <PricingRules
        isPurchaseOrder={props.isPurchaseOrder}
        openPricingRules={props.openPricingRules}
        setOpenPricingRules={props.setOpenPricingRules}
      />
    </React.Fragment>
  );
};
