import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ProductItem } from "../../../../components/productItem";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector, useDispatch } from "react-redux";
import { GetFiltredProducts } from "../../../../store/product/product.action";
import { useTranslation } from "react-i18next";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ProductFinancierCheckout from "../../../../components/productItem/productFinancier";

const useStyles = makeStyles((theme) => ({
  products: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: 20,
    background: theme.palette.productsTable.background,
    borderRadius: 19,
    "& .MuiInputBase-root": {
      background: theme.palette.productsTable.tableCell,
      marginTop: 20,
    },
    "& .MuiOutlinedInput-input": {
      padding: "7.5px 14px",
      fontSize: 12,
    },
  },
  box: {
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "auto",
  },
  autocomplete: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      minWidth: 200,
    },
    "& .MuiInputLabel-outlined": {
      marginTop: 18,
      fontSize: 16,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.white.main,
      "& input": {
        fontSize: 14,
      },
    },
  },
  container: {
    height: 375,
    display: "flex",
    flexWrap: "wrap",
    overflow: "auto",
    background: theme.palette.productsTable.background,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  productsitem: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: 200,
    },
  },
}));

export const Products = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const allCategories = useSelector((state) => state.Product.categories);
  const products = useSelector((state) => state.Inventory.stockQtyReport);
  const filteredProducts = useSelector(
    (state) => state.Product.filtredProducts
  );
  const { t } = useTranslation(["common", "checkout"]);
  const [selectedTab, setSelectedTab] = useState(0);

  const isInStock = (itemCode) => {
    var data = products.findIndex((p) => p.item_code === itemCode);
    return data !== -1;
  };

  const handleSelectedCategoty = (e, value, reason) => {
    if (value) {
      dispatch(GetFiltredProducts(false, value.name, ""));
    } else {
      dispatch(GetFiltredProducts(false, null, ""));
    }
  };

  useEffect(() => {
    dispatch(GetFiltredProducts(true, null, ""));
  }, [dispatch]);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box className={classes.products}>
      <Box className={classes.box}>
        <div style={{ marginTop: -20, width: "100%" }}>
          <Autocomplete
            id="combo-box-demo"
            options={allCategories}
            onChange={handleSelectedCategoty}
            className={classes.autocomplete}
            popupIcon={<ExpandMoreIcon />}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Catégories..." variant="outlined" />
            )}
          />
        </div>
      </Box>
      <Tabs
        value={selectedTab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Produit" />
        <Tab label="Produit Financier" />
      </Tabs>
      <div className={classes.container}>
        {selectedTab === 0 && Array.isArray(filteredProducts) &&
          filteredProducts.map(
            (p, i) =>
              isInStock(p?.item_code) && (
                <ProductItem
                  key={i}
                  isCheckout={true}
                  initialLines={props.initialLines}
                  GetTotalPrice={props.GetTotalPrice}
                  product={p}
                />
              )
          )}
        {selectedTab === 1 && (
          <ProductFinancierCheckout
            isCheckout={true}
            initialLines={props.initialLines}
            GetTotalPrice={props.GetTotalPrice}
          />
        )}
      </div>
    </Box>
  );
};
