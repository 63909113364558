import React, { useRef } from "react";
import clsx from "clsx";
import { useTableStyles } from "../../../../../styles/table.style";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../../helpers/utils";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Modal,
  OutlinedInput,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import { LoadPricingRuleByName } from "../../../../../store/client/client.action";
import { CadeauComponentToPrint } from "../../../../../components/componentToPrint/carteCadeau";

export const ClientCadeauDetails = () => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const dispatch = useDispatch();
  // const [open, setOpen] = React.useState(false);
  const client = useSelector((state) => state.Client.client);
  const couponCodes = useSelector((state) => state.Client.couponCodes);
  const currencies = useSelector((state) => state.Client.currencies);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const { t } = useTranslation(["common", "clients", "order"]);
  const symbol = (currency) => {
    const x = currencies.find(function (c, i) {
      if (c.name === currency) return true;
    });
    return x?.symbol;
  };

  const currencySymbol =
    client && client.default_currency
      ? symbol(client.default_currency)
      : globalDefaults?.default_currency;
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const HandleClose = () => {
  //   setOpen(false);
  // };

  const [state, setState] = React.useState({
    open: false,
    row: null,
  });

  const handleClickOpen = (row) => () => {
    setState({ open: true, row: row });
    dispatch(LoadPricingRuleByName(row?.pricing_rule));
  };
  const handleClose = () => {
    setState({ open: false });
  };

  const PreviewGiftCard = (
    <div>
      <CadeauComponentToPrint handleClose={handleClose} state={state} />
    </div>
  );
  return (
    <Box>
      <Box className={clsx(classes.root, tableClasses.paper)}>
        <Table className={tableClasses.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:name")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("checkout:couponCode")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("checkout:PricingRule")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("checkout:ValidFrom")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("checkout:ValidUntil")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:statut")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:imprimer")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(couponCodes) &&
              couponCodes
                .filter((s) => s.coupon_type == "Gift Card")
                .map((row, index) => (
                  <>
                    <TableRow className={tableClasses.tableRow} key={index}>
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {row.coupon_name}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {row.coupon_code}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {row.pricing_rule}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {formatDate(row.valid_from, "YYYY-MM-DD")}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {formatDate(row.valid_upto, "YYYY-MM-DD")}
                      </TableCell>
                      <TableCell
                        className={clsx(
                          tableClasses.tableCell,
                          row.used == 0 ? classes.positive : classes.negative
                        )}
                        align="left"
                      >
                        {row.used == 0 ? "activée" : "consommée"}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        <IconButton aria-label="print">
                          <VisibilityIcon
                            color="primary"
                            onClick={handleClickOpen(row)}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
          </TableBody>
        </Table>
      </Box>
      <Modal
        open={state.open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {PreviewGiftCard}
      </Modal>
    </Box>
  );
};
