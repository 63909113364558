

import Box from "@material-ui/core/Box";
 
import { SetNotification } from "../../../../../store/notification/notification.action";
import { Delete } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router';
import { Loader } from "../../../../../components/loader";
import axios from 'axios';
import { useTranslation } from "react-i18next";

import useGetAxios from "../../../../initialization/useGetAxios";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import AddBoxIcon from "@mui/icons-material/AddBox";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  TextField,
  Collapse,
  LinearProgress,
  FormControl,
  InputAdornment,
  Link,
  Select,
  Modal,
  OutlinedInput,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { Button } from "@material-ui/core";
import {DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import useGetDoc from "../../../../../hooks/useGetDoc";
import React, {useEffect, useState} from "react";
import {formatDate, timeout} from "../../../../../helpers/utils";
import {fr} from "date-fns/esm/locale";
import DateFnsUtils from "@date-io/date-fns";
import {SpaceBar} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import AddItem from "../../../../../components/addItem/AddItem";
const  CreateRetentionStockEntry = ({setCloseModal, id}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation(["buying", "common"]);
    const[supplierList, setSupplierList] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const[itemList, setItemList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [CurrencyList, setCurrencyList] = useState([]);
    const [taxList, setTaxList] = useState([]);
    const [supplierSelected, setSupplierSelected] = useState('');
    const [companySelected, setCompanySelected] = useState('');
    const [quotationNo, setQuotationNo] = useState('');
    const [addressList, setAddressList] = useState([]);
    const [addressSelected, setAddressSelected] = useState('');
     const [contactSelected, setContactSelected] = useState('');
    const [CurrencySelected, setCurrencySelected] = useState('');
      const [priceSelected, setPriceSelected] = useState('');
    const [taxCategorySelected, setTaxCategorySelected] = useState('');
     const [purchaseTaxTemplateSelected, setPurchaseTaxTemplateSelected] = useState('');
    const [title,setTitle] = useState('')
    const [warehouseList, setWarehouseList] = useState([]);
    const [warehouseTargetSelected, setWarehouseTargetSelected] = useState('');
     const [scannedCode, setScannedCode] = useState('');
      const [docReturnData,setReturnData] = useState([])
 
    const [error, setError] = useState({
        supplier: false,
        scheduleDate: false,
        scheduleDateTill: false,
        items: false,
        taxes: false,
    });
    const [scheduleDate, setScheduleDate] = useState(
        formatDate(new Date(), "YYYY-MM-DD")
    );
    const [scheduleDateTill, setScheduleDateTill] = useState(
        formatDate(new Date(), "YYYY-MM-DD")
    );
    const [state, setState] = useState({
        items: [],
        taxes: []
        
    });
    
    
    const handleQuantityChange = (index, value) => {
        const updatedItems = [...state.items];
        updatedItems[index].qty = value;
        setState({ ...state, items: updatedItems });
        
      };
      const handleDescriptionChange = (index, value) => {
        const updatedItems = [...state.taxes];
        updatedItems[index].description = value;
        setState({ ...state, taxes: updatedItems });
        
      };
      const handleAmountChange = (index, value) => {
        const updatedItems = [...state.taxes];
        updatedItems[index].amount = value;
        setState({ ...state, taxes: updatedItems });
        
      };
      const handleWarehouseTargetChange = (index, value) => {
        const updatedItems = [...state.items];
        updatedItems[index].warehouse_target = value;
        setState({ ...state, items: updatedItems });
        
      };
      const handleWarehouseSourceChange = (index, value) => {
        const updatedItems = [...state.items];
        updatedItems[index].warehouse_source = value;
        setState({ ...state, items: updatedItems });
        
      };
    
      const handleDeleteItem = (index) => {
        const updatedItems = [...state.items];
        updatedItems.splice(index, 1);
        setState({ ...state, items: updatedItems });
      
      };

      const handleDeleteTaxes = (index) => {
        const updatedTaxes = [...state.taxes];
        updatedTaxes.splice(index, 1);
        setState({ ...state, taxes: updatedTaxes });
      };
    
      useEffect(() => {
         setLoading(true);
    
        const makeMappedDoc = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URI}/api/make-mapped-doc`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                        Authorization: JSON.parse(localStorage.getItem("user")).token,
                    },
                    body: JSON.stringify({
                        method: "erpnext.stock.doctype.purchase_receipt.purchase_receipt.make_stock_entry",
                        source_name: id.name
                    }),
                });
    
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
    
                const data = await response.json(); 
                setReturnData(data['message']);
               const  responseDoc = data['message']; 
                           setTitle(responseDoc.supplier);
                           setSupplierSelected(responseDoc.supplier);
                           setCompanySelected(responseDoc.company);
                           setCurrencySelected(responseDoc.currency);
                           setPriceSelected(responseDoc.price_list);
                           setPurchaseTaxTemplateSelected(responseDoc.purchase_tax_template);
                           setTaxCategorySelected(responseDoc.tax_category); 
                           setPurchaseTaxTemplateSelected(responseDoc.taxes_and_charges);  
                           setPriceSelected(responseDoc.buying_price_list);                  
                           setAddressSelected(responseDoc.supplier_address);
                           setContactSelected(responseDoc.contact_person);
                            
                            
                           responseDoc['taxes'].map((item)=>{
                              state.taxes.push(item) 
                             
                           })
                           //setTaxCategorySelected(item.tax_category);
                           responseDoc['items'].map((item)=>{
                               state.items.push({...item,qty:item.qty})
                             
                               
                           })
              
            } catch (error) {
                console.error("Error:", error);
            } finally {
               setLoading(false);
            }
        };
    
        makeMappedDoc();
    }, []); 
     
    console.log("company see",companySelected);
    const getItemList = () => { 
            fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
                method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                        Authorization: JSON.parse(localStorage.getItem("user")).token,
                    },
                body: JSON.stringify({
                    doctype: "Item",
                    fields: [ "`tabItem`.`name`",
                    "`tabItem`.`description`",
                    "`tabItem`.`item_group`",
                    "`tabItem`.`item_collection`",
                    "`tabItem`.`item_name`",
                    "`tabItem`.`standard_rate`",
                    "`tabItem`.`opening_stock`",

                    "`tabItem`.`image`",
                    "`tabItem`.`net_amount`",
                    "`tabItem`.`net_rate`",
                    "`tabItem`.`price_list_rate`",
                    "`tabItem`.`discount_amount`",
                    "`tabItem Tax`.`item_tax_template`",
                    "`tabItem`.`is_stock_item`",
                    "`tabItem`.`has_variants`",
                    "`tabItem`.`default_material_request_type`",
                    "`tabItem`.`net_rate`",
                    "`tabItem`.`standard_rate`",
                    "`tabItem`.`item_code`",
                    "`tabItem`.`description`",
                    "`tabItem`.`item_group`",
                    "`tabItem`.`total_projected_qty`",
                    
                ],
                filters: [["Item Default","company","=",`${companySelected}`]]
          //    filters: [["Item","retain_sample","=",1],["Item Default","company","=","Vndit Company Test"]]
                }),
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (Array.isArray(data)) {
                         
                        setItemList(data);
                        console.log('datadata',data);
                    }
                });
        
             
    };
    const getTaxList = () => { 
            fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                    Authorization: JSON.parse(localStorage.getItem("user")).token,
                },
                body: JSON.stringify({
                    doctype: "Account",
                    fields: ["`tabAccount`.`name`", "`tabAccount`.`owner`", "`tabAccount`.`creation`", "`tabAccount`.`modified`", "`tabAccount`.`modified_by`", "`tabAccount`.`_user_tags`", "`tabAccount`.`_comments`", "`tabAccount`.`_assign`", "`tabAccount`.`_liked_by`", "`tabAccount`.`docstatus`", "`tabAccount`.`parent`", "`tabAccount`.`parenttype`", "`tabAccount`.`parentfield`", "`tabAccount`.`idx`", "`tabAccount`.`account_name`", "`tabAccount`.`account_number`", "`tabAccount`.`disabled`", "`tabAccount`.`account_type`","`tabAccount`.`tax_rate`"],
                    filters: [["Account","company","=",`${companySelected}`],["Account","account_type","=","Tax"]]
                }),
            })
            .then((res) => res.json())
            .then((data) => {
                if (Array.isArray(data)) {
                    setTaxList(data);
                   
                }
            });
        
    };
 
     
   
  
  
   
    const getWarehouseList = () => {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                    Authorization: JSON.parse(localStorage.getItem("user")).token,
                },
            body: JSON.stringify({
                "doctype": "Warehouse",
                "fields": ["`tabWarehouse`.`name`","`tabWarehouse`.`owner`","`tabWarehouse`.`creation`","`tabWarehouse`.`modified`","`tabWarehouse`.`modified_by`","`tabWarehouse`.`_user_tags`","`tabWarehouse`.`_comments`","`tabWarehouse`.`_assign`","`tabWarehouse`.`_liked_by`","`tabWarehouse`.`docstatus`","`tabWarehouse`.`parent`","`tabWarehouse`.`parenttype`","`tabWarehouse`.`parentfield`","`tabWarehouse`.`idx`","`tabWarehouse`.`is_group`","`tabWarehouse`.`company`","`tabWarehouse`.`disabled`","`tabWarehouse`.`city`","`tabWarehouse`.`warehouse_name`"],
                "filters": [["Warehouse","company","=",companySelected]]

            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    setWarehouseList(data);
                }
            });
    };
    
    const handleSaveQuotation = () => {
        setLoadingButton(true);
        const currentDate = new Date();
const currentTime = currentDate.toISOString().split('T')[1].slice(0, 12); // Extract time in HH:mm:ss format

         const data = {
            "doc": { 
          "idx": 0,
          "docstatus": 0,
          "naming_series": "MAT-STE-.YYYY.-",
          "stock_entry_type": "Material Transfer",
          "purpose": "Material Transfer",
          "posting_date": currentDate.toISOString().split('T')[0],
  "posting_time": currentTime,
          "add_to_transit": 0,
          "company": companySelected, 
          "set_posting_time": 0,
          "inspection_required": 0,
          "from_bom": 0,
          "apply_putaway_rule": 0,
          "fg_completed_qty": 0,
          "use_multi_level_bom": 1,
          "total_outgoing_value": 0,
          "total_incoming_value": 0,
          "value_difference": 0,
          "total_additional_costs": 0,
          "address_display": null,
          "letter_head": "xxxxxxxx",
          "is_opening": "No",
          "per_transferred": 0,
          "total_amount": 0,
          "is_return": 0,
          "doctype": "Stock Entry",
          "items": state.items.map((item) => {
            return {
                "parentfield": "items",
                "parenttype": "Stock Entry",
                "idx": 1,
                "docstatus": 0, 
                "s_warehouse": item.warehouse_source,
                "item_code":  item.item_code,
                "item_name": item.item_name,
                "is_finished_item": 0,
                "is_scrap_item": 0,
                "is_process_loss": 0,
                "description": item.description,
                "item_group":  item.item_group,
                "qty": item.qty,
                "transfer_qty": item.qty,
                "retain_sample": 0,
                "uom": "Nos",
                "stock_uom": "Nos",
                "conversion_factor": 1,
                // "sample_quantity": 0,
                // "basic_rate": 0,
                // "additional_cost": 0,
                // "valuation_rate": 0,
                // "allow_zero_valuation_rate": 0,
                // "set_basic_rate_manually": 0,
                // "basic_amount": 0,
                // "amount": 0, 
                // "transferred_qty": 0,
                // "allow_alternative_item": 0,
                "reference_purchase_receipt": id.name,
                "doctype": "Stock Entry Detail",
                "__islocal": 1,
                "__unsaved": 1,
                "parent": "new-stock-entry-5",
                "name": "new-stock-entry-detail-8",
                "t_warehouse": item.warehouse_target
              
            }
          }),
          "additional_costs": state.taxes.map((tax) => {
            return {
                "docstatus": 0,
                "doctype": "Landed Cost Taxes and Charges",
                "name": "new-landed-cost-taxes-and-charges-1",
                "__islocal": 1,
                "__unsaved": 1,
               
               // "account_currency": "TND",
                "parent": "new-stock-entry-1",
                "parentfield": "additional_costs",
                "parenttype": "Stock Entry",
                "idx": 1,
                "__unedited": false,
                "expense_account":  tax.name,
                "exchange_rate": 1,
                "description":  tax.description,
                "amount":  tax.amount,
                "base_amount":  tax.amount,
            }
            }),
          "__islocal": 1,
          "__onload": {
            "load_after_mapping": true
          },
          "__unsaved": 1,
          "name": "new-stock-entry-5", 
          "purchase_receipt_no": null,
          "supplier_address": null,
          "supplier_name": null,
          "supplier": null,
          "sales_invoice_no": null,
          "delivery_note_no": null,
          "customer_address": null,
          "customer_name": null,
          "customer": null
        },
           "action": "Submit",
        }
          
         fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
           method: "POST",
           headers: {
             Accept: "application/json",
             "Content-Type": "application/json",
             "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
             Authorization: JSON.parse(localStorage.getItem("user"))?.token,
           },
           body: JSON.stringify(data),
         })
           .then((res) => {
             setLoadingButton(false);
           
             if (!res.ok) {
               dispatch(
                 SetNotification({ code: "error", message: "Something went wrong" })
               );
             }
             return res.json();
           })
           .then((data) => {
             if (data?.success === false) {
                 setLoadingButton(false);
               dispatch(
                 SetNotification({ code: "warning", message: data?.message })
               );
               
             } else {
                 setLoadingButton(false);
               dispatch(
                 SetNotification({
                   code: "success",
                   message: `Make Stock Entry Created successfully`,
                 })
               );
               
               handleCancel();
               handleReload();                
             }
           });
       }; 

      const  handleReload = () => {
        window.location.reload();
       }
    useEffect(() => {
         
            getItemList();
            getWarehouseList();     
            getTaxList();
        
           
        
    },[companySelected]);  
   
    
    const handleChangeSelectmultiple = (event) => {
        setError({ ...error, [event.target.name]: false });
        setState({ ...state, [event.target.name]: event.target.value });
         
    };
    
    const handleCancel = () => {
        setCloseModal(); // Call setCloseModal to close the modal
      };
      if (loading) {
        return <Loader />; // Render loader while data is being fetched
      }
      return (
        <Card
      sx={{
        boxShadow: 20,
        marginLeft: "-20px",
        marginRight: "-20px",
        marginTop: "-5px",
        marginBottom: "-12px",
      }}
    >
      <div style={{ margin: "15px" }}>
        <Grid item xs={12} style={{ marginTop: 20, fontWeight: "bold" }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            style={{ fontSize: "1.25rem", fontWeight: 800 }}
          >
            {/* <AddBoxIcon sx={{ mr: 1 }} style={{ color: "#0b68ff" }} /> */}
            <div style={{ color: "#2F4B7C" }}>
            {t("common:PurchaseReceiptId")}: {id.name}
            </div>
          </Box>
        </Grid>
      </div>
      <Divider orientation="horizontal" fullWidth />

      <div style={{ marginLeft: "40px" }}>
        <Grid
          container
          spacing={5}
          style={{ paddingLeft: "3%", marginTop: "15px" }}
        >
          <Grid item xs={6}>
            <AddItem
              sx={{ width: "68%" }}
              fullwidth
              label={t("common:Items")}
              name="items"
              value={state.items}
              error={error.items}
              propToRender={"item_name"}
              handleChange={handleChangeSelectmultiple}
              defaultItem={""}
              data={itemList}
            />
          </Grid>

          {/* <Grid item xs={6}>
        <FormControl fullWidth style={{ marginTop: "25px" }}>
          <InputLabel
            id="demo-simple-select-label"
            style={{ fontSize: "16px" }}
          >
            {t("common:SetTargetWarehouse")}
          </InputLabel>
          <Select
            labelId="select-label-warehouse"
            style={{ width: "80%" }}
            className="CuFormInput"
            id="simple-select"
            value={warehouseSelected}
            label={t("common:SetTargetWarehouse")}
            onChange={(e) => {
              setWarehouseSelected(e.target.value);
            }}
          >
            {warehouseList?.map((item) => (
              <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                {item?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid> */}

          {state.items.length > 0 ? (
            <Grid item xs={11}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {t("common:Items")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {t("common:SourceWarehouse")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {t("common:TargetWarehouse")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {t("common:ReceivedQuantity")}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    
                    {state.items.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.item_name}</TableCell>
                        <TableCell>
                          <Select
                            className="CuFormInput"
                            style={{ width: "100%" }}
                            labelId="select-label-item"
                            id="`target-select-item-${index}`"
                            value={item.warehouse_source}
                            // label="Item"
                            onChange={(e) =>
                              handleWarehouseSourceChange(index, e.target.value)
                            }
                          >
                            {warehouseList?.map((item) => (
                              <MenuItem value={item?.name}>
                                {item?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <Select
                            className="CuFormInput"
                            style={{ width: "100%" }}
                            labelId="select-label-item"
                            id="`source-select-item-${index}`"
                            value={item.warehouse_target}
                            // label={t("common:Items")}
                            onChange={(e) =>
                              handleWarehouseTargetChange(index, e.target.value)
                            }
                          >
                            {warehouseList?.map((item) => (
                              <MenuItem value={item?.name}>
                                {item?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <TextField
                            className="CuFormInput"
                            style={{ width: "100%" }}
                            required
                            type="number"
                            value={item.qty}
                            onChange={(e) =>
                              handleQuantityChange(index, e.target.value)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleDeleteItem(index)}>
                            <Delete color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : null}

          <Grid item xs={6}>
            <AddItem
              sx={{ width: "68%" }}
              fullwidth
              label={t("common:AdditionalCosts")}
              name="taxes"
              value={state.taxes}
              error={error.taxes}
              propToRender={"name"}
              handleChange={handleChangeSelectmultiple}
              defaultItem={""}
              data={taxList}
            />
          </Grid>

          {state.taxes.length > 0 ? (
            <Grid item xs={11}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {t("common:ExpenseAccount")}{" "}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {t("common:Description")}{" "}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {t("common:Amount")}{" "}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {state.taxes.map((tax, index) => (
                      <TableRow key={index}>
                        <TableCell >{tax.name}</TableCell>
                        <TableCell>
                          <TextField
                            className="CuFormInput"
                            style={{ width: "100%" }}
                            value={tax.description}
                            onChange={(e) =>
                              handleDescriptionChange(index, e.target.value)
                            }
                          />
                        </TableCell>

                        <TableCell>
                          <TextField
                            className="CuFormInput"
                            style={{ width: "100%" }}
                            required
                            type="number"
                            value={tax.amount}
                            onChange={(e) =>
                              handleAmountChange(index, e.target.value)
                            }
                          />
                        </TableCell>

                        <TableCell>
                          <IconButton onClick={() => handleDeleteTaxes(index)}>
                            <Delete color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : null}



                  {/* Bouttons */}

          <Grid item xs={12} style={{ margin: "20px", marginRight: "15%" }}>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                size="large"
                disabled={loadingButton}
                color="primary"
                variant="contained"
                onClick={handleSaveQuotation}
                style={{ marginRight: "16px" }} // Add margin to separate buttons
              >
                {t("common:demender")}
              </Button>
              <Button
                size="large"
                color="error"
                variant="contained"
                onClick={handleCancel}
              >
                {t("common:annuler")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Card>


    )
   
     
}

export default CreateRetentionStockEntry