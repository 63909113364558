import React from "react";
import { TableRow, TableCell, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const FreeItemTableBody = ({
  products,
  classes,
  currencySymbol,
  ignorePricingRule,
  isPricingRule,
}) => {
  const { t } = useTranslation(["payement", "common"]);
  const selectCurrency = useSelector((state) => state.Checkout.selectCurrency);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const itemTaxList = useSelector((state) => state.Product.itemTax);
  const tvaTaxes = (product) => {
    const data =
      selectCurrency != globalDefaults?.default_currency
        ? product?.item_tax_template &&
          product?.item_tax_template.slice(
            8,
            product?.item_tax_template.indexOf("%") + 1
          ) +
            ": " +
            (
              (product?.net_amount *
                parseFloat(
                  product?.item_tax_template
                    .match(/\d+/g)
                    .toString()
                    .replace(",", ".")
                ).toFixed(3)) /
              100
            ).toFixed(3)
        : product?.item_tax_template &&
          product?.item_tax_template.slice(
            8,
            product?.item_tax_template.indexOf("%") + 1
          ) +
            ": " +
            (
              (product?.net_amount *
                parseFloat(
                  product?.item_tax_template
                    .match(/\d+/g)
                    .toString()
                    .replace(",", ".")
                ).toFixed(3)) /
              100
            ).toFixed(3);
    return data;
  };

  const prixTTC = (element) => {
    let taxRate = itemTaxList.find(
      (s) => s.name == element.item_tax_template
    )?.tax_rate;
    if (!taxRate) {
      taxRate = 0;
    }
    return element.price_list_rate
      ? parseFloat(element.price_list_rate) +
          (parseFloat(element.price_list_rate) * parseFloat(taxRate)) / 100
      : element.standard_rate
      ? parseFloat(element.standard_rate) +
        (parseFloat(element.standard_rate) * parseFloat(taxRate)) / 100
      : parseFloat(element.base_rate) +
        (parseFloat(element.base_rate) * parseFloat(taxRate)) / 100;
  };

  const discountAmount = (element) => {
    return (element.price_list_rate * element.discount) / 100;
  };

  const discountPercentage = (element) => {
    return (element.discount_amount * 100) / element.price_list_rate;
  };

  const processPricingRules = (pricingRules) => {
    if (typeof pricingRules === "string") {
      try {
        const parsedValue = JSON.parse(pricingRules);
        if (Array.isArray(parsedValue)) {
          return parsedValue;
        } else {
          return "Not an array";
        }
      } catch (error) {
        return pricingRules;
      }
    } else {
      return "Not a string";
    }
  };

  return (
    <>
      {products?.map((product) => (
        <TableRow key={product?.item_name} className={classes.tableRow}>
          <TableCell className={classes.tableCell}>
            <Box
              fontWeight={"bold"}
              fontFamily={"Courier Prime"}
              color={"#707070"}
            >
              {product?.quantity || product?.qty}X {product?.item_name}{" "}
              {product?.item_code}{" "}
              {isPricingRule
                ? "(" + processPricingRules(product?.pricing_rules)? processPricingRules(product?.pricing_rules):"" + ")"
                : null}
            </Box>
            <Box fontFamily={"Courier Prime"} color={"#707070"}>
              P.U: {prixTTC(product)?.toFixed(3)} {currencySymbol}
            </Box>
            {product.discount ? (
              <Box
                fontSize={"12px"}
                fontFamily={"Courier Prime"}
                color={"#707070"}
              >
                {t("common:remise")}: {product.discount}% (
                {discountAmount(product)?.toFixed(3)} {currencySymbol})
              </Box>
            ) : null}

            {!ignorePricingRule && product.discount_amount ? (
              <Box
                fontSize={"12px"}
                fontFamily={"Courier Prime"}
                color={"#707070"}
              >
                {t("common:remise")}: {discountPercentage(product).toFixed(3)}%
                ( {product.discount_amount.toFixed(3)} {currencySymbol})
              </Box>
            ) : null}
            {product.item_tax_template ? (
              <Box
                fontSize={"12px"}
                fontFamily={"Courier Prime"}
                color={"#707070"}
              >
                {tvaTaxes(product)}
              </Box>
            ) : null}
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Box
              align={"right"}
              bottom={"0"}
              fontFamily={"Courier Prime"}
              color={"#707070"}
            >
              {(
                parseFloat(product.qty || product.quantity) * prixTTC(product)
              ).toFixed(3)}{" "}
              {currencySymbol}
            </Box>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
