import React, { useState } from "react";
import {
  Paper,
  Typography,
  Modal,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  OutlinedInput,
} from "@material-ui/core";
import { useStyles } from "../../style";
import { useTableStyles } from "../../../../styles/table.style";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  LoadQuoRepById,
  SetQuoReqDetails,
  MakeSuppQuotation,
  SetSuppQuotationDoc,
  CreateSuppQuotation,
} from "../../../../store/buying/buying.action";
import { Loader } from "../../../../components/loader";

export const QuoReqTable = (props) => {
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "buying"]);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);

  const quotationReqDetails = useSelector(
    (state) => state.Buying.quotationReqDetails
  );
  const supplierQuoDoc = useSelector((state) => state.Buying.supplierQuoDoc);

  const handleCloseModal = () => {
    setOpenDetailsModal(false);
    dispatch(SetQuoReqDetails(null));
    dispatch(SetSuppQuotationDoc(null));
  };

  const handleQuoReqDetails = (id) => {
    dispatch(LoadQuoRepById(id));
    setOpenDetailsModal(true);
  };

  const handleMakeSuppQuotation = (id, supplier) => {
    dispatch(MakeSuppQuotation(id, supplier));
  };

  const handleRateChange = (e, id) => {
    const ItemIndex = supplierQuoDoc.items.findIndex((s) => s.item_code == id);
    const data = [...supplierQuoDoc.items];

    let taxRate = Object.values(JSON.parse(data[ItemIndex].item_tax_rate))[0];
    if (!taxRate) {
      taxRate = 0;
    }

    if (ItemIndex != -1 && !isNaN(e.target.value)) {
      data[ItemIndex].net_rate = parseFloat(e.target.value);
      data[ItemIndex].rate =
        parseFloat(e.target.value) +
        (parseFloat(e.target.value) * taxRate) / 100;
      data[ItemIndex].amount = data[ItemIndex].rate * data[ItemIndex].qty;
      data[ItemIndex].net_amount =
        data[ItemIndex].net_rate * data[ItemIndex].qty;
    }
    const total = data.reduce((a, v) => (a = a + parseFloat(v.net_amount)), 0);
    const grand_total = data.reduce(
      (a, v) => (a = a + parseFloat(v.amount)),
      0
    );
    const total_taxes_and_charges = grand_total - total;

    dispatch(
      SetSuppQuotationDoc({
        ...supplierQuoDoc,
        items: data,
        total: total,
        grand_total: grand_total,
        total_taxes_and_charges: total_taxes_and_charges,
      })
    );
  };

  const handleSubmitDoc = () => {
    dispatch(CreateSuppQuotation(supplierQuoDoc));
    handleCloseModal();
  };

  const quotationDetails = (
    <div className={classes.paper}>
      <Box className={classes.paperr}>
        <Typography align={"center"} color={"primary"}>
          {t("buying:RequestQuotation")}: {quotationReqDetails?.name}
        </Typography>
        <Box mt={3}>
          <Box>
            <Paper className={classes.paperr} style={{ marginBottom: 10 }}>
              <Typography align={"left"} color={"primary"}>
                {t("common:AddBy")}: {quotationReqDetails?.owner}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {t("common:mgsCible")}:{" "}
                {quotationReqDetails?.items[0]?.warehouse}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {t("buying:RequiredOn")}:{" "}
                {quotationReqDetails?.items[0]?.schedule_date}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {t("buying:SupplierName")}:{" "}
                {quotationReqDetails?.suppliers[0]?.supplier_name}
              </Typography>

              {quotationReqDetails?.message_for_supplier.length > 355 ? (
                <Box
                  sx={{
                    height: 300,
                  }}
                >
                  <Paper
                    elevation={0}
                    style={{ maxHeight: "100%", overflow: "auto" }}
                  >
                    {" "}
                    <Typography align={"left"} color={"primary"}>
                      {t("buying:MsgSupplier")}:{" "}
                      {quotationReqDetails?.message_for_supplier}
                    </Typography>
                  </Paper>
                </Box>
              ) : (
                <Typography align={"left"} color={"primary"}>
                  {t("buying:MsgSupplier")}:{" "}
                  {quotationReqDetails?.message_for_supplier}
                </Typography>
              )}
            </Paper>
          </Box>
          <Box mt={2}>
            <Typography
              style={{ marginBottom: -5 }}
              align={"left"}
              color={"primary"}
            >
              {t("common:article")}:
            </Typography>
            <Table className={tableclasses.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className={tableclasses.tablecellHeader}
                    align="left"
                  >
                    {t("common:produit")}
                  </TableCell>
                  <TableCell
                    className={tableclasses.tablecellHeader}
                    align="left"
                  >
                    {t("common:itemCode")}
                  </TableCell>
                  <TableCell
                    className={tableclasses.tablecellHeader}
                    align="left"
                  >
                    {t("buying:RequiredOn")}
                  </TableCell>
                  <TableCell
                    className={tableclasses.tablecellHeader}
                    align="left"
                  >
                    {t("common:qty")}
                  </TableCell>
                  {supplierQuoDoc && (
                    <>
                      <TableCell
                        className={tableclasses.tablecellHeader}
                        align="left"
                      >
                        {t("common:tax")}
                      </TableCell>
                      <TableCell
                        className={tableclasses.tablecellHeader}
                        align="center"
                      >
                        {t("common:taux")}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {quotationReqDetails?.items.map((row, i) => (
                  <TableRow className={tableclasses.tableRow} key={i}>
                    <TableCell className={tableclasses.tableCell} align="left">
                      {row.item_name}
                    </TableCell>
                    <TableCell className={tableclasses.tableCell} align="left">
                      {row.item_code}
                    </TableCell>
                    <TableCell className={tableclasses.tableCell} align="left">
                      {row.schedule_date}
                    </TableCell>
                    <TableCell className={tableclasses.tableCell} align="left">
                      {row.qty}
                    </TableCell>
                    {!supplierQuoDoc && (
                      <>
                        <TableCell
                          className={tableclasses.tableCell}
                          align="left"
                        >
                          {
                            supplierQuoDoc?.items?.find(
                              (p, i) => p.item_code == row.item_code
                            )?.item_tax_template
                          }
                        </TableCell>
                        <TableCell
                          className={tableclasses.tableCell}
                          align="center"
                        >
                          {/* <OutlinedInput
                            style={{ fontSize: 12 }}
                            autoComplete="off"
                            className={classes.formInput}
                            placeholder={supplierQuoDoc?.currency}
                            value={supplierQuoDoc?.items?.find((p, i) => p.item_code == row.item_code)?.net_rate}
                            onChange={(e) => handleRateChange(e, row.item_code)}
                            name="rate"
                          /> */}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {!supplierQuoDoc && (
              <Paper className={classes.paperr} style={{ marginBottom: 10 }}>
                <Typography align={"left"} color={"primary"}>
                  {t("common:qtyTotal")}: {supplierQuoDoc?.total_qty}
                </Typography>
                <Typography align={"left"} color={"primary"}>
                  {t("common:totalHT")}: {supplierQuoDoc?.total?.toFixed(3)}{" "}
                  {supplierQuoDoc?.currency}
                </Typography>
                <Typography align={"left"} color={"primary"}>
                  {t("common:Taxation")}:{" "}
                  {(
                    supplierQuoDoc?.grand_total - supplierQuoDoc?.total
                  )?.toFixed(3)}{" "}
                  {supplierQuoDoc?.currency}
                </Typography>
                <Typography align={"left"} color={"primary"}>
                  {t("common:grandTotal")}:{" "}
                  {supplierQuoDoc?.grand_total?.toFixed(3)}{" "}
                  {supplierQuoDoc?.currency}
                </Typography>
              </Paper>
            )}
          </Box>
        </Box>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          {supplierQuoDoc && (
            <Button
              style={{ marginTop: 20, alignContent: "center", marginRight: 10 }}
              variant="contained"
              color="primary"
              onClick={handleSubmitDoc}
            >
              {t("common:valider")}
            </Button>
          )}
          {/* {!supplierQuoDoc && (<Button style={{marginTop: 20, alignContent: "center", marginRight: 10}} variant="contained" color="primary" onClick={() => handleMakeSuppQuotation(quotationReqDetails?.name, quotationReqDetails?.suppliers[0]?.supplier_name)}>Faire un devis Fournisseur</Button>)} */}
          <Button
            style={{ marginTop: 20, alignContent: "center" }}
            variant="outlined"
            color="primary"
            onClick={handleCloseModal}
          >
            {t("common:fermer")}
          </Button>
        </Box>
      </Box>
    </div>
  );

  return (
    <Box className={tableclasses.paper}>
      <Table className={tableclasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:ref")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:createDate")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:AddBy")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("buying:MsgSupplier")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.quotationReqList &&
            Array.isArray(props.quotationReqList) &&
            props.quotationReqList.map((row, index) => (
              <TableRow className={tableclasses.tableRow} key={index}>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row.name}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row.transaction_date}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row.owner}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row.message_for_supplier.length > 142 ? (
                    <>
                      {row.message_for_supplier?.substring(0, 142)}{" "}
                      <Button
                        variant="text"
                        size="small"
                        style={{
                          fontSize: "10px",
                          textDecoration: "underline",
                          color: "blue",
                        }}
                      >
                        ...voir plus
                      </Button>
                    </>
                  ) : (
                    row.message_for_supplier
                  )}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="right">
                  <Button
                    size={"small"}
                    variant="outlined"
                    color="primary"
                    onClick={() => handleQuoReqDetails(row.name)}
                  >
                    {t("common:details")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Modal
        open={openDetailsModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {quotationReqDetails ? (
          quotationDetails
        ) : (
          <div className={classes.paper}>
            <Loader />
          </div>
        )}
      </Modal>
    </Box>
  );
};
