import React, { useRef, useState } from "react";
import { useStyles } from "../../style";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { formatDate, symbol } from "../../../../helpers/utils";
import { Button, Typography, Box, Badge } from "@material-ui/core";
import { SetNotification } from "../../../../store/notification/notification.action";
import ReactToPrint from "react-to-print";
import { AvoirComponentToPrint } from "../../../../components/componentToPrint/avoir";
import { SetAvoirToPrint } from "../../../../store/checkout/checkout.action";
import { Voucher } from "../../../../components/componentToPrint/avoir/voucher";

export const PriceResult = () => {
  const classes = useStyles();
  const { t } = useTranslation(["payement", "common"]);
  const dispatch = useDispatch();
  const doc2Print = useRef();

  const avoir2Print = useSelector((state) => state.Checkout.avoir2Print);
  const selectedCustomer = useSelector((state) => state.Checkout.client);
  const amount = useSelector((state) => state.Checkout.amount);
  const discount = useSelector((state) => state.Checkout.discount);
  const order = useSelector((state) => state.Checkout.order);
  const payments = useSelector((state) => state.Checkout.amount.payments);
  const appliedLoyaltyCard = useSelector(
    (state) => state.Cart.appliedLoyaltyCard
  );
  const client = useSelector((state) => state.Checkout.client);
  const currencies = useSelector((state) => state.Client.currencies);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const taxsales = useSelector((state) => state.Checkout.taxsales);

  const [isPending, setIsPending] = useState(false);

  const taxsalesvalue =
    taxsales && !isNaN(taxsales?.value) ? parseFloat(taxsales?.value) : 0;
  const totalAmount = amount.totalAmount
    ? (
        amount.totalAmount -
        (discount * amount.totalAmount) / 100 +
        taxsalesvalue
      ).toFixed(3)
    : "0.000";
  const totalAmountPayed = payments
    ? payments.reduce((a, v) => (a = a + parseFloat(v.amount)), 0)
    : 0;
  const currencySymbol =
    client && client?.default_currency
      ? symbol(client?.default_currency, currencies)?.symbol
      : symbol(globalDefaults?.default_currency, currencies)?.symbol;

  function getAmount2Return() {
    if (amount.totalAmount && parseFloat(totalAmountPayed - totalAmount) <= 0) {
      return 0.0;
    } else {
      return parseFloat(totalAmountPayed - totalAmount);
    }
  }

  const makeAvoir = async () => {
    setIsPending(true);
    const data = {
      doc: {
        credit_amount: getAmount2Return(),
        currency: globalDefaults?.default_currency,
        naming_series: "CUS-AVR-.YYYY.-",
        creation_date: formatDate(Date.now(), "YYYY-MM-DD"),
        customer: selectedCustomer.customer_name,
        company: globalDefaults?.default_company,
        return_against: null,
        amended_from: null,
        doctype: "Avoir Account",
      },
      action: "Submit",
    };
    fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user"))?.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        setIsPending(false);
        if (!res.ok) {
          dispatch(
            SetNotification({ code: "error", message: "Something went wrong" })
          );
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === false) {
          dispatch(
            SetNotification({ code: "warning", message: data?.message })
          );
        } else if (data?.docs?.[0]) {
          dispatch(SetAvoirToPrint(data?.docs?.[0]));
          dispatch(
            SetNotification({
              code: "success",
              message: `Avoir a été généré avec succès.`,
            })
          );
        }
      });
  };

  return (
    <Box
      textAlign="center"
      style={{
        margin: 20,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box className={classes.depositBlock} style={{ padding: 30 }}>
        <Typography
          align={"center"}
          className={clsx(classes.total, classes.totalItem)}
        >
          {t("common:total")}:
        </Typography>
        <Typography align={"center"} className={classes.total}>
          {appliedLoyaltyCard?.redeem_points_amount &&
          appliedLoyaltyCard?.redeem_points_amount < parseFloat(totalAmount)
            ? (
                parseFloat(totalAmount) +
                parseFloat(appliedLoyaltyCard?.redeem_points_amount)
              ).toFixed(3)
            : parseFloat(totalAmount)?.toFixed(3)}{" "}
          {currencySymbol}
        </Typography>
      </Box>
      <Box className={classes.depositBlock} style={{ padding: 30 }}>
        <Typography
          align={"center"}
          className={clsx(classes.total, classes.totalItem)}
        >
          {t("common:montantRendre")}:
        </Typography>
        <Typography
          align={"center"}
          className={classes.total}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 15,
            alignItems: "center",
          }}
        >
          {getAmount2Return()?.toFixed(3)} {currencySymbol}
          {getAmount2Return() > 0 && order ? (
            avoir2Print ? (
              <ReactToPrint
                trigger={() => (
                  <Badge badgeContent={1} color="error">
                    <Button variant="contained" color="secondary" size="large">
                      Imprimer Avoir
                    </Button>
                  </Badge>
                )}
                content={() => avoir2Print && doc2Print.current}
              />
            ) : (
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={async () => await makeAvoir()}
                disabled={isPending}
              >
                Générer Avoir
              </Button>
            )
          ) : null}
        </Typography>
      </Box>
      <div className={classes.print}>
        {avoir2Print && (
          <Voucher document={avoir2Print} title={"Avoir"} ref={doc2Print} />
        )}
      </div>
    </Box>
  );
};
