import React, { useRef } from "react";
import {
  Button,
  Modal,
  Typography,
  Paper,
  ClickAwayListener,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core";
import { useStyles } from "../../style";
import { useTableStyles } from "../../../../theme/table.style";
import { useDispatch, useSelector } from "react-redux";
import {
  CreatePurchaseReceiptRequest,
  createPurchaseReceiptDoc,
  SetPurchaseReceiptMappedDoc,
  SetStockistSignature,
  LoadPurchaseOrderById,
  UpdateSignaturePurchaseOrder,
} from "../../../../store/buying/buying.action";
import { Loader } from "../../../../components/loader";
import SignaturePad from "react-signature-pad-wrapper";
import { useReactToPrint } from "react-to-print";
import { PurchaseOrderPrint } from "../purchaseOrderDoc";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { statusFormat } from "../../../../helpers/utils";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PrintIcon from "@material-ui/icons/Print";
import { useHistory } from "react-router-dom";

export const PurReqTable = (props) => {
  const { module, countPackage } = props;
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const signPad = useRef({});
  const componentRef = useRef();
  const { t } = useTranslation(["common", "buying"]);

  const [openDetailsModal, setOpenDetailsModal] = React.useState(false);
  const [openPreviewModal, setOpenPreviewModal] = React.useState(false);

  const handleNavigation = (id) => {
    history.push(`/purchase-order/${id}`);
  };

  const purchaseReceiptDoc = useSelector(
    (state) => state.Buying.purchaseReceiptDoc
  );
  const stockistSignature = useSelector(
    (state) => state.Buying.stockistSignature
  );
  const purOrderDetails = useSelector(
    (state) => state.Buying.purchaseOrderDetails
  );

  const handleCloseModal = () => {
    setOpenDetailsModal(false);
    dispatch(SetPurchaseReceiptMappedDoc(null));
  };

  const createPurchaseReceipt = () => {
    let updateMappedDoc = {
      ...purchaseReceiptDoc,
      is_subcontracted: 0,
    };
    dispatch(CreatePurchaseReceiptRequest(updateMappedDoc));
    handleCloseModal();
  };

  const handlePurReceiptDetails = (id) => {
    id && dispatch(createPurchaseReceiptDoc(id));
    setOpenDetailsModal(true);
  };
  const handleAddPackagePurchase = (id) => {
    history.push(`/purchase-package/${id}`);
  };
  const clear = () => {
    dispatch(SetStockistSignature(signPad.current.clear()));
  };
  const trim = () => {
    dispatch(SetStockistSignature(signPad.current.toDataURL("image/png")));
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setOpenPreviewModal(false),
  });

  const handlePurOrderDetails = (id) => {
    dispatch(LoadPurchaseOrderById(id));
    setOpenPreviewModal(true);
  };

  const AddSignaturePurchaseOrder = () => {
    handlePrint();
    const data = {
      name: purOrderDetails?.name,
      signature: stockistSignature,
    };
    if (
      stockistSignature !=
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAAAXNSR0IArs4c6QAABGJJREFUeF7t1AEJAAAMAsHZv/RyPNwSyDncOQIECEQEFskpJgECBM5geQICBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAgQdWMQCX4yW9owAAAABJRU5ErkJggg=="
    ) {
      dispatch(UpdateSignaturePurchaseOrder(data));
    }
  };

  const purchaseOrderDetails = (
    <div className={classes.paper}>
      <Box className={classes.paperr}>
        <Typography align={"center"} color={"primary"}>
          {t("buying:PurchaseReceipt")}
        </Typography>
        <Box mt={3}>
          <Box>
            <Paper className={classes.paperr} style={{ marginBottom: 10 }}>
              <Typography align={"left"} color={"primary"}>
                {t("common:AddBy")}: {purchaseReceiptDoc?.owner}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {t("buying:SupplierName")}: {purchaseReceiptDoc?.supplier_name}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {purchaseReceiptDoc?.address_display?.replace(
                  /<br\s*\/?>/gi,
                  ";   "
                )}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {t("common:createDate")}: {purchaseReceiptDoc?.transaction_date}
              </Typography>
            </Paper>
          </Box>
        </Box>
        <Box mt={2}>
          <Typography
            style={{ marginBottom: -5 }}
            align={"left"}
            color={"primary"}
          >
            {t("common:article")}:
          </Typography>
          <Table className={tableclasses.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:produit")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:itemCode")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:amnt")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:qtyAchat")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:taux")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:Taxation")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchaseReceiptDoc?.items.map((row, i) => (
                <TableRow className={tableclasses.tableRow} key={i}>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.item_name}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.item_code}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.amount.toFixed(3)} {purchaseReceiptDoc?.currency}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.qty}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.rate.toFixed(3)} {purchaseReceiptDoc?.currency}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.item_tax_rate != "" &&
                      Object.keys(JSON.parse(row?.item_tax_rate))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Paper className={classes.paperr} style={{ marginBottom: 10 }}>
            <Typography align={"left"} color={"primary"}>
              {t("common:qtyTotal")}: {purchaseReceiptDoc?.total_qty}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:totalHT")}: {purchaseReceiptDoc?.total?.toFixed(3)}{" "}
              {purchaseReceiptDoc?.currency}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:totalTaxe")}:{" "}
              {purchaseReceiptDoc?.base_total_taxes_and_charges?.toFixed(3)}{" "}
              {purchaseReceiptDoc?.currency}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:grandTotal")}:{" "}
              {purchaseReceiptDoc?.grand_total?.toFixed(3)}{" "}
              {purchaseReceiptDoc?.currency}
            </Typography>
          </Paper>
        </Box>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={purchaseReceiptDoc?.items?.length == 0}
            style={{ marginTop: 20, alignContent: "center" }}
            variant="contained"
            color="primary"
            onClick={createPurchaseReceipt}
          >
            {t("common:valider")}
          </Button>
          <Button
            style={{ marginTop: 20, alignContent: "center", marginLeft: 10 }}
            variant="outlined"
            color="primary"
            onClick={handleCloseModal}
          >
            {t("common:fermer")}
          </Button>
        </Box>
      </Box>
    </div>
  );

  const previewOrderDetails = (
    <ClickAwayListener
      onClickAway={() => {
        setOpenPreviewModal(false);
        clear();
      }}
    >
      <div className={classes.paperSign} width="auto">
        <Typography align={"center"} color={"primary"}>
          {t("common:msgImpression")}
        </Typography>
        <Box
          mt={3}
          justifyContent="center"
          display="flex"
          className={classes.signaturePad}
        >
          <SignaturePad
            ref={signPad}
            redrawOnResize
            options={{ penColor: "#2F4B7C" }}
          />
        </Box>
        <Box mt={3} justifyContent="end" display="flex">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              clear();
              setOpenPreviewModal(false);
            }}
          >
            {t("common:annuler")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: 10 }}
            onClickCapture={() => trim()}
            onClick={AddSignaturePurchaseOrder}
          >
            {t("common:imprimer")}
          </Button>
        </Box>
      </div>
    </ClickAwayListener>
  );

  return (
    <Box className={tableclasses.paper}>
      <Table className={tableclasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:ref")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:createDate")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("buying:SupplierName")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {module == "wms" ? t("buying:NoOfpackages") : t("common:total")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {module == "wms"
                ? t("buying:NoOfproducts")
                : t("common:Taxation")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:grandTotal")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:statut")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.purchaseOrderList &&
            Array.isArray(props.purchaseOrderList) &&
            props.purchaseOrderList.map((row, index) => (
              <TableRow className={tableclasses.tableRow} key={index}>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.name}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.transaction_date}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.supplier}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {module == "wms"
                    ? countPackage?.find(
                        (e) => e?.purchaseorder_id === row?.name
                      )?.total ?? 0
                    : row?.total.toFixed(3) + " " + row?.currency}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {module == "wms"
                    ? row?.total_qty
                    : row?.total_taxes_and_charges.toFixed(3) +
                      " " +
                      row?.currency}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.grand_total.toFixed(3)} {row?.currency}
                </TableCell>
                <TableCell
                  className={clsx(
                    tableclasses.tableCell,
                    tableclasses[statusFormat(row?.status).color]
                  )}
                  align="left"
                >
                  {statusFormat(row?.status).value}
                </TableCell>
                <TableCell
                  className={tableclasses.tableCell}
                  align="right"
                  justifyContent="space-between"
                  display="flex"
                >
                  {module && module === "wms" ? (
                    <>
                      <IconButton
                        disabled={row?.status != "To Receive and Bill"}
                        color="primary"
                        aria-label="add"
                        component="span"
                        className={classes.button}
                        onClick={() =>
                          row?.name && handleAddPackagePurchase(row?.name)
                        }
                      >
                        <AddCircleIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        aria-label="details"
                        component="span"
                        className={classes.button}
                        onClick={() => handleNavigation(row?.name)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <Button
                        size={"small"}
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: 10 }}
                        onClick={() => handlePurOrderDetails(row?.name)}
                      >
                        {t("common:Aperçu-du-commande")}
                      </Button>
                      <Button
                        disabled={row?.status === "To Bill"}
                        size={"small"}
                        color="primary"
                        variant="contained"
                        style={{ marginLeft: 10 }}
                        onClick={() =>
                          handlePurReceiptDetails(row?.name ? row?.name : null)
                        }
                      >
                        {t("common:Créer-un-Reçu-Achat")}
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Modal
        open={openDetailsModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {purchaseReceiptDoc ? (
          purchaseOrderDetails
        ) : (
          <div className={classes.paper}>
            <Loader />
          </div>
        )}
      </Modal>
      <Modal
        open={openPreviewModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {previewOrderDetails}
      </Modal>
      <div className={classes.hidden}>
        <PurchaseOrderPrint
          details={purOrderDetails}
          stockistSignature={stockistSignature}
          ref={componentRef}
          title="Bon Commande"
        />
      </div>
    </Box>
  );
};
