import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ButtongoBack } from "../../../../components/buttongoback";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { fr } from "date-fns/esm/locale";
import DateFnsUtils from "@date-io/date-fns";
import { TransitionAlerts } from "../../../../components/TransitionAlerts";
import { BasicTable } from "../../../checkout/components/basictable";
import { formatDate, symbol } from "../../../../helpers/utils";
import { GlobalfilterData } from "../../../../helpers/helper";
import { ExpandMore } from "@material-ui/icons";
import {
  LoadSupplierDetails,
  createPurchaseOrderRequests,
} from "../../../../store/buying/buying.action";
import { StyledCheckbox } from "../../../../components/styledCheckbox";
import {
  SetPricingRules,
  UpdateProducts,
  ignorePricingRules,
  loadItemDetail,
  updateItemDetails,
} from "../../../../store/checkout/checkout.action";
import { CircularProgressLoader } from "../../../../components/loader/circularLoader";
import { Loader } from "../../../../components/loader";
import { SetNotification } from "../../../../store/notification/notification.action";
import AlertModal from "../../../../components/common/alertModal";

const useStyles = makeStyles((theme) => ({
  blockTitle: {
    fontSize: 14,
    marginBottom: 8,
    paddingLeft: 10,
  },
  formControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  paper: {
    paddingLeft: 10,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
    "& .MuiSelect-root": {
      padding: "0.4938rem 0.875rem",
    },
  },
  label: {
    fontSize: 12,
    color: theme.palette.gray.main,
    paddingLeft: 9,
    marginBottom: 6,
  },
  date: {
    marginBottom: 15,
  },
  formBlock: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
    paddingTop: "20px",
  },
  customButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
    margin: "0rem 1.375rem",
    padding: "0.1875rem 1.4375rem",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },
  cancelButton: {
    color: "#909BAB",
  },
  basictable: {
    height: "255px",
  },
  save: {
    marginTop: "10px",
    marginLeft: 0,
    marginRight: -20,
  },
}));

export const PurchaseForm = (props) => {
  const {
    handleSubmit,
    selectedSupplier,
    setSelectedSupplier,
    targetWarehouse,
    setTargetWarehouse,
    scheduleDate,
    setScheduleDate,
    items,
    setItems,
    error,
    setError,
    ispurchaseInvoice,
    pricing,
    alert,
    setAlert,
    isPricingRule,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const currencies = useSelector((state) => state.Client.currencies);
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const itemDetails = useSelector((state) => state.Checkout.itemDetails);
  const currencySymbol =
    symbol(globalDefaults?.default_currency, currencies) &&
    symbol(globalDefaults?.default_currency, currencies)?.symbol;
  const supplierList = useSelector((state) => state.Buying.supplierList);
  const stores = useSelector((state) => state.Login.stores);
  const products = useSelector((state) => state.Product.products);
  const itemTaxList = useSelector((state) => state.Product.itemTax);
  const ignorePricingRule = useSelector(
    (state) => state.Checkout.ignorePricingRules
  );
  const itemsWithPricingRules = itemDetails?.filter((item) =>
    Object.keys(item).includes("pricing_rules")
  );
  const [searchProduct, setSearchProduct] = useState("");
  const [suggestion, setsuggestion] = useState([]);
  const [selectitem, setSelectitem] = useState(false);
  const [initialLines, setInitialLines] = useState([0, 1, 2, 3]);
  const [openPricingRules, setOpenPricingRules] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const loadItemDetails = (items) => {
    items?.forEach((item) => {
      let doc = {
        docstatus: 0,
        doctype: "Purchase Order",
        title: "{supplier_name}",
        naming_series: "PUR-ORD-.YYYY.-",
        company: globalDefaults?.default_company,
        transaction_date: formatDate(new Date(), "YYYY-MM-DD"),
        currency: globalDefaults?.default_currency,
        buying_price_list: "Standard Buying",
        price_list_currency: globalDefaults?.default_currency,
        ignore_pricing_rule: 0,
        apply_discount_on: "Grand Total",
        status: "Draft",
        party_account_currency: globalDefaults?.default_currency,
        items: [
          {
            docstatus: 0,
            doctype: "Purchase Order Item",
            item_group: item.item_group,
            stock_uom: "Nos",
            parent: "new-purchase-order-1",
            parentfield: "items",
            parenttype: "Purchase Order",
            idx: 1,
            qty: item.qty,
            item_code: item.item_code,
            warehouse: targetWarehouse,
          },
        ],
        supplier: selectedSupplier,
        language: "en",
        set_warehouse: targetWarehouse,
      };
      let args = {
        item_code: item.item_code,
        supplier: selectedSupplier,
        currency: globalDefaults?.default_currency,
        price_list: "Standard Buying",
        price_list_currency: globalDefaults?.default_currency,
        company: globalDefaults?.default_company,
        ignore_pricing_rule: 0,
        doctype: "Purchase Order",
        name: "new-purchase-order-1",
        qty: item.qty,
        set_warehouse: targetWarehouse,
        warehouse: targetWarehouse,
      };
      dispatch(loadItemDetail(args, doc));
    });
  };

  const handleSearchProduct = async (e) => {
    setSearchProduct(e.target.value);
    if (e.target.value == "") {
      setsuggestion([]);
      return;
    }

    const itemstock = products?.filter(
      (s) =>
        s.is_stock_item !== 0 &&
        s?.has_variants == 0 &&
        s?.is_purchase_item == 1
    );
    const data = await GlobalfilterData(itemstock, e.target.value, [
      "item_code",
      "item_name",
      "item_group",
      "description",
    ]);
    setsuggestion(data);
  };

  const handleAddproduct = async (id) => {
    setSelectitem(true);
    var item = suggestion.find((s) => s.item_name === id);
    const data = [...items];
    const ItemIndex = items.findIndex((s) => s.item_name === id);
  
    if (ItemIndex !== -1) {
      data[ItemIndex].quantity = data[ItemIndex].quantity + 1;
      data[ItemIndex].qty = data[ItemIndex].qty + 1;
      data[ItemIndex].stock_qty = data[ItemIndex].stock_qty + 1;
    } else {
      
      const matchingProduct = products.find((p) => p.item_code === item?.item_code);
  
      const dataitem = {
        parentfield: "items",
        parenttype: ispurchaseInvoice ? "Purchase Invoice" : "Purchase Order",
        item_code: item?.item_code,
        item_name: item?.item_name,
        description: item?.description,
        qty: 1,
        stock_uom: "Nos",
        uom: "Nos",
        conversion_factor: 1,
        price_list_rate: item?.price_list_rate || matchingProduct?.standard_rate || 0,
        item_tax_template: item?.item_tax_template,
        is_stock_item: 1,
        expense_account: `${companyacounts?.default_expense_account}`,
        cost_center: `${companyacounts?.cost_center}`,
        doctype: ispurchaseInvoice ? "Purchase Invoice Item" : "Purchase Order Item",
      };
      data.push(dataitem);
    }
  
    setItems(data);
    setsuggestion([]);
    setSelectitem(false);
    setSearchProduct("");
    isPricingRule && loadItemDetails(data);
  };

  const HandleQuantityChange = (onAdd, id) => {
    const ItemIndex = items.findIndex((s) => s.item_code == id);
    const data = [...items];
    if (ItemIndex != -1) {
      data[ItemIndex].qty = onAdd
        ? parseInt(data[ItemIndex].qty) + 1
        : Math.sign(data[ItemIndex].qty - 1) == 1
        ? data[ItemIndex].qty - 1
        : 0;
      data[ItemIndex].qty = data[ItemIndex].qty;
      data[ItemIndex].stock_qty = data[ItemIndex].qty;
      setItems(data);
      isPricingRule && loadItemDetails(data);
    }
  };
  const HandleUpdateQuantity = (e, id) => {
    const ItemIndex = items.findIndex((s) => s.item_code == id);
    const data = [...items];
    if (ItemIndex != -1) {
      data[ItemIndex].quantity = e.target.value;
      data[ItemIndex].qty = e.target.value;
      data[ItemIndex].stock_qty = e.target.value;
      setItems(data);
      isPricingRule && loadItemDetails(data);
    }
  };
  const HandleUpdatePrice = (e, id) => {
    const ItemIndex = items.findIndex((s) => s.item_code == id);
    const data = [...items];
    if (ItemIndex != -1) {
      data[ItemIndex].price_list_rate = e.target.value;
      setItems(data);
      isPricingRule && loadItemDetails(data);
    }
  };

  const HandleDelete = (id) => {
    const data = items?.filter((s) => s.item_code !== id);
    setItems(data);
    const filteredItemDetails = itemDetails?.filter((s) => s.item_code !== id);
    isPricingRule && dispatch(updateItemDetails(filteredItemDetails));
  };

  const handleChange = (e) => {
    setSelectedSupplier(e.target.value);
    //  dispatch(LoadSupplierDetails(e.target.value));
  };
  const handleDateChange = (date, event) => {
    isNaN(Date.parse(date))
      ? setError({ ...error, scheduleDate: false })
      : setScheduleDate(date.toISOString().slice(0, 10));
  };
  const createPurchaseOrder = () => {
    let isValid = true;
    const nextError = { ...error };

    if (!selectedSupplier || selectedSupplier.trim() === "") {
      nextError.selectedSupplier = true;
      isValid = false;
    } else {
      nextError.selectedSupplier = false;
    }
    if (items.length === 0) {
      nextError.items = true;
      isValid = false;
    } else {
      nextError.items = false;
    }

    if (!targetWarehouse || targetWarehouse.trim() === "") {
      nextError.targetWarehouse = true;
      isValid = false;
    } else {
      nextError.targetWarehouse = false;
    }
    let testitem = [];
    items?.forEach((element) => {
      if (element?.qty < 1 || element?.price_list_rate <= 0) {
        testitem.push(element?.item_code);
      }
    });
    if (testitem?.length > 0) {
      nextError.testitem = true;
      isValid = false;
      setAlert({
        open: true,
        message: t("buying:pleaseEnterThePurchasePrice"),
        severity: "error",
      });
    }

    setError(nextError);

    if (isValid) {
      setOpen(true);
    }
  };
  const handleCreate = async (event) => {
    let taxes = [];
    let products = [];
    items?.forEach((element, index) => {
      // Check if the current element exists
      const existingItem = itemDetails?.find(
        (item) => item.item_code === element.item_code
      );

      // Process the main item
      const mainItem = {
        doctype: "Purchase Order Item",
        docstatus: 0,
        __islocal: 1,
        __unsaved: 1,
        parenttype: "Purchase Order ",
        parentfield: "items",
        qty: element?.qty,
        item_name: element?.item_name,
        item_code: element?.item_code,
        item_tax_template: element?.item_tax_template,
        price_list_rate: parseFloat(element?.price_list_rate),
      };

      // Add the main item to the items array
      products = [...products, mainItem];

      // Process free_item_data if it exists
      if (!ignorePricingRule && existingItem && existingItem.free_item_data) {
        existingItem?.free_item_data?.forEach((freeItem, freeIndex) => {
          const freeItemData = {
            doctype: "Purchase Order Item",
            docstatus: 0,
            __islocal: 1,
            __unsaved: 1,
            parenttype: "Purchase Order",
            parentfield: "items",
            qty: freeItem?.qty, // Adjust this based on the free item's quantity
            item_name: freeItem?.item_name,
            item_code: freeItem?.item_code,
            pricing_rules: freeItem?.pricing_rules,
            rate: freeItem?.rate,
            is_free_item: freeItem?.is_free_item,
            price_list_rate: freeItem?.price_list_rate,
          };

          // Add the free item to the items array
          products = [...products, freeItemData];
        });
      }

      // Handle taxes (unchanged from your original code)
      if (element?.item_tax_template) {
        let taxess = itemTaxList?.find(
          (el) => el?.name == element?.item_tax_template
        );
        const tax = {
          account_head: element?.item_tax_template?.substring(
            element?.item_tax_template.indexOf(" ") + 1
          ),
          parentfield: "taxes",
          parenttype: "Purchase Order",
          doctype: "Purchase Taxes and Charges",
          charge_type: "On Net Total",
          cost_center: `${companyacounts?.cost_center}`,
          description:
            element?.item_tax_template.slice(
              8,
              element?.item_tax_template.indexOf("%") + 1
            ) +
            " @ " +
            parseFloat(
              element?.item_tax_template
                .match(/\d+/g)
                .toString()
                .replace(",", ".")
            ).toFixed(1),
          tax_amount: taxess?.tax_rate,
        };
        //taxes.push(tax);
        taxes = [...taxes, tax];
      }
    });
    const req = {
      docstatus: 0,
      idx: 0,
      title: "{supplier_name}",
      naming_series: "PUR-ORD-.YYYY.-",
      supplier: selectedSupplier,
      supplier_name: selectedSupplier,
      apply_tds: 0,
      is_subcontracted: 0,
      company: globalDefaults?.default_company,
      transaction_date: formatDate(new Date(), "YYYY-MM-DD"),
      schedule_date: scheduleDate,
      currency: globalDefaults?.default_currency,
      conversion_rate: 1,
      buying_price_list: "Standard Buying",
      price_list_currency: globalDefaults?.default_currency,
      ignore_pricing_rule: !ignorePricingRule ? 0 : 1,
      set_warehouse: targetWarehouse,
      apply_discount_on: "Grand Total",
      language: "en",
      group_same_items: 0,
      party_account_currency: globalDefaults?.default_currency,
      is_internal_supplier: 0,
      represents_company: "",
      is_old_subcontracting_flow: 0,
      doctype: "Purchase Order",
      status: "To Receive and Bill",
      taxes: taxes,
      items: products,
    };
    dispatch(createPurchaseOrderRequests(req));
  };
  const handleCreatePurchaseOrder = async () => {
    await handleCreate();
    await dispatch(updateItemDetails([]));
    setOpen(false);
  };

  useEffect(() => {
    const updatedItems = items?.map((item) => {
      const matchingDetail = itemDetails?.find(
        (detail) => detail.item_code === item.item_code
      );

      if (matchingDetail) {
        let updatedPriceListRate =
          matchingDetail.price_list_rate || item.price_list_rate;
        return {
          ...item,
          price_list_rate: updatedPriceListRate,
        };
      }

      return item;
    });

    if (!arraysEqual(items, updatedItems)) {
      setItems(updatedItems.flat());
    }
  }, [itemDetails, ignorePricingRule]);

  function arraysEqual(arr1, arr2) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }
  console.log("products: ", products);

  return (
    <Box mt={5}>
      <Box className={classes.paper}>
        <Box className={classes.formBlock}>
          <Grid container spacing={3}>
            <Grid item xs>
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                <InputLabel className={classes.label}>
                  {ispurchaseInvoice
                    ? t("buying:dueDate")
                    : t("buying:RequiredOn")}
                </InputLabel>
                <KeyboardDatePicker
                  className={classes.date}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  name="schedule_date"
                  inputVariant="outlined"
                  id="date-picker-inline"
                  value={scheduleDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  minDate={formatDate(new Date(), "YYYY-MM-DD")}
                  minDateMessage={t("common:minDateMessage")}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs>
              <InputLabel
                required
                className={classes.label}
                error={error.selectedSupplier}
              >
                {t("buying:SelectSupplier")}:
              </InputLabel>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  className={classes.select}
                  value={selectedSupplier}
                  IconComponent={ExpandMore}
                  onChange={handleChange}
                  error={error.selectedSupplier}
                  name="supplier"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {Array.isArray(supplierList) &&
                    supplierList.map((row, index) => (
                      <MenuItem value={row.name} key={row.name}>
                        {row.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            {!ispurchaseInvoice && (
              <Grid item xs>
                <InputLabel
                  error={error.set_warehouse}
                  required
                  className={classes.label}
                >
                  {t("common:mgsCible")}
                </InputLabel>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    className={classes.select}
                    value={targetWarehouse}
                    error={error.targetWarehouse}
                    IconComponent={ExpandMore}
                    onChange={(event) => setTargetWarehouse(event.target.value)}
                    name="set_warehouse"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {Array.isArray(stores) &&
                      stores.map((row, index) => (
                        <MenuItem value={row.name} key={row.name}>
                          {row.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Box>
        <Typography
          className={classes.blockTitle}
          color={error.items ? "error" : "primary"}
          variant="caption"
          display="block"
        >
          {t("common:article")} *
        </Typography>
        <div data-grid={{ w: 9, h: 3, x: 0, y: 0, minW: 4, minH: 3 }}>
          <Box justifyContent="center" display="flex">
            <TransitionAlerts
              severity={alert.severity}
              setAlert={setAlert}
              open={alert.open}
              message={alert.message}
              timeout={3000}
            />
          </Box>
          <BasicTable
            selectitem={selectitem}
            suggestion={suggestion}
            initialLines={initialLines}
            rows={items}
            setSearchProduct={setSearchProduct}
            setsuggestion={setsuggestion}
            HandleQuantityChange={HandleQuantityChange}
            HandleDelete={HandleDelete}
            handleSearchProduct={handleSearchProduct}
            handleAddproduct={handleAddproduct}
            searchProduct={searchProduct}
            HandleUpdateQuantity={HandleUpdateQuantity}
            HandleUpdatePrice={HandleUpdatePrice}
            setInitialLines={setInitialLines}
            addPurchaseAction={true}
            invaliditems={error?.invaliditems}
            openPricingRules={openPricingRules}
            setOpenPricingRules={setOpenPricingRules}
            isPurchaseOrder
          />
        </div>
        {ispurchaseInvoice && (
          <div data-grid={{ w: 9, h: 3, x: 0, y: 0, minW: 4, minH: 3 }}>
            <Grid
              container
              item
              spacing={3}
              style={{
                color: "white",
                backgroundColor: "#0C2605",
                borderRadius: 10,
                height: "100%",
                width: "98%",
                marginRight: 30,
                marginLeft: 10,
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
              }}
            >
              <Grid item style={{ padding: 5 }}>
                <Typography
                  className={classes.checkoutResume}
                  variant="caption"
                  display="block"
                  align="left"
                >
                  H.T
                </Typography>
                <Typography
                  className={classes.checkoutResume}
                  variant="caption"
                  display="block"
                  align="left"
                >
                  {t("common:tax")}
                </Typography>
              </Grid>

              <Grid item style={{ padding: 5 }}>
                <Typography
                  className={classes.checkoutResume}
                  variant="caption"
                  display="block"
                  align="left"
                >
                  :{" "}
                  {pricing?.totalAmountHT
                    ? pricing?.totalAmountHT.toFixed(3)
                    : "00.000"}{" "}
                  {currencySymbol}
                </Typography>
                <Typography
                  className={classes.checkoutResume}
                  variant="caption"
                  display="block"
                  align="left"
                >
                  : {pricing?.taxe ? pricing?.taxe.toFixed(3) : "00.000"}{" "}
                  {currencySymbol}
                </Typography>
              </Grid>

              <Grid item style={{ padding: 5 }}>
                <Typography
                  style={{ color: "#8EBF24" }}
                  className={classes.checkoutResume}
                  align="left"
                  variant="caption"
                  display="block"
                >
                  {t("common:total")}
                </Typography>
              </Grid>

              <Grid item style={{ padding: 5 }}>
                <Typography
                  style={{ color: "#8EBF24" }}
                  className={classes.checkoutResume}
                  align="left"
                  variant="caption"
                  display="block"
                >
                  : {(pricing?.totalAmountHT + pricing?.taxe).toFixed(3)}{" "}
                  {currencySymbol}
                </Typography>
              </Grid>
            </Grid>
          </div>
        )}

        <br />
        <br />
        <Box display="flex" justifyContent="flex-end" className={classes.save}>
          <Button
            onClick={isPricingRule ? createPurchaseOrder : handleSubmit}
            className={classes.customButton}
            size={"small"}
            variant="contained"
            color="primary"
          >
            {t("common:save")}
          </Button>
        </Box>
      </Box>
      <AlertModal
        setOpenModal={setOpen}
        handleCancel={handleCreatePurchaseOrder}
        openModal={open}
        title={
          itemsWithPricingRules?.length != 0
            ? "Voulez-vous accepter ou ignorer la condition commerciale ?"
            : t("common:msgContinue")
        }
        defaultClient={selectedSupplier}
        isPricingRule
      />
    </Box>
  );
};
