import React, { useEffect } from "react";
import { Route, Redirect, useHistory, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export default function SubRouters(route) {
  var user = JSON.parse(localStorage.getItem("user"));
  const redirectTo = useSelector((state) => state.Page.redirectTo);
  const history = useHistory();

  useEffect(() => {
    if (redirectTo) {
      history.push(redirectTo);
    }
  }, [redirectTo]);

  function isPermitted(route, user) {
    // Check if the route has roles specified
    if (route.role) {
      // Check if the user role is included in the route roles
      return route.role.includes(user.role);
    } else if (route.routes) {
      // Find the route that matches the location.pathname
      const matchingRoute = route.routes.find(
        (subRoute) => subRoute.path === route.location.pathname
      );

      // If a matching route is found
      if (matchingRoute) {
        // Check if the user role is included in the matching route roles
        return matchingRoute.role.includes(user.role);
      }

      // If no matching route is found, it is permitted
      return true;
    } else {
      // If the route doesn't have roles or routes specified, it is permitted
      return true;
    }
  }

  function checkPermittion(route, props) {
    if (route.isPublic)
      return <route.Component {...props} routes={route?.routes} />;
    if (user) {
      if (isPermitted(route, user))
        return <route.Component {...props} routes={route?.routes} />;
      return (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Alert
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => history.goBack()}
              >
                RETOUR
              </Button>
            }
            severity="error"
            variant="filled"
          >
            Oops! Vous n'êtes pas autorisé à entrer ici.
          </Alert>
        </Box>
      );
    } else {
      return <Redirect to="/login" />;
    }
  }

  return (
    <Switch>
      <Route
        path={route.path}
        exact={route.exact}
        render={(props) => checkPermittion(route, props)}
      />
    </Switch>
  );
}
